import * as React from 'react';
import { useState } from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';

const DateSetterSimple = (props) => {

    const { setDate, date } = props
    const [dateValue, setDateValue] = useState({ ...date });
    
    const handleChange = value => {
        setDateValue(value);
        setDate(value);
      };

    return(
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
                maxDate={dateValue.end}
                openTo="year"
                views={['year']}
                label="Ano"
                value={dateValue.start}
                onChange={value => handleChange({ ...dateValue, start:value })}
                renderInput={(params) => <TextField size="small" {...params} />}
                disableFuture
            />
        </LocalizationProvider>
    );
};

export default DateSetterSimple;