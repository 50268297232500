import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSection } from '../features/sectionsSlice';
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const SectionCreate = () => {

    // REDUX STATES
    const dispatch = useDispatch();
  
    // REACT ROUTER 
    const navigate = useNavigate();
    const location = useLocation();
    const trackLocation = location.state?.from?.pathname || '/admin';
  
    // STATES
    const [sectionName, setSectionName] = useState('');
  
    // HANDLE INSERT NEW SECTION
    const handleInsertSection = (sectionname) => {
      const newSection = { name: sectionname.trim() }
      dispatch(createSection(newSection));
      setSectionName('');
    }
  
    // VALIDATE FIELDS
    const validateName = (str) => {
      return str.match(
        /^[a-zA-Z\u00C0-\u00FF][a-zA-Z\u00C0-\u00FF\s]*$/
      );
    };
    const nameError = (str) => {
        if(!validateName(str) && str.length > 0)
        return true
        else
        return false
    };
  
    return (
      <React.Fragment>
        <Box
        sx={{
            minWidth: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            p: 3,
        }}
      >  
          <Box >
            <TextField
              value={sectionName}
              error={nameError(sectionName)}
              onChange={e => setSectionName(e.target.value)}
              fullWidth
              label="Nome da Seção"
              id="section-name"
              size="small"
              type="text"
              helperText={nameError(sectionName) ? "Deve possuir apenas letras, três ou mais caracteres, espaços apenas entre palavras" : null}
              sx={{ my: 1,}}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button 
              variant="contained" 
              disabled={!validateName(sectionName) || sectionName.trim().length < 3}
              fullWidth 
              sx={{ my: 1,}} 
              onClick={e => {
                e.preventDefault();
                handleInsertSection(sectionName);
              }}
            >
              Criar Seção
            </Button>
          </Box>
      </Box>
      </React.Fragment>
    );
  }
  
  export default SectionCreate;