import * as React from 'react';
import { useState } from 'react';
import { updateSection } from '../features/sectionsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Title from './Title';   
import DefaultDialog from './DefaultDialog';   
import GroupsEdit from './GroupsEdit';   
import CategoriesEdit from './CategoriesEdit';    

import { validateName, nameError } from '../utils';   


const SectionsEdit = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();

    // REDUX SELECTORS
    const dispatch = useDispatch();
    const section = useSelector(state => state.sections.sections.find(s => s.id === parseInt(params.sectionId, 10) ));
    const groups  = useSelector(state => state.sections.groups);   
    const categories = useSelector(state => state.sections.categories);

    // EDIT SECTION STATES
    const [sectionData, setSectionData] = useState({...section});

    // DIALOG STATES
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogGroupOpen, setDialogGroupOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null); 
    const [selectedCategory, setSelectedCategory] = useState(null); 

    // HANDLE TOGGLE DIALOG
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };    
    const handleDialogGroupOpen = () => {
        setDialogGroupOpen(true);
    };
    const handleDialogGroupClose = () => {
        setDialogGroupOpen(false);
    };    

    // HANDLE GROUP EDIT
    const handleGroupEdit = (categoryid) => {
        setSelectedCategory(categoryid);
        handleDialogOpen(true);
    };
    // HANDLE CATEGORY EDIT
    const handleCategoryEdit = (groupid) => {
        // const sectionGroupId = groups.find(g => g.id === groupid).section_id;
        // setSelectedCategoryClass(sectionGroupId);
        setSelectedGroup(groupid);
        handleDialogGroupOpen(true);
    };


    // HANDLE CLEAN TYPES
    const handleCleanTypes = () => {
        setDialogOpen(false);
        setDialogGroupOpen(false);
        setSelectedGroup(null);
        setSelectedCategory(null); 
    };

    // CHANGE SECTION STATES
    const handleChangeSectionData = (event) => {
        setSectionData({...sectionData, [event.target.name]: event.target.value});
    };

    // UPDATE SECTION
    const handleUpdateSection = () => {
        const updatedSectionData = {
            ...sectionData,
            name: sectionData.name.trim()
        }

        dispatch(updateSection(updatedSectionData));
    };

    return (
        <Container maxWidth="xl" sx={{ my: 4 }}>
            <Grid container spacing={2}>
                {/* LEFT PANEL */}
                <Grid item xs={12} md={8}>
                    <Paper sx={{ mb: 2, height: '150px', }} >
                        <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', }}>
                            <Title position={'left'}/> 
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <TextField
                                value={sectionData.name}
                                onChange={(event) => handleChangeSectionData(event)}
                                fullWidth
                                label="Nome da Seção"
                                name="name"
                                size="small"
                                type="text"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                                //error={true}
                                helperText={nameError(sectionData.name) ? "Deve possuir apenas letras, três ou mais caracteres, espaços apenas entre palavras" : null}
                            />     
                        </Grid>
                    </Paper>                     
                </Grid>

                {/* RIGHT PANEL */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ mb: 2, height: '150px', }} >
                        <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', }}>
                            <Title position={'right'}/> 
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <Button 
                                variant="contained" 
                                fullWidth 
                                sx={{ my: 2,}} 
                                disabled={!validateName(sectionData.name) || sectionData.name.trim().length < 3} 
                                onClick={e => {
                                    e.preventDefault();
                                    handleUpdateSection();
                                    console.log('clicou');
                                  }}
                            >
                                Atualizar
                            </Button>  
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            {/* MIDDLE PANEL */}
            <Grid item xs={12}>
                <Paper sx={{ minHeight: 150, mb: 2, }} >
                    <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'row', }}>
                        <Title position={'middle'}/> 
                    </Grid>
                    <Divider />
                    <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {groups && groups.filter(g => g.section_id === section.id).map(sg => ( 
                                    <Grid item xs={4}>
                                        <Paper variant='outlined'> 
                                            <List dense >
                                                <ListItem key={sg.id} disablePadding disableGutters >
                                                    <ListItemButton role={undefined} onClick={() => handleCategoryEdit(sg.id)} >
                                                        <ListItemText primary={sg.name} sx={{ textTransform: 'uppercase', }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                            <Divider />
                                            <List dense >
                                                {categories && categories.filter(c => c.group_id === sg.id).map(gc => (
                                                    <ListItem key={gc.id} disablePadding disableGutters>
                                                        <ListItemButton role={undefined} onClick={() => handleCategoryEdit(sg.id)} dense>
                                                            <ListItemText primary={gc.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}    
                                            </List>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                            <Stack direction="row-reverse" sx={{ mt: 5, }} >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    //endIcon={<BookmarksIcon />} 
                                    onClick={() => handleGroupEdit(section.id)} 
                                >
                                    Incluir Grupo de Categorias em {section.name}
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Paper>
            </Grid>

            {/* <SectionsIndex />*/}
            

            {/* MANAGE GROUP DIALOG */}
            <DefaultDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={`Incluir grupo em [${section.name}]`}
                children={<GroupsEdit sectionId={section.id} cleanTypes={handleCleanTypes} />}
            />

            {/* MANAGE CATEGORY DIALOG */}
            <DefaultDialog
                open={dialogGroupOpen}
                onClose={handleDialogGroupClose}
                title={`Organizar grupo [${selectedGroup && groups.find(g => g.id === selectedGroup).name}] em [${section.name}]`}
                children={<CategoriesEdit groupId={selectedGroup} cleanTypes={handleCleanTypes} />}
            />

        </Container>
    )
};

export default SectionsEdit;