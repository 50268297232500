import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signIn } from '../features/sessionSlice';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { validatePassword, emailError } from '../utils';
import logo from '../images/logo.png';

const Signin = () => {

  // REDUX STATES
  const dispatch = useDispatch();

  // REACT ROUTER 
  const navigate = useNavigate();
  const location = useLocation();
  const trackLocation = location.state?.from?.pathname || '/admin';

  // STATES
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

   // DISPATCH SIGN IN DATA
   const handleSignin = (email, password, navigate, trackLocation) => {
    dispatch(signIn({ email, password, navigate, trackLocation }));
  };

  return (
    <React.Fragment>
      <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >  
        <Box sx={{ maxWidth: 400, }} >
          <Box
            component="img"
            sx={{
              display: 'block',
              margin: 'auto',
              maxWidth: 400,
              overflow: 'hidden',
              width: '100%',
              mb: 3,
            }}
            src={logo}
            alt='marca maristela ribeiro'
          />
          <Typography component="p" variant="overline" align="center" gutterBottom sx={{ mb: 1, }}>
            Acesso Administrativo
          </Typography>
          <TextField
            value={email}
            error={emailError(email)}
            onChange={e => setEmail(e.target.value)}
            fullWidth
            label="Endereço de e-mail"
            id="input-email"
            size="small"
            type="email"
            helperText={emailError(email) ? "Digite um endereço de e-mail válido" : null}
            sx={{ my: 1,}}
            InputProps={{
              startAdornment: <InputAdornment position="start"><PersonIcon /></InputAdornment>,
            }}
          />
          <TextField
            value={password}
            error={validatePassword(password)}
            onChange={e => setPassword(e.target.value)}
            fullWidth
            label="Senha"
            id="input-password"
            size="small"
            type="password"
            helperText={password.length < 6 ? "Mínimo 6 caracteres, sem espaços em branco" : null}
            sx={{ my: 1,}}
            InputProps={{
              startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
            }}
          />
          <Button 
            variant="contained" 
            fullWidth 
            sx={{ my: 1,}} 
            onClick={e => {
              e.preventDefault();
              handleSignin(email, password, navigate, trackLocation);
            }}  
          >
            Acessar
          </Button>
        </Box>
      <Box sx={{ py: 2, }} >
      <Typography component="span" variant="p" align="center" >
          Esqueceu sua senha? <Link to="/resetpassword" color="inherit" underline="hover" >Clique aqui</Link>
        </Typography>
      </Box>
    </Box>
    </React.Fragment>
  );
}

export default Signin