import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const CategoryCheckbox = (props) => {

    const { checked, setChecked } = props;

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();

    // REDUX SELECTORS
    const content = useSelector(state => state.content.content).find(c => c.id === parseInt(params.contentId, 10) );
    // const contentCategoriesIds = useSelector(state => state.content.content_categories)
    //     .filter(cc => cc.content_id === parseInt(params.contentId, 10) )
    //     .map(c => {return c.category_id} );     
    const groups = useSelector(state => state.sections.groups);
    const categories = useSelector(state => state.sections.categories);

    // HANDLE SELECTED CATEGORIES
    const handleToggle = (obj) => () => {
        const currentIndex = checked.indexOf(obj);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(obj);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };

  return (
    <div>
        {groups && groups.filter(g => g.section_id === content.section_id).map(cg => (
            <FormControl sx={{ width: '100%', my: 1, }} key={`${cg.id}`}>
                <InputLabel shrink variant="outlined" size="small" id={cg.name}>{cg.name}</InputLabel>
                <Select
                    labelId={cg.name}
                    id={`${cg.id}`}
                    multiple
                    value={checked}
                    //onChange={handleChange}
                    input={<OutlinedInput id={`${cg.id}`} label={cg.name} size="small" notched />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.filter(s => s.group_id === cg.id).map((value) => (
                                <Chip key={value.id} label={value.name} size="small" />
                            ))}
                        </Box>
                    )}
                >
                {categories && categories.filter(c => c.group_id === cg.id).map(ct => (
                    <ListItem 
                        key={`${ct.id}`}
                        disablePadding
                        secondaryAction={
                            <Checkbox
                                edge="end"
                                onChange={handleToggle(ct)}
                                checked={checked.indexOf(ct) !== -1}
                                inputProps={{ 'aria-labelledby': ct.name }}
                            />
                        }
                    >
                        <ListItemButton dense> 
                            <ListItemText primary={ct.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
                </Select>
            </FormControl>
        ))}

    </div>
  );
};

export default CategoryCheckbox;