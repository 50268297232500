import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom"; 
import { updatePublicImageGalleryContainerSize } from '../features/contentSlice'; 
import { ThemeProvider } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PublicMainImage from './PublicMainImage';
import PublicMainImageDescription from './PublicMainImageDescription';   
import PublicImageGrid from './PublicImageGrid'; 
import PublicContentMenu from './PublicContentMenu'; 
import { useElementSize } from '../components/useElementSize'
import { publicTheme } from '../styles/publicStyles';

const PublicContent = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams(); 

    // REDUX SELECTORS
    const dispatch = useDispatch(); 

    const contentCategoriesIds = useSelector(state => state.content.content_categories)
        .filter(cc => cc.content_id === parseInt(params.contentId, 10) )
        .map(c => {return c.category_id} ); 
    const categories = useSelector(state => state.sections.categories);
    const contentCategories = categories.filter(cc => contentCategoriesIds.includes(cc.id)); //console.log('contentCategories',contentCategories);
    const isVideo = Boolean( contentCategories.map(cc => {return cc.id}).includes(16)); //console.log('isVideo',isVideo);
    const mainDivSize = useSelector(state => state.content.mainDivSize); 

    // REF TO IMAGE GRID
    const ref = useRef(null);
    const size = useElementSize(ref);
    
    // TRACK MAIN WIDTH
    useEffect(() => {
        const getMainSize = (size) => { 
            const { x, y, width, height, top, right, left, bottom, } = size;
            const mainsize = {
                x: x, 
                y: y, 
                width: width, 
                height: height, 
                top: top, 
                right: right, 
                left: left, 
                bottom: bottom
            };
            
            return mainsize;
        };
         
        dispatch(updatePublicImageGalleryContainerSize(getMainSize(size)));
    }, [mainDivSize, size, dispatch]);


    return (
        <ThemeProvider theme={publicTheme} >
            <Container maxWidth="xl"  >
                <Grid container spacing={1} >
                    <Grid item xs={12} sx={{ mx: mainDivSize.width > 830 ? 10 : 2, }}>
                        <PublicContentMenu />
                    </Grid>

                    <Grid 
                        item 
                        xs={12} 
                        sx={{ mx: mainDivSize.width > 830 ? 10 : 2, pt: 0, mt: 0, }}
                    >
                        <Box 
                            ref={ref} 
                            id="publicImageGalleryContainer"
                            sx={{ 
                                display: 'flex',
                                background: publicTheme.palette.wheat.light,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                minHeight: '65vh',
                                //mb: 3,
                            }} 
                        >
                            <Grid container spacing={3} sx={{ mx: 0, my: 0, }}>
                                {isVideo ? (
                                    <Grid item xs={12}>
                                        <Box  
                                            sx={{ 
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <PublicMainImage />
                                        </Box> 
                                    </Grid>
                                ) : (
                                    <React.Fragment>
                                        <Grid item xs={mainDivSize.width > 830 ? 7 : 12}>
                                            <Box  
                                                sx={{ 
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                }}
                                            >
                                                <PublicMainImage />
                                            </Box> 
                                        </Grid>
                                        <Grid item xs={mainDivSize.width > 830 ? 5 : 12}>
                                            <Box  
                                                sx={{ 
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                }}
                                            >
                                                <PublicImageGrid />
                                            </Box> 
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Box>

                        <Box 
                            sx={{ 
                                display: 'flex',
                                background: publicTheme.palette.wheat.light,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                mb: 3,
                            }} 
                        >
                            <Grid container spacing={0} sx={{ mx: 0, my: 0, px: 3, pb: 1, }}>
                                <Grid item xs={mainDivSize.width > 830 ? 7 : 12}>
                                    <PublicMainImageDescription />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default PublicContent;
