import * as React from 'react';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Article';
import LayersIcon from '@mui/icons-material/Layers';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FeedIcon from '@mui/icons-material/Feed';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useHistory } from './history';

const MainMenu = () => {

    // MY HISTORY HOOK
    const history = useHistory();
    const section = history?.pathArray[2] || ''

    // TRACK ROUTES 
    useEffect(() => {
    }, [history.location]);

    function activeMenu(section, link) {
        if(section === link)
            return true;
        else
            return false;
    };

    return (
        <div>
            <ListItemButton component={Link} to="/admin/content" selected={activeMenu(history?.pathArray[2],'content')} >
                <ListItemIcon>
                    <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Publicações" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/contentvideos/edit" selected={activeMenu(history?.pathArray[2],'contentvideos')} >
                <ListItemIcon>
                    <VideoLibraryIcon />
                </ListItemIcon>
                <ListItemText primary="Vídeos" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/contentarticles/edit" selected={activeMenu(history?.pathArray[2],'contentarticles')} >
                <ListItemIcon>
                    <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Bibliografia" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/contentauthorarticles/edit" selected={activeMenu(history?.pathArray[2],'contentauthorarticles')} >
                <ListItemIcon>
                    <LocalLibraryIcon />
                </ListItemIcon>
                <ListItemText primary="Textos da Artista" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/contentcatalogues/edit" selected={activeMenu(history?.pathArray[2],'contentcatalogues')} >
                <ListItemIcon>
                    <FeedIcon />
                </ListItemIcon>
                <ListItemText primary="Publicações" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/contentclipping/edit" selected={activeMenu(history?.pathArray[2],'contentclipping')} >
                <ListItemIcon>
                    <NewspaperIcon />
                </ListItemIcon>
                <ListItemText primary="Clipping" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/sections" selected={activeMenu(section,'sections')} >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Seções" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/pages" selected={activeMenu(section,'pages')} >
                <ListItemIcon>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Páginas" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/members" selected={activeMenu(section,'members')} >
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Colaboradores" />
            </ListItemButton>
        </div>
    )
};

export default MainMenu;
