import * as React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createContent } from '../features/contentSlice';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Title from './Title';   
import DateSetter from './DateSetter'; 

const ContentCreate = () => {

    // REDIRECT
    const navigate = useNavigate();    

    // REDUX SELECTORS
    const dispatch = useDispatch();
    const statuses = useSelector(state => state.content.statuses);
    const sections = useSelector(state => state.sections.sections);

    // START NEW CONTENT OBJECT
    const startDate = DateTime.now();
    const content = {
        status: 1,
        section_id: sections ? sections[0].id : null,
        title: '',
        slug: '',
        body: '',
        date: {
            interval: false,
            start: startDate,
            end: startDate
        }
    };

    // EDIT RESEARCH STATES
    const [contentData, setContentData] = useState({ ...content });
    const [checked, setChecked] = useState([]); 
    
    // CHANGE CONTENT STATES
    const handleChangeContentData = (event) => {
        setContentData({...contentData, [event.target.name]: event.target.value});
    };

    // CREATE CONTENT
    const handleCreateContent = () => {
        const newDate = { ...contentData.date, start: contentData.date.start.c, end: contentData.date.end.c }
        const newContent = {
            contentData: {
                ...contentData,
                date: newDate
            },
            contentCategoriesData: checked,
        };

        dispatch(createContent({ 
            obj: newContent,
            navigate: navigate,
        }));
    };

    return (
        <Container maxWidth="xl" sx={{ my: 4 }}>
            <Grid container spacing={2}>
                {/* LEFT PANEL */}
                <Grid item xs={12} md={8}>
                    <Paper sx={{ minHeight: 260, }}>
                        <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', }}>
                            <Title position={'left'}/> 
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <TextField
                                value={contentData.title}
                                //error={emailError(email)}
                                onChange={(event) => handleChangeContentData(event)}
                                fullWidth
                                label="Título"
                                name="title"
                                size="small"
                                multiline={true}
                                minRows={1}
                                maxRows={2}
                                type="text"
                                //error={true}
                                helperText="Título principal da página"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                value={contentData.slug}
                                //error={emailError(email)}
                                onChange={(event) => handleChangeContentData(event)}
                                fullWidth
                                label="Título de Menu"
                                name="slug"
                                size="small"
                                type="text"
                                //error={true}
                                helperText="Termo do menu de navegação principal, que dá acesso à página"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            />



                        </Grid>
                    </Paper>
                </Grid>

                {/* RIGHT PANEL */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ minHeight: 260, }} >
                        <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', }}>
                            <Title position={'right'}/> 
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <TextField
                                name="status"
                                select
                                label="Status"
                                size="small"
                                value={contentData.status}
                                onChange={(event) => handleChangeContentData(event)}
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            >
                                {statuses.map((c) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.status}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                name="section_id"
                                select
                                label="Seção"
                                size="small"
                                value={contentData.section_id}
                                onChange={(event) => handleChangeContentData(event)}
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            >
                                {sections.map(s => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </TextField>


                            
                        </Grid>

                        <Divider />

                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <DateSetter 
                                setDate={(value) => setContentData({ ...contentData, date:value })}
                                date={{ ...contentData.date }}
                            />
                        </Grid>

                        <Divider />

                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <Button 
                                variant="contained" 
                                fullWidth 
                                sx={{ my: 1,}} 
                                onClick={handleCreateContent}  
                            >
                                Criar
                            </Button>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
};

export default ContentCreate;