import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import sessionReducer from './sessionSlice';
import sectionsReducer from './sectionsSlice';
import contentReducer from './contentSlice';
import imagesReducer from './imagesSlice';
import pagesReducer from './pagesSlice';

export const store = configureStore({
    reducer: {
      session: sessionReducer,
      sections: sectionsReducer,
      content: contentReducer,
      images: imagesReducer,
      pages: pagesReducer,
    },
  })

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)