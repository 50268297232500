import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMainDivSize } from '../features/contentSlice'; 
import { updateDrawer } from '../features/sessionSlice'; 
//import { logout } from '../features/sessionSlice'; 
import { Link, Outlet, useParams } from "react-router-dom"; 
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
//import LoginIcon from '@mui/icons-material/Login';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PublicMenu from '../components/PublicMenu';
import Controls from '../components/Controls';
import PublicContentInfo from '../components/PublicContentInfo';
// import Legend from './Legend';
// import FilterSelect from './FilterSelect';
// import PublicFooter from '../components/PublicFooter';
import { slugger } from '../components/slugger';
import { AppBar, DrawerHeader, Main, publicTheme, ButtonMenu, drawerWidth } from '../styles/publicStyles';
import { useHistory } from '../components/history';
import { showControls } from '../utils';
import { useElementSize } from '../components/useElementSize'

const Home = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();   

    // REDUX SELECTORS
    const dispatch = useDispatch();
    //const content = useSelector(state => state.content.content).find(c => c.id === parseInt(params.contentId, 10) );
    const contentCategoriesIds = useSelector(state => state.content.content_categories)
        .filter(cc => cc.content_id === parseInt(params.contentId, 10) )
        .map(c => {return c.category_id} ); 
    const categories = useSelector(state => state.sections.categories);
    const contentCategories = categories.filter(cc => contentCategoriesIds.includes(cc.id)); console.log('cc',contentCategories.find(cc => cc.id === 16))

    // MY HISTORY HOOK
    const history = useHistory(); 
    
    // REF TO IMAGE GRID
    const ref = useRef(null);
    const size = useElementSize(ref);
    const windowWidth = size.width;

    // TRACK MAIN WIDTH
    useEffect(() => {
        const getMainSize = (size) => { 
            const { x, y, width, height, top, right, left, bottom, } = size;
            const mainsize = {
                x: x, 
                y: y, 
                width: width, 
                height: height, 
                top: top, 
                right: right, 
                left: left, 
                bottom: bottom
            };
            
            return mainsize;
        };
     
        dispatch(updateMainDivSize(getMainSize(size)));
    }, [history.pathArray]);

    // FREEZE DRAWER ON VIDEO CATEGORY 
    // useEffect(() => {
    //     if(history.pathArray[1] === 'content' && contentCategories.find(cc => cc.id === 16) ) {
    //         dispatch(updateDrawer(true));
    //     }
    // }, [history.pathArray, contentCategories, dispatch]);

    const session = useSelector(state => state.session);
    const drawer = useSelector(state => state.session.drawer); //console.log('drawer', drawer)
    const sections = useSelector(state => state.sections.sections);
    const relatedContent = useSelector(state => state.content.related_content);
    const pages = useSelector(state => state.pages.pages);  

    // ALL MENU STATES
    const [anchorEl, setAnchorEl] = useState({});
    //const [hoverMenu, setHoverMenu] = useState(false); console.log('hoverMenu',hoverMenu)
    
    // HANDLE MENU
    const handleMenu = (event) => {
        dispatch(updateDrawer(false));
        if (anchorEl[event.currentTarget.id] === event.currentTarget) 
            setAnchorEl({...anchorEl, [event.currentTarget.id]: null});
        else 
            setAnchorEl({...anchorEl, [event.currentTarget.id]: event.currentTarget});
    };
    const handleClose = () => {
        setTimeout(() => {
            setAnchorEl({});
        }, "100"); 
    };
    const handleSmallNavClose = () => {
        setAnchorEl({}); 
    };
    
    //const [open, setOpen] = useState(false);

    //// CLOSE DRAWER ON HOME 
    //useEffect(() => {
    //    if(history.pathArray[1] === '')
    //        setOpen(false)
    //        //dispatch(updateDrawer(false));
    //}, [history.pathArray]);

    //const [show, setShow] = useState(false);

    const handleDrawerOpen = () => {
      //setOpen(!open);
      dispatch(updateDrawer(!drawer));
    };
  
    const handleDrawerClose = () => {
      //setOpen(false);
      dispatch(updateDrawer(false));
    };

    // const handleFooterShow = (e) => {
    //     setShow(e);
    // };

    // // HANDLE LOGOUT
    // const handleLogout = () => {
    //     dispatch(logout());
    // };

    const PublicSmallNav = () => {
        return (
            <Menu 
                id="PublicSmallNav-mainMenu"
                anchorEl={anchorEl['PublicSmallNavMenuButton-Main']}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={anchorEl.hasOwnProperty('PublicSmallNavMenuButton-Main')}
                onClose={handleClose}    
            >
                <MenuList sx={{ width: 320, maxWidth: '100%', }} >
                    <Box sx={{ display: { xs: 'block', md: 'none', lg: 'none' }}} >
                        {sections.map((se) => (
                            <MenuItem
                                key={slugger(se.name)} 
                                component={Link} 
                                to={`/content/5`}
                                color="inherit"
                                onClick={handleSmallNavClose}
                            >
                                <ListItemText inset >{se.name}</ListItemText>
                            </MenuItem>
                         ))}

                        <MenuItem
                            key="chronology" 
                            component={Link} 
                            to={`/chronology/0`}
                            color="inherit"
                            onClick={handleSmallNavClose}
                        >
                            <ListItemText inset >Cronologia</ListItemText>
                        </MenuItem>

                        {relatedContent.map((rc) => (
                            <MenuItem
                                key={slugger(rc.name)} 
                                component={Link} 
                                to={`/relatedcontent/${rc.id}`}
                                color="inherit"
                                onClick={handleSmallNavClose}
                            >
                                <ListItemText inset >{rc.name}</ListItemText>
                            </MenuItem>
                        ))}

                         {pages.map((pa) => (
                            <MenuItem
                                key={slugger(pa.title)} 
                                component={Link} 
                                to={`/page/${pa.id}`}
                                color="inherit"
                                onClick={handleSmallNavClose}
                            >
                                <ListItemText inset >{pa.title}</ListItemText>
                            </MenuItem>
                         ))}
                    </Box>
                   
                </MenuList>
            </Menu>
        );
    };

    return ( 
        <ThemeProvider theme={publicTheme} > 
            <Box 
                sx={{ 
                    display: 'flex', 
                    //zIndex: -200, 
                }}
                >
            <CssBaseline />
                <AppBar 
                    open={drawer}
                    position="fixed" 
                    color="inherit" 
                    elevation={0}  
                    // sx={{ 
                    //     height: '41px',
                    //     borderBottom: 1,
                    //     borderColor: 'divider',
                    // }}
                >
                    <Toolbar  >
                        <Box sx={{ flexGrow: 1 }} />
                        <Box 
                          sx={{ 
                            display: windowWidth > 900 ? 'block' : 'none', 
                          }}
                        > 
                            {sections && sections.map((s) => (
                                <Box sx={{ display: 'inline-flex' }} key={slugger(s.name)} > 
                                    <ButtonMenu
                                        id={`PublicNavMenuButton-${s.name}`} 
                                        active={anchorEl.hasOwnProperty(`PublicNavMenuButton-${s.name}`)}
                                        onClick={handleMenu} 
                                        color="inherit"
                                        key={slugger(s.name)} 
                                    >
                                        {s.name}
                                    </ButtonMenu>
                                    
                                    <Menu 
                                        PaperProps={{
                                            style: {
                                                maxHeight: '85%',
                                                width: 300,
                                                color: publicTheme.palette.common.white,
                                                background: publicTheme.palette.lead.main,
                                            },
                                        }}
                                        MenuListProps={{ onMouseLeave: handleClose }}
                                        id={`PublicNavSubmenu-${s.name}`}
                                        
                                        anchorEl={anchorEl[`PublicNavMenuButton-${s.name}`]}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={anchorEl.hasOwnProperty(`PublicNavMenuButton-${s.name}`)}
                                        onClose={handleClose}    
                                    >
                                        <MenuList >
                                            <PublicMenu sectionId={s.id} close={handleClose}/>
                                        </MenuList>
                                    </Menu>
                                </Box>
                            ))}

                            {/* CHRONOLOGY PAGE */}
                            <Box sx={{ display: 'inline-flex' }} key="relatedcontent"  > 
                                <ButtonMenu
                                    id={`PublicNavMenuButton-chronology`} 
                                    //active={activeMenu(history?.pathArray, p.id, 'relatedContent')}
                                    //onClick={handleMenu} 
                                    color="inherit"
                                    //key={slugger(p.title)} 
                                    component={Link} 
                                    to="/chronology/0"
                                >
                                    Cronologia
                                </ButtonMenu>
                            </Box>

                            {relatedContent && relatedContent.map((rc) => (
                                <Box sx={{ display: 'inline-flex' }} key={slugger(rc.name)}  > 
                                    <ButtonMenu
                                        id={`PublicNavMenuButton-${rc.name}`} 
                                        //active={activeMenu(history?.pathArray, rc.id, 'publicPage')}
                                        onClick={handleMenu} 
                                        color="inherit"
                                        key={slugger(rc.name)} 
                                        component={Link} 
                                        to={`/relatedcontent/${rc.id}`}
                                    >
                                        {rc.name}
                                    </ButtonMenu>
                                </Box>
                            ))}

                            {pages && pages.map((p) => (
                                <Box sx={{ display: 'inline-flex' }} key={slugger(p.title)}  > 
                                    <ButtonMenu
                                        id={`PublicNavMenuButton-${p.title}`} 
                                        //active={activeMenu(history?.pathArray, p.id, 'publicPage')}
                                        onClick={handleMenu} 
                                        color="inherit"
                                        key={slugger(p.title)} 
                                        component={Link} 
                                        to={`/page/${p.id}`}
                                    >
                                        {p.title}
                                    </ButtonMenu>
                                </Box>
                            ))}
                        </Box>

                        <IconButton 
                            id={`PublicSmallNavMenuButton-Main`}
                            disableRipple 
                            edge="end" 
                            onClick={handleMenu} 
                            sx={{ 
                                display: windowWidth > 900 ? 'none' : 'block',
                                color: publicTheme.palette.lead.light,
                                '&:hover': {
                                    color: publicTheme.palette.lead.main,
                                },
                            }}
                            
                        >
                            <MenuIcon />
                        </IconButton>

                        {session?.session?.user?.aud === "authenticated" ? (
                            <IconButton 
                                id="Admin-Main"
                                disableRipple 
                                edge="end" 
                                component={Link} 
                                to="/admin"   
                                sx={{ 
                                    height: '40px',
                                    pt: '4px',
                                    pb: '12px',
                                    display: windowWidth > 900 ? 'block' : 'none',
                                    color: publicTheme.palette.lead.light,
                                    '&:hover': {
                                        color: publicTheme.palette.lead.main,
                                    },
                                }}
                                
                            >
                                <SettingsIcon />
                            </IconButton>
                        ) : (
                            null
                        )}

                        <PublicSmallNav />

                    </Toolbar>
                </AppBar>
                
                <Main ref={ref} id="main" open={drawer} sx={{ overflow: 'hidden', }} >
                    
                    <DrawerHeader />
                    
                    <Outlet 
                        // context={[open, setOpen]}
                    />

                    {showControls(history.pathArray) && 
                        <Controls 
                            open={drawer} 
                            //show={show} 
                            setOpen={handleDrawerOpen} 
                        />
                    }
                </Main>

                <Drawer
                    PaperProps={{ 
                        style: {
                            background: publicTheme.palette.wheat.light,
                        }
                    }}
                    sx={{
                        width: drawerWidth,
                        //zIndex: -100,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    //open={open}
                    open={drawer}
                >
                    <DrawerHeader sx={{ height: '39px', }} >
                        <IconButton 
                            onClick={handleDrawerClose}
                            sx={{ 
                                height: '39px',
                                pt: '6px',
                                pb: '10px',
                            }}
                            disableRipple
                        >
                            {publicTheme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>

                    {/* <Divider /> */}

                    <PublicContentInfo />
                    
                </Drawer>

                {/* <PublicFooter open={open} show={show} setShow={(e) => handleFooterShow(e)} /> */}

            </Box>
        </ThemeProvider>
    );
};

export default Home;