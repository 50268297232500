import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import YoutubeEmbed from '../components/YoutubeEmbed';
import { publicTheme } from '../styles/publicStyles';
import { videoDialogLayout, sortByDate } from '../utils';
import { useElementSize } from '../components/useElementSize' 

const VideoView = (props) => {

    const { all } = props;

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();
    let navigate = useNavigate();

    const allContentVideos = useSelector(state => state.content.content_videos);
    const contentVideos = all ? allContentVideos.slice().sort(sortByDate) : allContentVideos
        .filter((v, index) => parseInt(v.content_id, 10) === parseInt(params.contentId, 10))
        .sort(sortByDate);
    const contentVideosIds = contentVideos.map(cv => cv.video_id);
    const index = contentVideosIds.indexOf(params.videoId); 
    const contentVideo = contentVideos[index];
    const publicContent = useSelector(state => state.content.content);
    const content = contentVideo.content_id ? publicContent.find(c => c.id === contentVideo.content_id) : null;

    const [frameSize, setFrameSize] = useState( { w: 960, h: 540 }); 

    // HANDLE NAVIGATE BETWEEN VIDEOS
    const handleNavVideo = (index) => {
        const contentPath = all ? 'all' : params.contentId;

        if((index % contentVideos.length) >= 0) {
            const newIndex = index % contentVideos.length;
            const targetVideoId = contentVideosIds[newIndex]; 
            navigate(`/content/${contentPath}/video/${targetVideoId}`);
        } else {
            const newIndex = contentVideos.length + (index % contentVideos.length);
            const targetVideoId = contentVideosIds[newIndex]; 
            navigate(`/content/${contentPath}/video/${targetVideoId}`);
        };
    };


    // IMAGE BOX REF TARGET AND SIZE
    const ref = useRef(null);
    const size = useElementSize(ref); 

    // TRACK MAIN WIDTH 
    useEffect(() => {
        const getMainSize = (size) => { 
            const { width, height } = size;
            const mainsize = { 
                width: width, 
                height: height
            };
            
            return mainsize;
        };
            
        setFrameSize(videoDialogLayout(getMainSize(size).width, getMainSize(size).height));
    }, [size]);

    // TRACK KEYBOARD EVENTS 
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'ArrowLeft') 
                handleNavVideo(index - 1);
            if (e.key === 'ArrowRight')
                handleNavVideo(index + 1);
            if (e.key === 'Escape' && params.contentId) 
                navigate(`/content/${params.contentId}`);
            if (e.key === 'Escape') 
                navigate('/relatedcontent/1');    
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [index, navigate, params]);
    

    return (
        <ThemeProvider theme={publicTheme} > 
            <Container maxWidth={false} disableGutters sx={{height: '100%' }}>
                <CssBaseline />
                <AppBar  color="inherit" elevation={0} sx={{ position: 'fixed' }}>
                    <Toolbar variant="dense">
                        <Box sx={{ height: '28px', width: '28px' }} />
                        <Box 
                            sx={{ 
                                flexGrow: 1,
                                display: 'flex',
                                direction: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} 
                        >
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton size="small" onClick={() => handleNavVideo(index - 1)}>
                                    <NavigateBeforeRoundedIcon fontSize="inherit" />
                                </IconButton> 
                                <Typography variant="caption" component="div" >
                                    {`${index+1}/${contentVideos.length}`}
                                </Typography>
                                <IconButton size="small" onClick={() => handleNavVideo(index + 1)}> 
                                    <NavigateNextRoundedIcon fontSize="inherit" />
                                </IconButton>
                            </Stack>
                        </Box>
                        <IconButton
                            component={Link}
                            to={params.contentId ? `/content/${params.contentId}` : '/relatedcontent/1'} 
                            size="small"
                            disableRipple
                            edge="end"
                            color="inherit"
                            aria-label="close"
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                
                <Box 
                    ref={ref} 
                    id="video-dialog"
                    sx={{ 
                        display: 'flex',                                 
                        direction: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%', 
                        height: '100vh',
                        px: 3,
                        pt: 18,
                        pb: 22,
                    }} 
                >
                    <Box
                        sx={{ 
                            width: frameSize.w, 
                            height: frameSize.h,
                        }} 
                    >
                        <YoutubeEmbed embedId={params.videoId} />
                    </Box>
                </Box>
                <Box 
                    sx={{ 
                        position: 'absolute', 
                        bottom: 0, 
                        display: 'flex',
                        direction: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%', 
                        height: '40px', 
                        mb: 4,
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {content && <Typography variant="caption" component="h1" sx={{ fontWeight: 600, textTransform: 'none' }}> 
                            {content.title}
                        </Typography>}
                        {content && <Typography variant="caption" component="h1" sx={{ fontWeight: 600, textTransform: 'none' }}> 
                            |
                        </Typography>}
                        {contentVideo.title && <Typography variant="caption" component="div" sx={{ fontWeight: 600, textTransform: 'none' }}>
                            {contentVideo.title}
                        </Typography>}
                        {contentVideo.date && <Typography variant="caption" component="h1" sx={{ fontWeight: 400, textTransform: 'none' }}>
                            {contentVideo.date.year}
                        </Typography>}
                        {contentVideo.description && <Typography variant="caption" component="h1" sx={{ fontWeight: 400, textTransform: 'none' }}>
                            {contentVideo.description}
                        </Typography>}
                    </Stack>
                </Box> 
            </Container>
        </ThemeProvider>

    );

};

export default VideoView;

VideoView.defaultProps = {
    section: false,
}

VideoView.propTypes = {
    all: PropTypes.bool,
};


