import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { sortImages, mainImageLayout, publicImageDescription } from '../utils'; 
 
// MY HISTORY HOOK
import { useHistory } from './history';

const PublicMainImageDescription = () => {

    // MY HISTORY HOOK
    const history = useHistory();      

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();

    // REDUX SELECTORS
    const images = useSelector(state => state.images.images); 
    const mainDivSize = useSelector(state => state.content.mainDivSize); 
    const imageContainerSize = useSelector(state => state.content.publicImageGalleryContainerSize); 
    const imageIndex = 0; 
    const contentImages = images?.filter(i => parseInt(i.folder, 10) === parseInt(params.contentId, 10) && i.fileType === 'image').sort(sortImages);
    

    // CHANGE MAIN IMAGE ON HOVER THUMBS
    const [mainImage, setMainImage] = useState(null);

    const mainImageSize = mainImageLayout(mainDivSize, imageContainerSize, mainImage);
    const imageDescription = publicImageDescription(mainImage);

    // IMAGEGRID LAYOUT ELEMENTS
    const imgCount = contentImages?.length;
    
    useEffect(() => {
        if(imgCount) {
            setMainImage(contentImages[imageIndex]);
        }
        
        return () => {
        }
    }, [history.pathArray, contentImages, imgCount]);

    const mainImageDescription = () => {
        if(imgCount > 0 && mainImage && mainImageSize) 
            return (
                <Box 
                    sx={{  
                        display: 'flex',
                        direction: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%', 
                        pb: 1,
                    }}
                >
                    <Typography variant="caption" component="h1" sx={{ display: 'inline-block', textAlign: 'center' }}> 
                        {imageDescription.title && <span style={{ fontWeight: 'bold', marginRight: '6px' }}>{`${imageDescription.title} `}</span> }
                        {imageDescription.date && <span style={{ marginRight: '6px' }}>{`${imageDescription.date} `}</span>}
                        {imageDescription.complement && <span>{` ${imageDescription.complement}`}</span>}
                    </Typography>
                </Box>
            );        
        else 
            return null;
    };

    // CONTENT GALLERY
    if( history.pathArray[1] === 'content' ) 
        return mainImageDescription();
    else {
        return null;
    };

};

export default PublicMainImageDescription;

                    

  