import * as React from 'react';
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { join, logout } from '../features/sessionSlice'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { confirmPassword, validateNames } from '../utils';
import logo from '../images/logo.png';

const Signup = () => {

  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [nameFocus, setNameFocus] = useState(false)
  const [surnameFocus, setSurnameFocus] = useState(false)

  // REACT ROUTER 
  const navigate = useNavigate();
  const location = useLocation();
  const trackLocation = location.state?.from?.pathname || '/admin';

  // COMPARE PASSWORDS
  // const confirmPassword = (pass, password) => {
  //   if(pass === password && pass.length > 5)
  //     return null
  //   else
  //     return true
  // }

  // VALIDATE FIELDS
  // const validateNames = (str) => {
  //   if(str.replace(/\s+/g,"").length < 2)
  //     return true
  //   else
  //     return false
  // }

  // DISPATCH SIGN UP DATA
  const handleSignup = (firstname, surname, password) => {
    dispatch(join({ firstname, surname, password, navigate }))
  }

  return (
    <React.Fragment>
      <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >  

        <Box sx={{ maxWidth: 400, }} >
          <Box
            component="img"
            sx={{
              display: 'block',
              margin: 'auto',
              maxWidth: 400,
              overflow: 'hidden',
              width: '100%',
              mb: 3,
            }}
            src={logo}
            alt='marca maristela ribeiro'
          />
          <Typography component="p" variant="overline" align="center" gutterBottom sx={{ mb: 1, }}>
            Inscrição Administrativa
          </Typography>
          <TextField
            value={name}
            onChange={e => setName(e.target.value)}
            required
            error={validateNames(name)}
            fullWidth
            label="Primeiro Nome"
            id="input-first-name"
            size="small"
            onFocus={() => setNameFocus(true)}
            onBlur={() => setNameFocus(false)}
            helperText={nameFocus ? 'ex: Fulano' : null}
            InputLabelProps={{ shrink: true }}
            sx={{ my: 1,}}
          />
          <TextField
            value={surname}
            onChange={e => setSurname(e.target.value)}
            required
            error={validateNames(surname)}
            fullWidth
            label="Sobrenome"
            id="input-surname"
            size="small"
            onFocus={() => setSurnameFocus(true)}
            onBlur={() => setSurnameFocus(false)}
            helperText={surnameFocus ? 'ex: Souza de Tal' : null}
            InputLabelProps={{ shrink: true }}
            sx={{ my: 1,}}
          />
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            fullWidth
            label="Senha"
            id="input-password"
            size="small"
            type="password"
            InputLabelProps={{ shrink: true }}
            sx={{ my: 1,}}
          />
          <TextField
            value={confirmedPassword}
            error={confirmPassword(confirmedPassword, password)}
            onChange={e => setConfirmedPassword(e.target.value)}
            required
            fullWidth
            label="Confirme senha"
            id="input-confirm-password"
            size="small"
            helperText={confirmPassword(confirmedPassword, password) ? 'Ao menos 6 caracteres, sem espaços em branco' : null}
            type="password"
            InputLabelProps={{ shrink: true }}
            sx={{ my: 1,}}
          />
          <Button 
            variant="contained" 
            fullWidth 
            size="large" 
            sx={{ my: 1,}} 
            disabled={confirmPassword(confirmedPassword, password)}
            onClick={e => {
              e.preventDefault();
              handleSignup(name, surname, password, navigate, trackLocation);
            }}
          >
            Inscrever-se
          </Button>
          <Button 
            variant="contained" 
            fullWidth 
            size="large" 
            sx={{ my: 1,}} 
            //disabled={confirmPassword(confirmedPassword, password)}
            onClick={e => {
              e.preventDefault();
              dispatch(logout());
            }}
          >
            Sair
          </Button>
        </Box>
      <Box sx={{ py: 2, }} >
      <Typography component="span" variant="p" align="center" >
          Já é membro? <Link to="/signin" color="inherit" underline="hover" >Acesse aqui</Link>
        </Typography>
      </Box>
    </Box>
    </React.Fragment>
  );
}

export default Signup