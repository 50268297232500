import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { 
    selectArticles,  
    selectAuthorArticles, 
    selectCatalogues, 
    selectClipping, 
    selectRelatedContentChronology } from '../features/imagesSlice';
import { selectVideosYears, selectContentYears } from '../features/contentSlice';  
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updateDrawer } from '../features/sessionSlice'; 
import { updatePublicImageGalleryContainerSize, selectVideosCategories } from '../features/contentSlice'; 
import { ThemeProvider } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Logo from './Logo';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';
import { publicTheme, MenuItem } from '../styles/publicStyles'; 
import { sxProps, headerProps } from '../styles/publicRelatedContentStyles';
import { useElementSize } from './useElementSize';
import { articleContent, articleAuthors, videoContent, sortImagekitFileByDate, sortByDate } from '../utils';
import { useHistory } from './history';

const PublicChronology = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();

    // MY HISTORY HOOK
    const history = useHistory();    

    // REDUX SELECTORS
    const dispatch = useDispatch();  

    // REF TO IMAGE GRID
    const ref = useRef(null);
    const size = useElementSize(ref);
    // REF TO SECTION GROUP MENU 
    const anchorContentRef = useRef(null);

    // RELATED CONTENT MENU ELEMENTS
    const relatedContentChronology = useSelector(selectRelatedContentChronology); 
    const articles = useSelector(selectArticles); 
    const authorArticles = useSelector(selectAuthorArticles); 
    const catalogues = useSelector(selectCatalogues); 
    const clipping = useSelector(selectClipping); 
    const contentVideos = useSelector(state => state.content.content_videos);
    const content = useSelector(state => state.content.content).filter(c => c.status === 1);
    const categories = useSelector(state => state.sections.categories);
    const contentCategories = useSelector(state => state.content.content_categories);
    const categorizedContent = content.map(c => {
        let categoryId = contentCategories.find(ccat => ccat.content_id === c.id)?.category_id; 
        let category = categories.find(cat => cat.id === categoryId);

        return ({
            ...c,
            category: category
        })
    }); 
    const contentYears = useSelector(selectContentYears); 
    const videosCategories = useSelector(selectVideosCategories); 
    const videosYears = useSelector(selectVideosYears);
    const relatedContents = useSelector(state => state.content.related_content);
    const categoryContents = [
        { id: 0, name: 'Trabalhos' },
        ...relatedContents,
    ]; 
    const relatedContent = categoryContents.find(cc => cc.id === parseInt(params.chronologyRelatedContentId, 10));    
    const mainDivSize = useSelector(state => state.content.mainDivSize);  

    const [open, setOpen] = useState(false);
    const [contentIndex, setContentIndex] = useState(0); 
    const [anchorContentEl, setAnchorContentEl] = useState(null);

    useEffect(() => { 
        const contentIndex = categoryContents.map(cc => cc.id).indexOf(relatedContent.id);

        setContentIndex(contentIndex);
        
    }, [history.pathArray]);   

    // HANDLE CONTENT MENU
    const handleClick = (event) => {
        setAnchorContentEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };
    const handleContentClose = (event) => {
        setOpen(false); 
    };

    const handleClose = (event) => {
        setTimeout(() => {
            setAnchorContentEl({});
        }, "100"); 
    };  

    const handleCloseClick = (event) => {
        setAnchorContentEl({}); 
    };  

    // CLOSE DRAWER ON LOAD
    useEffect(() => {
        dispatch(updateDrawer(false));
    }, []);
    
    // TRACK MAIN WIDTH
    useEffect(() => {
        const getMainSize = (size) => { 
            const { x, y, width, height, top, right, left, bottom, } = size;
            const mainsize = {
                x: x, 
                y: y, 
                width: width, 
                height: height, 
                top: top, 
                right: right, 
                left: left, 
                bottom: bottom
            };
            
            return mainsize;
        };
         
        dispatch(updatePublicImageGalleryContainerSize(getMainSize(size)));
    }, [mainDivSize, size, dispatch]);  

    // RELATED CONTENT LISTS
    const showRelateContent = () => {
 
        if(parseInt(params.chronologyRelatedContentId, 10) === 0 
                && contentYears?.length > 0 
                && categorizedContent?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {contentYears.map(cc => {
                        if(cc.interval) {
                            return ( 
                                <Stack
                                    key={cc.string}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{ width: '100%', }}
                                >
                                    <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                        {cc.string}
                                    </Typography>
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={0.6}
                                        sx={{ pr: 3, width: '100%', }}
                                    >
                                        {categorizedContent
                                            .slice()
                                            .filter(fc => fc.date.start.year === cc.start && fc.date.end.year === cc.end && fc.date.interval === true)
                                            .map(c => (
                                                <Box 
                                                    key={c.fileId}
                                                    sx={{...sxProps}}
                                                    component={Link}
                                                    to={`/content/${c.id}`}
                                                    //target="_blank"
                                                >
                                                    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                                        <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                            {articleContent(c.title, c.date.start.year, null)}
                                                        </Typography>
                                                        <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, }}>
                                                            {articleAuthors(c.category?.name)} 
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                        ))}
                                    </Stack>
                                </Stack>
                            );
                        } else {
                            return (
                                <Stack
                                    key={cc.string}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{ width: '100%', }}
                                >
                                    <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                        {cc.string}
                                    </Typography>
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={0.6}
                                        sx={{ pr: 3, width: '100%', }}
                                    >
                                        {categorizedContent
                                            .slice()
                                            .filter(fc => fc.date.start.year === cc.start && fc.date.interval === false)
                                            .map(c => (
                                                <Box 
                                                    key={c.fileId}
                                                    sx={{...sxProps}}
                                                    component={Link}
                                                    to={`/content/${c.id}`}
                                                    //target="_blank"
                                                >
                                                    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                                        <Typography variant="caption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                            {articleContent(c.title, c.date.start.year, null)}
                                                        </Typography>
                                                        <Typography variant="caption" component="div" sx={{ pr: 1, }}>
                                                            {articleAuthors(c.category?.name)} 
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                        ))}
                                    </Stack>
                                </Stack>
                            );
                        }
                    })}
                </Stack>
            );
        } if(parseInt(params.chronologyRelatedContentId, 10) === 1 
            && videosCategories?.length > 0 
            && contentVideos?.length > 0) { 
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {videosYears.map(vc => ( 
                        <Stack
                            key={vc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {vc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {contentVideos
                                    .slice()
                                    .sort(sortByDate)
                                    .filter(acv => acv.date.year === vc)
                                    .map(cv => (
                                    <Box 
                                        key={cv.id}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={`/content/all/video/${cv.video_id}`}
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3} >
                                            <Typography variant="caption" component="div" sx={{ pr: 1, pt: 0.5, }} >
                                                {videoContent(cv.title, cv.date.year, cv.description)}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );
        } if(parseInt(params.chronologyRelatedContentId, 10) === 2 
                && relatedContentChronology.articles?.length > 0 
                && articles?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {relatedContentChronology.articles.map(cc => ( 
                        <Stack
                            key={cc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {cc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {articles
                                    .slice()
                                    //.sort(sortImagekitFileByDate)
                                    .filter(fc => fc.date === cc)
                                    .map(c => (
                                    <Box 
                                        key={c.fileId}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={c.url}
                                        target="_blank"
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                            <Typography variant="caption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                {articleContent(c.title, c.date, c.description)}
                                            </Typography>
                                            <Typography variant="caption" component="div" sx={{ pr: 1, }}>
                                                {articleAuthors(c.technique,c.dimensions)} 
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );
        } if(parseInt(params.chronologyRelatedContentId, 10) === 3  
                && relatedContentChronology.authorArticles?.length > 0 
                && authorArticles?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {relatedContentChronology.authorArticles.map(cc => ( 
                        <Stack
                            key={cc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {cc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {authorArticles
                                    .slice()
                                    .sort(sortImagekitFileByDate)
                                    .filter(fc => fc.date === cc)
                                    .map(c => (
                                    <Box 
                                        key={c.fileId}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={c.url}
                                        target="_blank"
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                            <Typography variant="caption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                {articleContent(c.title, c.date, c.description)}
                                            </Typography>
                                            <Typography variant="caption" component="div" sx={{ pr: 1, }}>
                                                {articleAuthors(c.technique,c.dimensions)} 
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );

        } if(parseInt(params.chronologyRelatedContentId, 10) === 4  
                && relatedContentChronology.catalogues?.length > 0 
                && catalogues?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {relatedContentChronology.catalogues.map(cc => ( 
                        <Stack
                            key={cc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {cc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {catalogues
                                    .slice()
                                    .sort(sortImagekitFileByDate)
                                    .filter(fc => fc.date === cc)
                                    .map(c => (
                                    <Box 
                                        key={c.fileId}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={c.url}
                                        target="_blank"
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                            <Typography variant="caption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                {articleContent(c.title, c.date, c.description)}
                                            </Typography>
                                            <Typography variant="caption" component="div" sx={{ pr: 1, }}>
                                                {articleAuthors(c.technique,c.dimensions)} 
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );
        }  if(parseInt(params.chronologyRelatedContentId, 10) === 5  
                && relatedContentChronology.clipping?.length > 0 
                && clipping?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {relatedContentChronology.clipping.map(cc => ( 
                        <Stack
                            key={cc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {cc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {clipping
                                    .slice()
                                    .sort(sortImagekitFileByDate)
                                    .filter(fc => fc.date === cc)
                                    .map(c => (
                                    <Box 
                                        key={c.fileId}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={c.url}
                                        target="_blank"
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                            <Typography variant="caption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                {articleContent(c.title, c.date, c.description)}
                                            </Typography>
                                            <Typography variant="caption" component="div" sx={{ pr: 1, }}>
                                                {articleAuthors(c.technique,c.dimensions)}  
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );

        } else 
            return null;
    };

    return (
        <ThemeProvider theme={publicTheme} >
            <Container maxWidth="xl"  >
                <Grid container spacing={1} >
                    <Grid item xs={12} sx={{ mx: mainDivSize.width > 830 ? 10 : 2, }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                            sx={{ pt: 4, }}
                        >
                            <Logo />
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                            sx={{ pt: 3, }}
                        >

                            <Box sx={{ flexGrow: 1, }} />

                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={0.5}
                                
                            >

                                {categoryContents && categoryContents.length > 1 ? (
                                    <IconButton 
                                        size="small" 
                                        color={publicTheme.palette.text.primary} 
                                        id="content-menu"
                                        onClick={handleClick}
                                        ref={anchorContentRef}
                                    >
                                        <ArrowDropDownIcon />
                                    </IconButton>
                                ) : null}

                                <Popper
                                    placement="bottom-start"
                                    sx={{ zIndex: 2000, }}
                                    open={open}
                                    onMouseLeave={handleContentClose}
                                    anchorEl={anchorContentEl}
                                    role={undefined}
                                    transition
                                    disablePortal
                                >
                                    {({ TransitionProps }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin: 'right top',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose} >
                                                    <MenuList id="content-menu" >
                                                        {categoryContents.map((cc, index) => (
                                                            <MenuItem
                                                                key={cc.id}
                                                                onClick={handleCloseClick}
                                                                selected={index === contentIndex}
                                                                component={Link}
                                                                to={`/chronology/${cc.id}`}
                                                                sx={{ 
                                                                    fontSize: '0.75rem', 
                                                                    fontWeight: 300,  
                                                                    textTransform: 'none', 
                                                                    color: index === contentIndex ? publicTheme.palette.common.white : publicTheme.palette.text.primary, 
                                                                }}
                                                            >
                                                                {cc.name}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>  
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper> 

                                <Typography component="h1" variant="h6" style={{ textTransform: 'none' }}>
                                    {relatedContent ? relatedContent.name : ''}
                                </Typography>

                            </Stack>

                        </Stack>
                    </Grid>

                    <Grid 
                        item 
                        xs={12} 
                        sx={{ mx: mainDivSize.width > 830 ? 10 : 2, pt: 0, mt: 0, }}
                    >
                        <Box 
                            ref={ref} 
                            id="publicImageGalleryContainer"
                            sx={{ 
                                display: 'flex',
                                background: publicTheme.palette.wheat.light,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                minHeight: '70vh',
                                pb: 4,
                                mb: 5
                            }} 
                        >
                            <Grid container spacing={3} sx={{ mx: 0, my: 0, }}>
                                <Grid item xs={12}>
                                    <Box  
                                        sx={{ 
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        {showRelateContent()}
                                    </Box> 
                                </Grid>
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default PublicChronology;