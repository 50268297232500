import { createTheme, styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiPaper from '@mui/material/Paper';
import MuiButton from '@mui/material/Button';
import MuiMenuItem from '@mui/material/MenuItem';

import '@fontsource/raleway';
import '@fontsource/open-sans';
import '@fontsource/maitree';

import '@fontsource/raleway/100.css';
import '@fontsource/raleway/200.css';
import '@fontsource/raleway/300.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/800.css';
import '@fontsource/raleway/900.css';

import '@fontsource/maitree/200.css';
import '@fontsource/maitree/300.css';
import '@fontsource/maitree/400.css';
import '@fontsource/maitree/500.css';
import '@fontsource/maitree/600.css';
import '@fontsource/maitree/700.css';

export const drawerWidth = 420; 
export const footerHeight = 150;

export const PaperControls = styled(MuiPaper, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, show }) => ({
        transition: theme.transitions.create(['margin', 'background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: publicTheme.palette.lead.light,
        position: 'fixed', 
        zIndex: 900, 
        bottom: 40,
        right: 10,
        marginRight: 10,
        '&:hover': {
            transition: theme.transitions.create(['margin', 'background'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            background: publicTheme.palette.lead.main,
        },
        ...(open && {
            marginRight: `${drawerWidth + 10}px`,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        ...(show && {
            marginBottom: `${footerHeight - 30}px`,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),        
        ...(open && show && {
            marginRight: drawerWidth - 1,
            marginBottom: `${footerHeight - 30}px`, 
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);

export const Main = styled('main', { 
    shouldForwardProp: (prop) => prop !== 'open' 
    })(({ theme, open }) => ({
        flexGrow: 1,
        height: '90%',
        //padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        // borderBottom: 1,
        // borderColor: 'divider',
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export const PaperFooter = styled(MuiPaper, {
    shouldForwardProp: (prop) => prop !== 'open',
    shouldForwardProp: (prop) => prop !== 'show',
    })(({ theme, open, show }) => ({
        zIndex: 2000,
        height: '30px',
        background: 'linear-gradient(to top, rgba(0, 0, 0,0.5) 0%, rgba(0, 0, 0,0) 100%)',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        transition: theme.transitions.create(['margin', 'width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width', 'height'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
    ...(show && {
        transition: theme.transitions.create(['margin', 'width', 'height', 'background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background: '#000',
        height: `${footerHeight}px`,
    }),
    ...(show && open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width', 'height', 'background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background: '#000',
        marginRight: drawerWidth,
        height: `${footerHeight}px`,
    }),
}));

export const PaperLegend = styled(MuiPaper, {
    shouldForwardProp: (prop) => prop !== 'open',
    shouldForwardProp: (prop) => prop !== 'show',
    })(({ theme, open, show }) => ({
        position: 'absolute',
        background: 'rgba(244, 240, 235, 0.75)', 
        zIndex: 900, 
        ml: 1.1,
        padding: 2,
        bottom: 40,
        left: 1.1,
        transition: theme.transitions.create(['margin', 'width', 'height', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        opacity: '0',
        transition: theme.transitions.create(['margin', 'width', 'height', 'opacity'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(show && {
        marginBottom: `${footerHeight - 30}px`, 
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(show && open && {
        //width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width', 'height', 'background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const ButtonMenu = styled(MuiButton, { 
    shouldForwardProp: (prop) => prop !== 'active' 
    })(({ theme, active }) => ({
        height: '80px', 
        marginBotton: 0,
        marginTop: 0,
        paddingBottom: 0,
        paddingTop: 0,
        fontWeight: 600,
        '&:hover': {
            background: theme.palette.lead.light,
            color: theme.palette.common.white,
        },
        transition: theme.transitions.create(['color', 'background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(active && {
            background: theme.palette.lead.main,
            color: theme.palette.common.white,
            transition: theme.transitions.create(['color', 'background'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);

export const ContentMenuButton = styled(MuiButton, { 
    shouldForwardProp: (prop) => prop !== 'active' 
    })(({ theme }) => ({
        //height: '54px', 
        fontSize: '0.65rem', 
        marginBotton: 0,
        marginTop: 0,
        paddintBottom: 0,
        paddintTop: 0,
        fontWeight: 400,
        '&:hover': {
            //background: theme.palette.lead.light,
            background: theme.palette.lead.main,
            color: theme.palette.common.white,
        },
        transition: theme.transitions.create(['color', 'background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // ...(active && {
        //     background: theme.palette.lead.main,
        //     color: theme.palette.common.white,
        //     transition: theme.transitions.create(['color', 'background'], {
        //         easing: theme.transitions.easing.easeOut,
        //         duration: theme.transitions.duration.enteringScreen,
        //     }),
        // }),
    }),
);

export const MenuItem = styled(MuiMenuItem, { 
    shouldForwardProp: (prop) => prop !== 'selected' 
    })(({ theme, selected }) => ({
        background: theme.palette.common.white,
        color: theme.palette.lead.main,
        '&:hover, &:active': {
            background: theme.palette.lead.main,
            color: theme.palette.common.white,
        },
        transition: theme.transitions.create(['color', 'background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(selected && {
            background: theme.palette.lead.light,
            color: theme.palette.common.white,
            transition: theme.transitions.create(['color', 'background'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);

export const publicTheme = createTheme({
    palette: {
        common: {
            black: '#000',
            white: '#fff'
        },
        primary: {
          light: '#da5857',
          main: '#ce423f',
          dark: '#dd4a41',
          contrastText: 'white',
        },
        secondary: {
          light: '#cbd5db',
          main: '#8A9CAA', 
          dark: '#5681a9',
          contrastText: '#black', 
        },

        blood: {
            light: '#da5857',
            main: '#ce423f',
            dark: '##dd4a41',
            contrastText: 'white',
          },
        lead: {
            light: '#abb8c2',
            main: '#8A9CAA', 
            dark: '#5681a9',
            contrastText: '#black', 
          },
        wheat: {
            light: '#eeede7',
            main: '#B9B0A1',
            dark: '#706242',
            contrastText: 'white',            
        },
        grey: {
            light: '#b4b5bb',
            main: '#6c6d72',
            dark: '#3a3b3f',
            contrastText: 'black',            
        },

        text: {
            primary: 'rgba(0, 0, 0, 0.95)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },

        divider: 'rgba(0, 0, 0, 0.12)',

        background: {
            paper: 'white',
            default: 'white',
        },

        action:{
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            hoverOpacity: 0.04,
            selected: 'rgba(0, 0, 0, 0.08)',
            selectedOpacity: 0.08,
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(0, 0, 0, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },

        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,

        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },



    components: {

        MuiMenuList: {
            defaultProps: {
                autoFocusItem: true, 
            },
        },

        MuiMenuItem: {
            defaultProps: {
                autoFocus: true,
                dense: true,
            },
            styleOverrides: {
                root: {
                    fontSize: '0.65rem', 
                    fontWeight: 400, 
                    textTransform: 'uppercase',
                },  
            },
        },

        MuiChip: {
            defaultProps: {
                variant: 'filled',
                clickable: true, 
                size: 'small',
            },
            styleOverrides: {
                root: {
                  fontSize: '9px',
                  textTransform: 'uppercase',
                  //padding: 0,
                },
            },

        },
    },

    shape: {
        borderRadius: 0,
    },

    mixins: {
        toolbar: {
            minHeight: 80,
            "@media (min-width:0px)": {
                "@media (orientation: landscape)": {
                    minHeight: 80,
                },
            },
            "@media (min-width:600px)": { 
                minHeight: 80, 
            },
        },
    },

    typography: {
        htmlFontSize: 16,
        fontFamily: "'Raleway', sans-serif",
        fontSize: 14,
        fontWeightLight: 200,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,

        h1: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 100,
            fontSize: "6rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
        },
        h2: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 100,
            fontSize: "3.75rem",
            lineHeight: 1.2,
            letterSpacing: "-0.00833em",
        },
        h3: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 400,
            fontSize: "3rem",
            lineHeight: 1.167,
            letterSpacing: "0em",
        },
        h4: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 400,
            fontSize: "2.125rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em",
        },
        h5: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 200,
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
        },
        h6: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 200,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
        },
        button: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: 1.6,
            letterSpacing: "0.02857em",
        },
        caption: {
            fontFamily: 'Maitree', 
        },
        relatedContentCaption: {
            fontFamily: 'Maitree', 
            fontSize: "0.75rem",
            lineHeight: 1.66,
            //letterSpacing: '0.03333em',
            letterSpacing: '0.00938em',
            fontWeight: 400,
        },
        contentRelatedCaption: {
            fontFamily: 'Maitree', 
            fontSize: "0.75rem",
            lineHeight: 1.5,
            fontWeight: 500
        },
        contentCaption: {
            fontFamily: 'Maitree', 
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            fontWeight: 200
        },
        boldContentCaption: {
            fontFamily: 'Maitree', 
            fontSize: '1.125rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            fontWeight: 500
        },
        pageBody: {
            fontFamily: 'Maitree', 
            fontSize: "0.875rem",
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            fontWeight: 300
        },
            
    },

});