import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSectionsTree } from '../features/sectionsSlice'; 
import { useParams, Link, useNavigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Logo from './Logo';
import { useHistory } from './history';
import { publicTheme, ContentMenuButton, MenuItem } from '../styles/publicStyles';

const PublicContentMenu = (props) => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();  
    const navigate = useNavigate(); 

    const sectionsTree = useSelector(selectSectionsTree);

    // MY HISTORY HOOK
    const history = useHistory();
    
    const publicContent = useSelector(state => state.content.content);
    const content = publicContent.find(c => c.id === parseInt(params.contentId, 10));
    const categories = useSelector(state => state.sections.categories);
    const contentCategories = useSelector(state => state.content.content_categories);  
    const categoriesOfContent = useSelector(state => state.content.content_categories)
        .filter(cc => cc.content_id === parseInt(params.contentId, 10));       
    const section = sectionsTree.find(st => st.id === content.section_id); 
    const sectionGroups = section.sectionGroups; 

    const category = categories.find(c => c.id === categoriesOfContent[0].category_id);  
    const sectionGroup = sectionGroups.find(sg => sg.groupCategories.includes(category));
    const categoryContentsIds = contentCategories
            .filter(cc => cc.category_id === category.id)
            .map(ccid => ccid.content_id); 
    const categoryContents = publicContent.filter(pc => categoryContentsIds.includes(pc.id)); 
    
    const anchorRef = useRef(null); 
    const anchorContentRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [contentIndex, setContentIndex] = useState(0); 
    const [selectedIndex, setSelectedIndex] = useState(0); 
    const [anchorEl, setAnchorEl] = useState({});
    const [anchorContentEl, setAnchorContentEl] = useState(null);

    useEffect(() => {
        const categoryIndex = sectionGroup.groupCategories.map(sggc => sggc.id).indexOf(category.id);   
        const contentIndex = categoryContents.map(cc => cc.id).indexOf(content.id);

        setContentIndex(contentIndex);
        setSelectedIndex(categoryIndex);
        
    }, [history.pathArray]);

    // HANDLE CONTENT MENU
    const handleClick = (event) => {
        setAnchorContentEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };
    const handleContentClose = (event) => {
        setOpen(false); 
    };

    // HANDLE CATEGORIES MENU
    const handleMenu = (event) => {
        if (anchorEl[event.currentTarget.id] === event.currentTarget) 
            setAnchorEl({...anchorEl, [event.currentTarget.id]: null});
        else 
            setAnchorEl({...anchorEl, [event.currentTarget.id]: event.currentTarget});
    };
    const handleClose = (event) => {
        setTimeout(() => {
            setAnchorEl({});
        }, "100"); 
    };

    // HANDLE NAVIGATION UNDER MENU CLICK
    const handleMenuItemClick = (event, cat) => {
        const contentDestination = contentCategories.find(cc => cc.category_id === cat.id); 
        if(contentDestination?.content_id)
            navigate(`/content/${contentDestination.content_id}`);
        setAnchorEl({});
    };

    return (
        <ThemeProvider theme={publicTheme} > 
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                sx={{ pt: 4, }}
            >
                <Logo />
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                sx={{ pt: 3, }}
            >
                <ButtonGroup 
                    size="small"
                    color="lead" 
                    disableElevation
                    aria-label="menu de categorias"
                >
                    {sectionGroups && sectionGroups.map(sg => (
                        <div key={sg.id}> 
                            <ContentMenuButton 
                                ref={anchorRef} 
                                key={sg.id}
                                id={`group-${sg.id}-menu`}
                                aria-controls={open ? `group-${sg.id}-menu` : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="selecionar categoria"
                                aria-haspopup="menu"
                                onClick={handleMenu}
                                endIcon={<ArrowDropDownIcon />}
                            >
                                {sg.groupCategories[selectedIndex].name}
                            </ContentMenuButton>



                            <Popper
                                placement="bottom-start"
                                sx={{ zIndex: 1, }}
                                open={anchorEl.hasOwnProperty(`group-${sg.id}-menu`)}
                                onMouseLeave={handleClose}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: 'right top',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList id={`group-${sg.id}-menu`} >
                                                    {sg.groupCategories.map((gc, index) => (
                                                        <MenuItem
                                                            key={gc.id}
                                                            selected={index === selectedIndex}
                                                            onClick={(event) => handleMenuItemClick(event, gc)}
                                                        >
                                                            {gc.name}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>  
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper> 

                        </div>
                        
                    ))}
                </ButtonGroup>


                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                    
                >

                    {categoryContents && categoryContents.length > 1 ? (
                        <IconButton 
                            size="small" 
                            color={publicTheme.palette.text.primary} 
                            id="content-menu"
                            onClick={handleClick}
                            ref={anchorContentRef}
                        >
                            <ArrowDropDownIcon />
                        </IconButton>
                    ) : null}

                    <Popper
                        placement="bottom-start"
                        sx={{ zIndex: 1, }}
                        open={open}
                        onMouseLeave={handleContentClose}
                        anchorEl={anchorContentEl}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: 'right top',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="content-menu" >
                                            {categoryContents.map((cc, index) => (
                                                <MenuItem
                                                    key={cc.id}
                                                    selected={index === contentIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                    component={Link}
                                                    to={`/content/${cc.id}`}
                                                    sx={{ 
                                                        fontSize: '0.75rem', 
                                                        fontWeight: 300,  
                                                        textTransform: 'none', 
                                                        color: index === contentIndex ? publicTheme.palette.common.white : publicTheme.palette.text.primary, 
                                                    }}
                                                >
                                                    {cc.title}
                                                </MenuItem>
                                            ))}
                                        </MenuList>  
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper> 

                    <Typography component="h1" variant="h6" style={{ textTransform: 'none' }}>
                        {content.title}
                    </Typography>

                </Stack>
              
            </Stack>
        </ThemeProvider>
    );
};

export default PublicContentMenu;