import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import DefaultDialog from './DefaultDialog';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ViewListIcon from '@mui/icons-material/ViewList';
// import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
// import BookmarksIcon from '@mui/icons-material/Bookmarks';
// import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
// import GroupWorkIcon from '@mui/icons-material/GroupWork';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ContactsIcon from '@mui/icons-material/Contacts';
import { useHistory } from './history';

import Invite from './Invite';
import SectionCreate from './SectionCreate';  

const ContextMenu = () => {

    // MY HISTORY HOOK
    const history = useHistory();
    const section = history?.pathArray[2] || ''
    const context = history?.pathArray[3] || ''

    // TRACK ROUTES 
    useEffect(() => {
    }, [history.location]);

    // DIALOG STATES 
    const [dialogOpen, setDialogOpen] = useState(false);

    // HANDLE TOGGLE DIALOG
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    function activeMenu(context, match) {
        if(context === match)
            return true;
        else
            return false;
    };

    if(section === 'content')
    return (
        <div>

            <ListItemButton component={Link} to="/admin/content" selected={activeMenu(context,'')} >
                <ListItemIcon>
                    <ViewListIcon />
                </ListItemIcon>
                <ListItemText primary="Índice de Publicações" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/content/create" selected={activeMenu(context,'create')} >
                <ListItemIcon>
                    <AddCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Inserir Publicação" />
            </ListItemButton>

            <ListItemButton onClick={() => history.goBack()} >
                <ListItemIcon>
                    <ArrowBackIcon />  
                </ListItemIcon>
                <ListItemText primary="Voltar" />
            </ListItemButton>

        </div>
    );

    if(section === 'contentvideos')
    return (
        <div>

            {/* <ListItemButton component={Link} to="/admin/content/all" selected={activeMenu(context,'all')} >
                <ListItemIcon>
                    <ViewListIcon />
                </ListItemIcon>
                <ListItemText primary="Índice de Conteúdo" />
            </ListItemButton>

            <ListItemButton component={Link} to="/admin/content/create" selected={activeMenu(context,'create')} >
                <ListItemIcon>
                    <AddCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Inserir Vídeo" />
            </ListItemButton> */}

            <ListItemButton onClick={() => history.goBack()} >
                <ListItemIcon>
                    <ArrowBackIcon />  
                </ListItemIcon>
                <ListItemText primary="Voltar" />
            </ListItemButton>

        </div>
    );    
        
    if(section === 'sections')
        return (
            <div>

                <ListItemButton component={Link} to="/admin/sections" selected={activeMenu(context,'')} >
                    <ListItemIcon>
                        <ViewListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Índice de Seções" />
                </ListItemButton>

                <ListItemButton >
                    <ListItemIcon>
                        <AddCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Criar Seção" onClick={handleDialogOpen}/>
                </ListItemButton>  

                {/* INVITE DIALOG */}
                <DefaultDialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    title={'Criar nova Seção'}
                    children={<SectionCreate/>}
                /> 

                {/* <ListItemButton component={Link} to="/admin/sections/series" selected={activeMenu(context,'series')} >
                    <ListItemIcon>
                        <BookmarksIcon />
                    </ListItemIcon>
                    <ListItemText primary="Índice de Séries" />
                </ListItemButton>     

                <ListItemButton component={Link} to="/admin/sections/createserie" selected={activeMenu(context,'createserie')} >
                    <ListItemIcon>
                        <BookmarkAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Criar Marcador" />
                </ListItemButton>      */}

                <ListItemButton onClick={() => history.goBack()} >
                    <ListItemIcon>
                        <ArrowBackIcon />  
                    </ListItemIcon>
                    <ListItemText primary="Voltar" />
                </ListItemButton>

            </div>
        );    
    
    if(section === 'members')
        return (
            <div>

                {/* <ListItemButton component={Link} to="/admin/members/all" selected={activeMenu(context,'all')} >
                    <ListItemIcon>
                        <ViewListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gerir Colaboradores" />
                </ListItemButton> */}

                <ListItemButton >
                    <ListItemIcon>
                        <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Convidar Colaborador" onClick={handleDialogOpen}/>
                </ListItemButton>

                {/* INVITE DIALOG */}
                <DefaultDialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    title={'Convidar Colaborador'}
                    children={<Invite/>}
                />

                {/* <ListItemButton component={Link} to="/admin/members/groups" selected={activeMenu(context,'groups')} >
                    <ListItemIcon>
                        <GroupWorkIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gerir Grupos" />
                </ListItemButton>

                <ListItemButton component={Link} to="/admin/members/creategroup" selected={activeMenu(context,'creategroup')} >
                    <ListItemIcon>
                        <EditLocationAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Criar Novo Grupo" />
                </ListItemButton> */}

                <ListItemButton onClick={() => history.goBack()} >
                    <ListItemIcon>
                        <ArrowBackIcon />  
                    </ListItemIcon>
                    <ListItemText primary="Voltar" />
                </ListItemButton>

            </div>
        );  
        
    if(section === 'pages')
        return (
            <div>

                <ListItemButton component={Link} to="/admin/pages" selected={activeMenu(context,'all')} >
                    <ListItemIcon>
                        <ViewListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Índice de Páginas" />
                </ListItemButton>

                <ListItemButton component={Link} to="/admin/pages/create" selected={activeMenu(context,'create')} >
                    <ListItemIcon>
                        <AddCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Criar Página" />
                </ListItemButton>            

                <ListItemButton onClick={() => history.goBack()} >
                    <ListItemIcon>
                        <ArrowBackIcon />  
                    </ListItemIcon>
                    <ListItemText primary="Voltar" />
                </ListItemButton>

            </div>
        );   

};

export default ContextMenu;