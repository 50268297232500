
import * as React from 'react';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateContentVideos, updateContentVideo } from '../features/contentSlice';
// REACT DATA TABLE COMPONENT
import DataTable from "react-data-table-component";
import { customStyles } from '../styles/tableTemplatesStyles'
import { findByVideoId, sliceUniqueFileString } from '../utils';

const VideoEditableTable = ( props ) => {

    const { title, stateData } = props; 

    // REDUX DISPATCH
    const dispatch = useDispatch();    

    // REACT STATES
    const [data, setData] = useState(stateData); 

    const columns = useMemo(() => {
        const handleTitleEditable = (id) => (row) => (e) => {
            
            const newRow = { ...row }; 
            newRow[id] = e.target.innerText;
            const newData = data.slice(0);
            newData[findByVideoId(data, row.id)] = newRow;

            const contentVideoObj = {
                id: newRow.id,
                content_id: newRow.content_id,
                title: newRow.title,
                video_id: newRow.video_id,
                description: newRow.description,
                date: newRow.date,
                category: newRow.category
                
            }; 

            dispatch(updateContentVideos(newData));
            dispatch(updateContentVideo(contentVideoObj));
            setData(newData);            
        };

        return [
            {
                id: 1,
                name: "ID",
                selector: row => row.id ,
                sortable: true,
                omit: true,
                width: '70px',
            },   
            {
                id: 2,
                name: "Título do Vídeo",
                selector: (row) => row.title,
                sortable: true,
                reorder: true,
                cell: (row) => (
                    <div
                        //contentEditable
                        contentEditable="plaintext-only"
                        suppressContentEditableWarning={true}
                        onBlur={handleTitleEditable("title")(row)}
                        style={{ padding: '5px', marginTop: '5px', marginBottom: '5px',  borderRadius: '12px', backgroundColor: 'lightgreen' }}
                    >
                        {row.title}
                    </div>
                ),
                grow: 3,
            },
            {
                id: 3,
                name: "Categoria",
                selector: (row) => row.category,
                sortable: true,
                reorder: true,
                cell: (row) => (
                    <div
                        //contentEditable
                        contentEditable="plaintext-only"
                        suppressContentEditableWarning={true}
                        onBlur={handleTitleEditable("category")(row)}
                        style={{ padding: '5px', marginTop: '5px', marginBottom: '5px',  borderRadius: '12px', backgroundColor: 'lightgreen' }}
                    >
                        {row.category}
                    </div>
                ),
                grow: 1,
            },
            {
                id: 4,
                name: "Identificador YouTube",
                selector: (row) => row.video_id,
                sortable: true,
                //right: true,
                reorder: true,
                cell: (row) => (
                    <div
                        //contentEditable
                        contentEditable="plaintext-only"
                        suppressContentEditableWarning={true}
                        onBlur={handleTitleEditable("video_id")(row)}
                        style={{ padding: '5px', marginTop: '5px', marginBottom: '5px',  borderRadius: '12px', backgroundColor: 'lightgreen' }}
                    >
                        {row.video_id}
                    </div>
                ),
                grow: 1,
            },  
            {
                id: 5,
                name: "Descrição",
                selector: (row) => row.description,
                sortable: true,
                reorder: true,
                cell: (row) => (
                    <div
                        //contentEditable
                        contentEditable="plaintext-only"
                        suppressContentEditableWarning={true}
                        onBlur={handleTitleEditable("description")(row)}
                        style={{ padding: '5px', marginTop: '5px', marginBottom: '5px',  borderRadius: '12px', backgroundColor: 'lightgreen' }}
                    >
                        {row.description}
                    </div>
                ),
                grow: 3,
            },
    
            // {
            //     id: 6,
            //     name: 'Ações',
            //     maxWidth: '80px',
            //     cell: row => <ActionMenu section={'contentarticles'} row={row}/>,
            //     right: true,
            //     grow: 1,
            // },
        ];
    }, [data, dispatch]);

    if(title) {
        return(  
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    striped
                    responsive
                    pagination
                    persistTableHead
                />
            );
    } else {
        return (
            <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                striped
                responsive
                pagination
                persistTableHead
            />
        );
    }
   
};

export default VideoEditableTable;


VideoEditableTable.propTypes = {
    title: PropTypes.node.isRequired,
    stateData: PropTypes.array.isRequired,
};