import * as React from 'react';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import ActionMenu from './ActionMenu'; 
import ActionMenuRelatedContent from './ActionMenuRelatedContent'; 
import { statusColorName, contentCounter, getContentCategoriesNames, getContentName, getRelatedContentName, truncate } from '../utils';  

export function useTableTemplates() {

    // IMAGEKIT
    const urlEndpoint = process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT; 
    const imgSize = 50;    

    // REDUX SELECTORS
    const dispatch = useDispatch();
    const content = useSelector(state => state.content.content);
    const sections = useSelector(state => state.sections.sections);
    const categories = useSelector(state => state.sections.categories);
    const statuses = useSelector(state => state.content.statuses);
    const contentCategories = useSelector(state => state.content.content_categories);

    const contentArticles = useSelector(state => state.content.content_articles);
        


    // COLUMNS TO CONTENT LIST
    const contentColumns = (
        [
            {
                name: 'ID',
                selector: row => row.id ,
                sortable: true,
                omit: true,
                width: '70px',
            },
            {
                name: 'Título',
                selector: row => row.title ,
                cell: row => <span style={{ wordBreak: "break-word" }}> {truncate(row.title, 15)} </span>,
                sortable: true,
                maxWidth: '400px',
                grow: 3,
            },
            {
                name: 'Destacado',
                selector: row => row.featured ,
                cell: row => row.featured ? 'Sim' : 'Não',
                sortable: true,
                maxWidth: '150px',
                grow: 1,
            },
            {
                name: 'Ano',
                selector: row => row.date.interval ? `${row.date.start.year}-${row.date.end.year}` : row.date.start.year,
                sortable: true,
                maxWidth: '100px',
                grow: 1,
            },
            {
                name: 'Seção',
                selector: row => sections.find(c => c.id === row.section_id).name,
                cell: row => sections.find(c => c.id === row.section_id).name,
                sortable: true,
                maxWidth: '140px',
                grow: 2,
            },       
            {
                name: 'Status',
                selector: row => statuses.find(s => s.id === row.status).status,
                cell: row => <Box sx={{ color: statusColorName(row.status) }} >
                                    {statuses.find(s => s.id === row.status).status} 
                                </Box>,
                sortable: true,
                maxWidth: '140px',
                grow: 2,
            },
            {
                name: 'Categorias',
                selector: row => getContentCategoriesNames(contentCategories, categories, row.id).join(', '),
                cell: row => getContentCategoriesNames(contentCategories, categories, row.id).join(', '),
                sortable: true,
                //maxWidth: '140px',
                grow: 2,
            },
            {
                name: 'Atualizado',
                selector: row => row.updated_at,
                cell: row => DateTime.fromISO(row.updated_at).setLocale('pt-br').toFormat('dd/MM/yyyy'),
                sortable: true,
                maxWidth: '120px',
                grow: 2,
            },
            {
                name: 'Ações',
                maxWidth: '80px',
                cell: row => <ActionMenu section={'content'} row={row} />,
                right: true,
                grow: 1,
            },
        ]
    );   

    // COLUMNS TO VIDEO LIST
    const contentVideosColumns = (
        [
            {
                name: 'ID',
                selector: row => row.id ,
                sortable: true,
                omit: true,
                width: '70px',
            },
            {
                name: 'Título do Vídeo',
                selector: row => row.title,
                cell: row => <span style={{ wordBreak: "break-word" }}> {truncate(row.title, 15)} </span>,
                sortable: true,
                maxWidth: '250px',
                grow: 2,
            },
            {
                name: 'Categoria',
                selector: row => row.category,
                sortable: true,
                //maxWidth: '200px',
                grow: 1,
            },   
            {
                name: 'Ano',
                selector: row => row.date.year,
                sortable: true,
                maxWidth: '100px',
                grow: 1,
            },
            {
                name: 'Publicação Relacionada',
                selector: row => getContentName(content, row.content_id),
                cell: row => getContentName(content, row.content_id),
                sortable: true,
                //maxWidth: '140px',
                grow: 2,
            },
            {
                name: 'Descrição',
                selector: row => row.description,
                cell: row => <span style={{ wordBreak: "break-word" }}> {truncate(row.description, 25)} </span>,
                sortable: true,
                //maxWidth: '200px',
                grow: 2,
            },     
            // {
            //     name: 'YouTube',
            //     selector: row => row.video_id,
            //     cell: row => <IconButton color="info" aria-label="link para youtube" size="small" component={Link} target="_blank" rel="noopener" href={`https://www.youtube.com/watch?v=${row.video_id}`}>
            //             <LaunchIcon fontSize="inherit" /> 
            //         </IconButton>,
            //     sortable: true,
            //     maxWidth: '120px',
            //     grow: 2,
            // },
            // {
            //     name: 'Atualizado',
            //     selector: row => row.updated_at,
            //     cell: row => DateTime.fromISO(row.updated_at).setLocale('pt-br').toFormat('dd/MM/yyyy'),
            //     sortable: true,
            //     maxWidth: '120px',
            //     grow: 2,
            // },
            {
                name: 'Ações',
                maxWidth: '80px',
                cell: row => <ActionMenu section={'contentvideos'} row={row}/>,
                right: true,
                grow: 1,
            },
        ]
    ); 


    // COLUMNS TO VIDEO LIST
    const contentArticlesColumns = (
        [
            {
                name: 'ID',
                selector: row => row.fileId ,
                sortable: true,
                omit: true,
                width: '70px',
            },
            {
                name: 'Título | Ano | Autor',
                selector: row => row.title + row.technique + row.date,
                //cell: row => <span style={{ wordBreak: "break-word" }}> {truncate(row.title, 15)} </span>,
                cell: (row) => (
                    <div style={{ paddingTop: "5px", paddingBottom: "5px", }}>
                        <div style={{ wordBreak: "break-word" }}>{`${truncate(row.title, 15)} [${row.date}]`}</div>
                        <div style={{ paddingTop: "3px", fontWeight: "bold" }}>{row.technique}</div>
                    </div>
                ),
                sortable: true,
                //maxWidth: '250px',
                grow: 3,
            },
            {
                name: 'Descrição',
                selector: row => row.description,
                cell: row => <span style={{ wordBreak: "break-word" }}> {truncate(row.description, 25)} </span>,
                sortable: true,
                //maxWidth: '200px',
                grow: 3,
            },  
            // {
            //     name: 'Ano',
            //     selector: row => row.date,
            //     cell: row => row.date,
            //     sortable: true,
            //     maxWidth: '100px',
            //     grow: 1,
            // },
            {
                name: 'Publicação Relacionada',
                selector: row => getRelatedContentName(content, contentArticles, row.fileId),
                cell: row => getRelatedContentName(content, contentArticles, row.fileId),
                sortable: true,
                //maxWidth: '140px',
                grow: 2,
            },
     
            // {
            //     name: 'Autor',
            //     selector: row => row.technique,
            //     //cell: row => <span style={{ wordBreak: "break-word" }}> {truncate(row.description, 25)} </span>,
            //     sortable: true,
            //     //maxWidth: '200px',
            //     grow: 2,
            // }, 
            {
                name: 'Categoria',
                selector: row => row.dimensions,
                sortable: true,
                //maxWidth: '200px',
                grow: 1,
            }, 
            // {
            //     name: 'YouTube',
            //     selector: row => row.video_id,
            //     cell: row => <IconButton color="info" aria-label="link para youtube" size="small" component={Link} target="_blank" rel="noopener" href={`https://www.youtube.com/watch?v=${row.video_id}`}>
            //             <LaunchIcon fontSize="inherit" /> 
            //         </IconButton>,
            //     sortable: true,
            //     maxWidth: '120px',
            //     grow: 2,
            // },
            // {
            //     name: 'Atualizado',
            //     selector: row => row.updated_at,
            //     cell: row => DateTime.fromISO(row.updated_at).setLocale('pt-br').toFormat('dd/MM/yyyy'),
            //     sortable: true,
            //     maxWidth: '120px',
            //     grow: 2,
            // },
            {
                name: 'Ações',
                maxWidth: '80px',
                cell: row => <ActionMenuRelatedContent section={'contentarticles'} row={row}/>,
                right: true,
                grow: 1,
            },
        ]
    );     

    const contentImagesColumns = (
        [
            {
                name: 'ID',
                selector: row => row.fileId ,
                sortable: true,
                omit: true,
                width: '70px',
            },
            {
                name: "Imagem", 
                selector: row => row.url,
                sortable: true,
                reorder: true,
                cell: (row) => (
                    <div style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                        <img 
                            style={{ borderStyle: "outset"}}
                            src={`${urlEndpoint}/tr:h-${imgSize},w-${imgSize}${row.filePath}?w=${imgSize}&h=${imgSize}&fit=crop&auto=format`}           
                            srcSet={`${urlEndpoint}/tr:h-${imgSize},w-${imgSize}${row.filePath}?w=${imgSize}&h=${imgSize}&fit=crop&auto=format&dpr=2 2x`}
                            alt={row.description}
                            loading="lazy"
                        />
                    </div>
                ),
                grow: 1,
            },
            {
                name: 'Ordem',
                selector: row => row.serial,
                cell: row => row.serial,
                sortable: true,
                maxWidth: '50px',
                grow: 0.5,
            },
            {
                name: 'Título',
                selector: row => row.title,
                cell: row => <span style={{ wordBreak: "break-word" }}> {truncate(row.title, 25)} </span>,
                sortable: true,
                //maxWidth: '200px',
                grow: 2,
            },              
            {
                name: 'Descrição',
                selector: row => row.description,
                cell: row => <span style={{ wordBreak: "break-word" }}> {truncate(row.description, 25)} </span>,
                sortable: true,
                //maxWidth: '200px',
                grow: 3,
            },  
            {
                name: 'Ano',
                selector: row => row.date,
                cell: row => row.date,
                sortable: true,
                maxWidth: '100px',
                grow: 1,
            },
            {
                name: 'Técnica',
                selector: row => row.technique,
                sortable: true,
                //maxWidth: '200px',
                grow: 2,
            },
            {
                name: 'Dimensões',
                selector: row => row.dimensions,
                sortable: true,
                //maxWidth: '200px',
                grow: 2,
            }, 
            {
                name: "Disponível", 
                selector: row => row.available,
                sortable: true,
                reorder: true,
                center: true,
                right: true,
                cell: (row) => (
                    <IconButton 
                        color="success" 
                        disabled={!row.available}
                        disableFocusRipple
                        disableRipple
                        aria-label="obra disponível para aquisição" 
                        size="small" 
                        sx={{ fontSize: '10px' }} 
                    >
                        <CircleIcon fontSize="inherit" /> 
                    </IconButton>
                ),
                // cell: row => <Switch 
                //                 checked={row.available} 
                //                 inputProps={{ 'aria-label': 'Obra disponível para compra' }} 
                //                 size="small" 
                //                 color="success"
                //             />,
                grow: 1.5,
            },
            // {
            //     name: 'Ações',
            //     maxWidth: '80px',
            //     cell: row => <ActionMenu section={'contentarticles'} row={row}/>,
            //     right: true,
            //     grow: 1,
            // },
        ]
    );         

    // COLUMNS TO SECTIONS LIST
    const sectionsColumns = (
        [
            {
                name: 'ID',
                selector: row => row.id ,
                sortable: true,
                omit: true,
                width: '70px',
            },
            {
                name: 'Nome',
                selector: row => row.name ,
                sortable: true,
                grow: 1,
            },
            {
                name: 'Categorias',
                selector: row => row.id,
                cell: row => <Typography variant="caption" sx={{ fontStyle: 'italic', }} ></Typography>,
                sortable: true,
                grow: 3,
            },                
            {
                name: 'Publicações',
                selector: row => row.id,
                cell: row => <Typography variant="caption" sx={{ fontStyle: 'italic', }} >{contentCounter(content, row.id)}</Typography>,
                sortable: true,
                grow: 1,
            },                
            {
                name: 'Criado',
                selector: row => DateTime.fromISO(row.created_at).setLocale('pt-br').toFormat('dd/MM/yyyy'),
                sortable: true,
                maxWidth: '140px',
                grow: 1,
            },
            {
                name: 'Atualizado',
                selector: row => DateTime.fromISO(row.updated_at).setLocale('pt-br').toFormat('dd/MM/yyyy'),
                sortable: true,
                maxWidth: '140px',
                grow: 1,
            },
            {
                name: 'Ações',
                maxWidth: '100px',
                cell: row => <ActionMenu section={'sections'} row={row} />,
                right: true,
                grow: 1,
            },
        ]
    );
    
    // COLUMNS TO PAGES LIST
    const pagesColumns = (
        [
            {
                name: 'ID',
                selector: row => row.id ,
                sortable: true,
                omit: true,
                width: '70px',
            },
            {
                name: 'Nome de Menu',
                selector: row => row.slug ,
                sortable: true,
                grow: 1,
            },
            {
                name: 'Título',
                selector: row => row.title ,
                sortable: true,
                grow: 3,
            },
            {
                name: 'Atualização',
                selector: row => row.updated_at,
                cell: row => DateTime.fromISO(row.updated_at).setLocale('pt-br').toFormat('dd/MM/yyyy'),
                sortable: true,
                grow: 2,
            },
            {
                name: 'Status',
                selector: row => row.status,
                cell: row => <Box sx={{ color: statusColorName(row.status) }} >
                                    {statuses.find(s => s.id === row.status).status} 
                                </Box>,
                sortable: true,
                maxWidth: '140px',
                grow: 2,
            },
            // {
            //     name: 'Status',
            //     selector: row => row.status,
            //     cell: row => <Chip 
            //                         label={statuses.find(s => s.id === row.status).status} 
            //                         size="small" 
            //                         variant="outlined" 
            //                         color={statusColor(row.status)}
            //                     />,
            //     sortable: true,
            //     maxWidth: '140px',
            //     grow: 1,
            // },
            {
                name: 'Ações',
                maxWidth: '100px',
                cell: row => <ActionMenu section={'pages'} row={row} />,
                right: true,
                grow: 1,
            },
        ]
    );      

    return {
        // fullProfilesColumns: fullProfilesColumns,
        contentColumns: contentColumns,
        contentVideosColumns: contentVideosColumns, 
        contentArticlesColumns: contentArticlesColumns, 
        contentImagesColumns: contentImagesColumns,       
        sectionsColumns: sectionsColumns,
        pagesColumns: pagesColumns,
    };
};