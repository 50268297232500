import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { sortImages, imageGridLayout } from '../utils';
 
// MY HISTORY HOOK
import { useHistory } from './history';

const PublicImageGrid = () => {

    // IMAGEKIT
    const urlEndpoint = process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT;

    // MY HISTORY HOOK
    const history = useHistory();      

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();

    // REDUX SELECTORS
    const images = useSelector(state => state.images.images); 
    const mainDivSize = useSelector(state => state.content.mainDivSize); 
    const imageContainerSize = useSelector(state => state.content.publicImageGalleryContainerSize); 
    const contentImages = images?.filter(i => parseInt(i.folder, 10) === parseInt(params.contentId, 10) && i.fileType === 'image').sort(sortImages);

    // IMAGEGRID LAYOUT ELEMENTS
    const imgSize = 70;
    const gap = 4;
    const imgCount = contentImages?.length;
    const grid = imageGridLayout(mainDivSize, imageContainerSize, imgCount, imgSize, gap); 
    const thumbSlice = grid.rows > 7 ? 7*grid.cols : imgCount; 

    const imageGrid = () => {
        if(imgCount > 1) 
            return (
                <ImageList 
                    sx={{ 
                          width: grid.width,  
                          overflow: 'hidden', 
                          mt: 0,
                          mb: 3,
                          mr: '24px',
                        }} 
                    rowHeight={grid.imgSize}
                    cols={grid.cols} 
                    gap={grid.imgGap}
                    >
                    {thumbSlice && contentImages.slice(0,thumbSlice).map((ci, index) => (
                        <ImageListItem key={ci.fileId} component={Link} to={`/content/${params.contentId}/${ci.fileId}`}>
                            <img 
                                src={`${urlEndpoint}/tr:h-${grid.imgSize},w-${grid.imgSize}${ci.filePath}?w=${grid.imgSize}&h=${grid.imgSize}&fit=crop&auto=format`}           
                                srcSet={`${urlEndpoint}/tr:h-${grid.imgSize},w-${grid.imgSize}${ci.filePath}?w=${grid.imgSize}&h=${grid.imgSize}&fit=crop&auto=format&dpr=2 2x`}
                                alt={ci.description}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            );            
        else 
            return null;
    };

    // CONTENT GALLERY
    if( history.pathArray[1] === 'content' ) 
        return imageGrid();
    else {
        return null;
    };

};

export default PublicImageGrid;
