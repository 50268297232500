import { publicTheme } from './publicStyles';

export const sxProps = {
  width: '100%',
  zIndex: 1000,
  flexGrow: 1,
  textDecoration: 'none',
  px: 1,
  py: 0.5,
  // borderWidth: '1px', 
  // borderStyle: 'solid', 
  borderColor: publicTheme.palette.lead.light,
  //color: publicTheme.palette.lead.light,
  color: publicTheme.palette.text.primary,
  '&:hover, &:active': {
      background: publicTheme.palette.lead.main,
      color: publicTheme.palette.common.white,
      '& .MuiSvgIcon-root': {
          color: publicTheme.palette.common.white,
      },
  },
  transition: publicTheme.transitions.create(['color', 'background'], {
      easing: publicTheme.transitions.easing.sharp,
      duration: publicTheme.transitions.duration.leavingScreen,
  }),   
};

export const headerProps = {
    width: '100%',
    pt: 2,
    pb: 1  
};