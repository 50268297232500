import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTableTemplates } from './tableTemplates';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Title from './Title'; 

// REACT DATA TABLE COMPONENT
import VideoEditableTable from './VideoEditableTable';
import FilteredDataTable from './FilteredDataTable';

// MY HISTORY HOOK
import { useHistory } from './history';

const ContentVideosIndex = () => {

    // TABLE TEMPLATES HOOK
    const tableTemplates = useTableTemplates(); 

    // MY HISTORY HOOK
    const history = useHistory();
    const context = history?.pathArray[3] || ''

    // REDUX SELECTORS
    const contentVideos = useSelector(state => state.content.content_videos); 
    const getContentVideosStatus = useSelector(state => state.content.getContentVideosStatus);
    const createContentTable = Boolean( getContentVideosStatus === "succeeded" );  

    const [editing, setEditing] = useState(false);

    const index = () => {
        if(editing) {
            return (
                <Grid item xs={12} >
                    <Paper square elevation={1}>
                        <Grid item xs={12} sx={{ px: 2, py: 2, display: 'flex', flexDirection: 'column', }}>
                            <Stack 
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={1}
                            >
                                <Box sx={{ flexgrow: 1, }}/> 
                                <Button 
                                    variant="contained" 
                                    size="small"
                                    margin="dense"
                                    color="success"
                                    onClick={() => setEditing(!editing)}  
                                >
                                    Salvar
                                </Button>
                            </Stack> 
                        </Grid>    
                        <Divider />

                        {createContentTable && contentVideos?.length ? (
                            <VideoEditableTable 
                                stateData={contentVideos} 
                                title={<Title position={'editVideosIndex'}/>}
                            />
                        ) : (
                            <Typography component="div" variant="body1" color="inherit" sx={{ fontStyle: 'italic', textAlign: 'center', pt: 4, }}>
                                Sem dados para exibir
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            );
        } else {
            return (
                <Grid item xs={12} >
                    <Paper square elevation={1}>
                        <Grid item xs={12} sx={{ px: 2, py: 2, display: 'flex', flexDirection: 'column', }}>
                            <Stack 
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={1}
                            >
                                <Box sx={{ flexgrow: 1, }}/> 
                                <Button 
                                    variant="contained" 
                                    size="small"
                                    margin="dense"
                                    onClick={() => setEditing(!editing)}  
                                >
                                    Editar Descrição dos Vídeos
                                </Button>
                            </Stack> 
                        </Grid>    
                        <Divider />

                        {createContentTable && contentVideos.length > 0 ? (
                            <FilteredDataTable 
                                data={contentVideos} 
                                columns={tableTemplates.contentVideosColumns} 
                                title={<Title position={'index'}/> }
                            />
                        ) : (
                            <Typography component="div" variant="body1" color="inherit" sx={{ fontStyle: 'italic', textAlign: 'center', pt: 4, }}>
                                Sem dados para exibir
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            );




            // return (
            //     <Grid item xs={12}>
            //         <Paper square elevation={1}>
            //             {createContentTable && contentVideos.length > 0 ? (
            //                 <FilteredDataTable 
            //                     data={contentVideos} 
            //                     columns={tableTemplates.contentVideosColumns} 
            //                     title={<Title position={'index'}/> }
            //                 />
            //             ) : (
            //                 <Typography component="div" variant="body1" color="inherit" sx={{ fontStyle: 'italic', textAlign: 'center', pt: 4, }}>
            //                     Sem dados para exibir
            //                 </Typography>
            //             )}
            //         </Paper>
            //     </Grid>
            // );
        };
    }; 
    
    if(context === 'edit') {
        return index();
    } else {
        return (
            <Container maxWidth="xl" sx={{ my: 4, }}>
                <Grid container spacing={2}>
                    {index()}  
                </Grid>
            </Container>
        );
    }; 

};

export default ContentVideosIndex;