import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getImages } from '../features/imagesSlice';
import Uppy from '@uppy/core/lib/Uppy';
import Dashboard from '@uppy/react/lib/Dashboard'
import Url from '@uppy/url';
import ImageKitUppyPlugin from "imagekit-uppy-plugin"
import Portuguese from '@uppy/locales/lib/pt_BR'; 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { useHistory } from './history';
import { uppySetter } from '../utils';

const UppyArticlesDashboard = () => {

    // REDUX SELECTORS
    const dispatch = useDispatch();

    const history = useHistory(); //console.log('history',history)
    
    const uppySettings = uppySetter(history.pathArray[2])
  
    const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
    const IMAGEKIT_PUBLIC_KEY = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
    const IMAGEKIT_AUTH_ENDPOINT = process.env.REACT_APP_IMAGEKIT_AUTH_ENDPOINT

    const [uppyOpen, setUppyOpen] = useState(false);
    const isContentArticles = Boolean(history.pathArray[2] === 'contentarticles');

    // HANDLE UPPY DIALOG
    const handleUppyOpen = () => {
        setUppyOpen(true);
    };   
    
    const handleUppyClose = () => {
        setUppyOpen(false);
    };   

    // METAFIELDS ALLOWED IN ARTICLE EDIT BEFORE UPLOAD
    const metaFields = [
        {id: 'title', name: 'Título', placeholder: 'ex: Imagens de Mulher: a fotografia na arte contemporânea brasileira'},
        {id: 'description', name: 'Descrição Complementar', placeholder: 'Faculdade de Artes Visuais da Universidade Federal de Goiás. (FAV/UFG). Goiânia - GO'},
        {id: 'date', name: 'Ano', placeholder: 'ex: 2007'},
        {id: 'technique', name: 'Autores', placeholder: 'ex: Letícia Segurado Côrtes'},
        {id: 'dimensions', name: 'Categoria', placeholder: 'ex: Dissertação'},
        {id: 'serial', name: 'Link Externo', placeholder: 'ex: http://www.dominiopublico.gov.br/pesquisa/DetalheObraForm.do?select_action=&co_obra=120102'}
    ];

    const uppy = new Uppy({
        // id: isContentArticles ? 'uppyArticles' : 'uppyClipping',
        id: uppySettings.uppyId,
        allowMultipleUploadBatches: false,
        restrictions: {
            maxFileSize: 20000000,
            minFileSize: 1,
            maxTotalFileSize: 600000000,
            maxNumberOfFiles: 30,
            minNumberOfFiles: 1,
            allowedFileTypes: ['image/*', '.pdf'],
            requiredMetaFields: [],
        },
        meta: {
            //folder: isContentArticles ? `/articles` : `/clipping`,
            folder: uppySettings.folder,
        },
        onBeforeFileAdded: (currentFile, files) => {

        },
        onBeforeUpload: (files) => {
            for (const [key, file] of Object.entries(files)) {
                let updatedFile = {
                    ...file,
                    meta: {
                        ...file.meta,
                        customMetadata: JSON.stringify({ 
                            title: file.meta.title || null,
                            description: file.meta.description || null,
                            date: file.meta.date || null,
                            technique: file.meta.technique || null,
                            dimensions: file.meta.dimensions || null,
                            serial: file.meta.serial || null
                        })
                    },
                }
                const obj = Object.assign(files[key], updatedFile);
            }

        },
        
    })
    .use(Url, { 
            companionUrl: SERVER_BASE_URL,
            locale: Portuguese,
    })
    .use(ImageKitUppyPlugin, {
            id: 'ImageKit',
            authenticationEndpoint: IMAGEKIT_AUTH_ENDPOINT,
            publicKey: IMAGEKIT_PUBLIC_KEY,
            metafields: [
                "customMetadata"
            ]
    })
    .on('complete', () => {
            dispatch(getImages())
    })
    
    // DONE UPLOAD HANDLER
    const onDoneClick = () => {
            uppy.cancelAll()
            uppy.getPlugin('uppyDialog').closeModal()
    };

    // CLOSE MODAL HANDLER
    const onRequestClose = () => {
        uppy.getPlugin('uppyDialog').closeModal()
    };

    const uppyProps = {
        target: 'uppyDialog',
        metaFields: metaFields,
        //trigger: isContentArticles ? 'uppyArticlesDashboard' : 'uppyClippingDashboard',
        trigger: uppySettings.uppyTrigger,
        inline: true,
        width: '100%',
        height: '90vh',
        thumbnailWidth: 280,
        showLinkToFileUploadResult: false,
        showProgressDetails: true,
        hideUploadButton: false,
        hideRetryButton: false,
        hidePauseResumeButton: false,
        hideCancelButton: false,
        hideProgressAfterFinish: false,
        doneButtonHandler: () => { onDoneClick() },
        note: null,
        closeModalOnClickOutside: false,
        closeAfterFinish: false,
        disableStatusBar: false,
        disableInformer: false,
        disableThumbnailGenerator: false,
        disablePageScrollWhenModalOpen: true,
        animateOpenClose: true,
        fileManagerSelectionType: 'files',
        proudlyDisplayPoweredByUppy : false,
        onRequestCloseModal: () => { onRequestClose() },
        showSelectedFiles: true,
        showRemoveButtonAfterComplete: false,
        showNativePhotoCameraButton: false,
        showNativeVideoCameraButton: false,
        locale: Portuguese,
        browserBackButtonClose: false,
        theme: 'light',
        autoOpenFileEditor: false,
        disableLocalFiles: false,
    };

    useEffect(() => {
        return () => uppy.close({ reason: 'unmount' })
    }, [uppy]);

    return (
        <React.Fragment>
            <Dialog id="uppyDialog" onClose={handleUppyClose} open={uppyOpen}  maxWidth="xl" fullWidth >
                <Dashboard 
                    uppy={uppy} 
                    {...uppyProps} 
                    plugins={['Url','ImageKitUppyPlugin']}
                    folder={isContentArticles ? '/articles' : '/clipping'}
                />
            </Dialog>
            
            <Button 
                variant="contained" 
                //id={isContentArticles ? 'uppyArticlesDashboard' : 'uppyClippingDashboard'}
                id={uppySettings.uppyTrigger}
                size="small"
                margin="dense"
                onClick={handleUppyOpen}  
            >
                {/* {isContentArticles ? 'Adicionar Bibliografia' : 'Adicionar Clipping'} */}
                {uppySettings.buttonTitle}
            </Button>
        </React.Fragment>
    );
};

export default UppyArticlesDashboard;