import * as React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createContentVideo } from '../features/contentSlice';
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Title from './Title'; 
import DateSetterSimple from './DateSetterSimple'; 
import ContentVideosIndex from './ContentVideosIndex';  
import { validateNames, validateYouTubeVideoId } from '../utils';

const ContentVideoEdit = () => {

    // // REDUX SELECTORS
    const dispatch = useDispatch();

    const content = useSelector(state => state.content.content);
    const contentVideos = useSelector(state => state.content.content_videos);

    // START NEW CONTENT VIDEO OBJECT
    const startDate = DateTime.now();
    const contentVideo = {
        content_id: null,
        title: '',
        description: '',
        video_id: '',
        category: '',
        date: startDate
    };

    // NEW VIDEO STATES
    const [contentVideoData, setContentVideoData] = useState({ ...contentVideo }); 
    const canInclude = Boolean(!validateNames(contentVideoData.title) && validateYouTubeVideoId(contentVideoData.video_id));
    // CHANGE VIDEO STATES
    const handleChangeContentVideoData = (event) => {
        setContentVideoData({...contentVideoData, [event.target.name]: event.target.value});
    };

    // CLEAR RELATED CONTENT
    const handleClearContentVideoData = () => {
        setContentVideoData({...contentVideo});
    };

    // CLEAR RELATED CONTENT
    const handleClear = () => {
        setContentVideoData({...contentVideoData, content_id: null});
    };

    // UPDATE CONTENT
    const handleCreateContentVideo = () => {
        const newDate = { ...contentVideoData.date.start.c };
        const newContentId = contentVideoData.content_id === '' ? null : contentVideoData.content_id;
        const updatedContentVideo = { ...contentVideoData, content_id: newContentId, date: newDate }; 
        dispatch(createContentVideo(updatedContentVideo));
    };

    return (
        <Container maxWidth="xl" sx={{ my: 4 }}>
            <Grid container spacing={2}>
                {/* LEFT PANEL */}
                <Grid item xs={12} md={8}>
                    <Paper sx={{ minHeight: 330, mb: 2, }}>
                        <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', }}>
                            <Title position={'left'}/> 
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <TextField
                                value={contentVideoData.title}
                                onChange={(event) => handleChangeContentVideoData(event)}
                                fullWidth
                                label="Título"
                                name="title"
                                size="small"
                                multiline={true}
                                minRows={1}
                                maxRows={2}
                                type="text"
                                //error={true}
                                //helperText="Título principal do vídeo"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                select
                                name="content_id"
                                label="Publicação Relacionada (Opcional)"
                                size="small"
                                value={contentVideoData.content_id || ''}
                                onChange={(event) => handleChangeContentVideoData(event)}
                                sx={{my: 1, "& .MuiSelect-iconOutlined": {display: contentVideoData.content_id ? 'none': ''}, }}
                                InputProps={{
                                    endAdornment: (
                                      <IconButton edge="end"  sx={{visibility: contentVideoData.content_id ? "visible": "hidden"}} onClick={handleClear}>
                                        <ClearIcon />
                                      </IconButton>
                                    ),
                                  }}
                                InputLabelProps={{ shrink: true }}
                            >
                                {content.map((c) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                value={contentVideoData.description}
                                onChange={(event) => handleChangeContentVideoData(event)}
                                fullWidth
                                label="Descrição Adicional (Opcional)"
                                name="description"
                                size="small"
                                type="text"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                value={contentVideoData.video_id}
                                onChange={(event) => handleChangeContentVideoData(event)}
                                fullWidth
                                label="Identificador Único do Vídeo no YouTube"
                                name="video_id"
                                size="small"
                                type="text"
                                placeholder="xQxVr_dezXI"
                                helperText={
                                    <span>
                                        Baseado na URL, ex: <b>https://www.youtube.com/watch?v=<span style={{ color: 'red'}}>xQxVr_dezXI</span></b>  ou <b>https://youtu.be/<span style={{ color: 'red'}}>xQxVr_dezXI</span></b>
                                    </span>
                                  }
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }} 
                            />
                        </Grid>
                    </Paper>

                </Grid>

                {/* RIGHT PANEL */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ minHeight: 330,  mb: 2, }} >
                        <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', }}>
                            <Title position={'right'}/>
                        </Grid>

                        <Divider />

                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <DateSetterSimple
                                setDate={(value) => setContentVideoData({ ...contentVideoData, date:value })}
                                date={{ ...contentVideoData.date }}
                            />
                        </Grid>

                        <Divider />

                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>

                            <TextField
                                value={contentVideoData.category}
                                onChange={(event) => handleChangeContentVideoData(event)}
                                fullWidth
                                label="Categoria"
                                name="category"
                                size="small"
                                type="text"
                                //error={true}
                                helperText="ex: Exposições, Entrevistas etc"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            />

                        </Grid>

                        <Divider />

                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <Button 
                                variant="contained" 
                                color="success"
                                disabled={!canInclude}
                                fullWidth 
                                sx={{ my: 2,}} 
                                onClick={() => {handleCreateContentVideo(); handleClearContentVideoData();}}  
                            >
                                Incluir Vídeo
                            </Button>
                        </Grid>





                    </Paper>

                </Grid> 


            </Grid>

            {/* INDEX */}
            <ContentVideosIndex />

        </Container>
    )
};

export default ContentVideoEdit;