import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import YoutubeEmbed from './YoutubeEmbed';
import { sortImages, mainImageLayout, mainVideoLayout } from '../utils'; 
 
// MY HISTORY HOOK
import { useHistory } from './history';

const PublicMainImage = () => {

    // IMAGEKIT
    const urlEndpoint = process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT;

    // MY HISTORY HOOK
    const history = useHistory();      

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();

    // REDUX SELECTORS
    const images = useSelector(state => state.images.images); 
    const mainDivSize = useSelector(state => state.content.mainDivSize); 
    const imageContainerSize = useSelector(state => state.content.publicImageGalleryContainerSize); console.log('imageContainerSize',imageContainerSize);
    const imageIndex = 0; 
    const contentImages = images?.filter(i => parseInt(i.folder, 10) === parseInt(params.contentId, 10) && i.fileType === 'image').sort(sortImages);
   
    // BEHAVIOR IF ON VIDEO CATEGORY
    const contentCategoriesIds = useSelector(state => state.content.content_categories)
        .filter(cc => cc.content_id === parseInt(params.contentId, 10) )
        .map(c => {return c.category_id} ); 
    const categories = useSelector(state => state.sections.categories);
    const contentCategories = categories.filter(cc => contentCategoriesIds.includes(cc.id)); //console.log('contentCategories',contentCategories);
    const isVideo = Boolean( contentCategories.map(cc => {return cc.id}).includes(16)); //console.log('isVideo',isVideo);
    // CONTENT VIDEOS
    const contentVideos = useSelector(state => state.content.content_videos).filter(cc => cc.content_id === parseInt(params.contentId, 10) );
   
    // CHANGE MAIN IMAGE ON HOVER THUMBS
    const [mainImage, setMainImage] = useState(null);

    const mainImageSize = mainImageLayout(mainDivSize, imageContainerSize, mainImage); console.log('mainImageSize',mainImageSize);
    const mainVideoSize = mainVideoLayout(mainDivSize, imageContainerSize); console.log('mainVideoSize',mainVideoSize);

    // IMAGEGRID LAYOUT ELEMENTS
    const imgCount = contentImages?.length;
    
    useEffect(() => {
        if(imgCount) {
            setMainImage(contentImages[imageIndex]);
        }
        
        return () => {
        }
    }, [history.pathArray, contentImages, imgCount]);

    const imageGrid = () => {
        if(imgCount > 0 && mainImage && mainImageSize) 
            return (
                <ImageList 
                    sx={{ 
                        width: mainImageSize ? mainImageSize.w : 0,  
                        overflow: 'hidden', 
                        my: 0,
                        mr: mainDivSize.width > 830 ? 0 : '24px',
                        }} 
                    rowHeight={ mainImageSize ? mainImageSize.h : 0}
                    cols={1} 
                    gap={0}
                    >
                        <ImageListItem key={mainImage.fileId} component={Link} to={`/content/${params.contentId}/${mainImage.fileId}`}>
                            <img 
                                src={`${urlEndpoint}/tr:h-${mainImageSize.h},w-${mainImageSize.w}${mainImage.filePath}?w=${mainImageSize.w}&h=${mainImageSize.h}&fit=crop&auto=format`}           
                                srcSet={`${urlEndpoint}/tr:h-${mainImageSize.h},w-${mainImageSize.w}${mainImage.filePath}?w=${mainImageSize.w}&h=${mainImageSize.h}&fit=crop&auto=format&dpr=2 2x`}
                                alt={mainImage.description}
                            />
                        </ImageListItem>
                </ImageList>
            );        
        else 
            return (
                <Box 
                    sx={{ 
                        display: 'flex',                                 
                        direction: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%', 
                        overflow: 'hidden', 
                        minWidth: '316px',
                        minHeight: '316px', 
                    }} 
                >
                    <Typography 
                      component="div" 
                      variant="h6" 
                      color="inherit" 
                      sx={{ color: 'text.disabled', fontStyle: 'italic', textAlign: 'center', pt: 2, }}
                    >
                        Sem imagens para exibir
                    </Typography> 
                </Box>
            );
    };

    const videoThumb = () => {
        //to={context === 'videos' ? `/content/${params.contentId}/video/${cv.video_id}` : cv.url}
        return (
            <Box
                sx={{

                    width: mainVideoSize ? mainVideoSize.w : 0, 
                    height:  mainVideoSize ? mainVideoSize.h : 0,
                }} 
            >
                <YoutubeEmbed embedId={contentVideos[0].video_id} />
            </Box>
        );
    };

    // CONTENT GALLERY
    if( history.pathArray[1] === 'content' ) {
        if( isVideo ) 
            return videoThumb();
        else 
            return imageGrid();
    }
        
    else {
        return null;
    };

};

export default PublicMainImage;

  