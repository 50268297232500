import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { updateImageViewSize } from '../features/contentSlice'; 
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import CircleIcon from '@mui/icons-material/Circle';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
// import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
// import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import { publicTheme } from '../styles/publicStyles';
import { sortImages, imageDialogLayout, publicImageDescription } from '../utils';
import { useElementSize } from '../components/useElementSize'

const ImageView = () => {
  
    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();
    let navigate = useNavigate();

    // IMAGEKIT URL
    const urlEndpoint = process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT;

    // REDUX SELECTORS
    const dispatch = useDispatch();
    const images = useSelector(state => state.images.images); 
    const contentImages = images.filter((i, index) => parseInt(i.folder, 10) === parseInt(params.contentId, 10) && i.fileType === 'image').sort(sortImages);
    const contentImageCount = Array.isArray(contentImages) ? contentImages.length : 0; 
    const image = contentImages.find(i => i.fileId === params.imageId);    
    const index = contentImages.indexOf(image);  
    const imageViewSize = useSelector(state => state.content.imageViewSize); 
    const imageDescription = publicImageDescription(image);
    // DEFINE IMAGE SIZE USING IMAGE BOX VALUES
    const imgSize = imageDialogLayout(imageViewSize.width, imageViewSize.height, image); 

    const [loaded, setLoaded] = useState(false); 

    // HANDLE NAVIGATE BETWEEN IMAGES 
    const handleNavImage = (index) => {
        setLoaded(false);

        if((index % contentImages.length) >= 0) {
            const newIndex = index % contentImages.length;
            const targetImageId = contentImages[newIndex].fileId; 
            navigate(`/content/${params.contentId}/${targetImageId}`);
        } else {
            const newIndex = contentImages.length + (index % contentImages.length);
            const targetImageId = contentImages[newIndex].fileId; 
            navigate(`/content/${params.contentId}/${targetImageId}`);
        };
    };

    // IMAGE BOX REF TARGET AND SIZE
    const ref = useRef(null);
    const size = useElementSize(ref); 

    // HANDLE POPOVER IN AVAILABLE ITEMS
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    // TRACK MAIN WIDTH 
    useEffect(() => {
        const getMainSize = (size) => { 
            const { x, y, width, height, top, right, left, bottom, } = size;
            const mainsize = {
                x: x, 
                y: y, 
                width: width, 
                height: height, 
                top: top, 
                right: right, 
                left: left, 
                bottom: bottom
            };
            
            return mainsize;
        };
            
        dispatch(updateImageViewSize(getMainSize(size)));
    }, [size, dispatch]);

    // TRACK KEYBOARD EVENTS 
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'ArrowLeft') 
                handleNavImage(index - 1);
            if (e.key === 'ArrowRight')
                handleNavImage(index + 1);
            if (e.key === 'Escape')
                navigate(`/content/${params.contentId}`);
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [index, handleNavImage, navigate, params]);

    return (
        <ThemeProvider theme={publicTheme} > 
            <Container maxWidth={false} disableGutters sx={{height: '100%' }}>
                <CssBaseline />
                <AppBar  color="inherit" elevation={0} sx={{ position: 'fixed' }}>
                    <Toolbar variant="dense">
                        <Box sx={{ height: '28px', width: '28px' }} />
                        <Box 
                            sx={{ 
                                flexGrow: 1,
                                display: 'flex',
                                direction: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} 
                        >
                            {contentImageCount > 1 && <Stack direction="row" alignItems="center" spacing={1}>
                                {/* <IconButton size="small">
                                    <KeyboardDoubleArrowLeftRoundedIcon fontSize="inherit" />
                                </IconButton> */}
                                <IconButton  size="small"  onClick={() => handleNavImage(index - 1)}>
                                    <NavigateBeforeRoundedIcon fontSize="inherit" />
                                </IconButton> 
                                <Typography variant="caption" component="div" >
                                    {`${index+1}/${contentImages.length}`}
                                </Typography>
                                <IconButton size="small" onClick={() => handleNavImage(index + 1)}> 
                                    <NavigateNextRoundedIcon fontSize="inherit" />
                                </IconButton>
                                {/* <IconButton size="small">
                                    <KeyboardDoubleArrowRightRoundedIcon fontSize="inherit" />
                                </IconButton> */}
                            </Stack>}
                        </Box>
                        <IconButton
                            component={Link}
                            to={`/content/${params.contentId}`}
                            size="small"
                            disableRipple
                            edge="end"
                            color="inherit"
                            aria-label="close"
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box 
                    ref={ref} 
                    id="image-dialog"
                    sx={{ 
                        display: 'flex',                                 
                        direction: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%', 
                        height: '100vh',
                        px: 3,
                        pt: 18,
                        pb: 22,
                    }} 
                >
                    <ImageList 
                        sx={{ width: imgSize.w, overflow: 'hidden', m: 0, }} 
                        rowHeight={imgSize.h}
                        cols={1} 
                        gap={0}
                    >
                        <ImageListItem sx={{ background: publicTheme.palette.wheat.light, }}>
                            <img 
                                onLoad={() => setLoaded(true)}
                                style={{display: loaded ? '' : 'none'}}
                                src={`${urlEndpoint}/tr:h-${imgSize.h},w-${imgSize.w}${image.filePath}?w=${imgSize.w}&h=${imgSize.h}&fit=crop&auto=format`}           
                                srcSet={`${urlEndpoint}/tr:h-${imgSize.h},w-${imgSize.w}${image.filePath}?w=${imgSize.w}&h=${imgSize.h}&fit=crop&auto=format&dpr=2 2x`}
                                alt={image.description}
                            />
                        </ImageListItem>
                    </ImageList>
                </Box>
                <Box 
                    sx={{ 
                        position: 'absolute', 
                        bottom: 0, 
                        display: 'flex',
                        direction: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%', 
                        height: '40px', 
                        mb: 4,
                    }}
                >
                    <Typography 
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        variant="caption" 
                        component="h1" 
                        sx={{ display: 'inline-block', textAlign: 'center' }}
                    > 
                        {imageDescription.title && <span style={{ fontWeight: 'bold', marginRight: '6px' }}>{`${imageDescription.title} `}</span> }
                        {imageDescription.date && <span style={{ marginRight: '6px' }}>{`${imageDescription.date} `}</span>}
                        {imageDescription.complement && <span>{` ${imageDescription.complement}`}</span>}
                        {image.available &&
                            <Tooltip 
                                title="Disponível para aquisição" 
                                placement="top" 
                                arrow
                                TransitionComponent={Zoom}
                            >             
                                <IconButton 
                                    color="success" 
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                    aria-label="obra disponível para aquisição" 
                                    size="small" 
                                    sx={{ fontSize: '10px' }} 
                                >
                                    <CircleIcon fontSize="inherit" /> 
                                </IconButton>
                            </Tooltip>
                        }
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default ImageView;




