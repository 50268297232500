import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import YoutubeEmbed from '../components/YoutubeEmbed';
import { adminVideoDialogLayout, sortByDate } from '../utils';
import { useElementSize } from '../components/useElementSize' 

const AdminVideoView = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();
    let navigate = useNavigate();

    const allContentVideos = useSelector(state => state.content.content_videos);
    const contentVideos = allContentVideos.slice().sort(sortByDate);
    const contentVideosIds = contentVideos.map(cv => cv.video_id);
    const index = contentVideosIds.indexOf(params.videoId); 
    const contentVideo = contentVideos[index];
    const publicContent = useSelector(state => state.content.content);
    const content = contentVideo.content_id ? publicContent.find(c => c.id === contentVideo.content_id) : null;

    const [frameSize, setFrameSize] = useState( { w: 960, h: 540 }); 

    // HANDLE NAVIGATE BETWEEN VIDEOS
    const handleNavVideo = (index) => {

        if((index % contentVideos.length) >= 0) {
            const newIndex = index % contentVideos.length;
            const targetVideoId = contentVideosIds[newIndex]; 
            navigate(`/admin/contentvideos/${targetVideoId}`);
        } else {
            const newIndex = contentVideos.length + (index % contentVideos.length);
            const targetVideoId = contentVideosIds[newIndex]; 
            navigate(`/admin/contentvideos/${targetVideoId}`);
        };
    };

    // IMAGE BOX REF TARGET AND SIZE
    const ref = useRef(null);
    const size = useElementSize(ref); 

    // TRACK MAIN WIDTH 
    useEffect(() => {
        const getMainSize = (size) => { 
            const { width, height } = size;
            const mainsize = { 
                width: width, 
                height: height
            };
            
            return mainsize;
        };
            
        setFrameSize(adminVideoDialogLayout(getMainSize(size).width, getMainSize(size).height));
    }, [size]);

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                {/* LEFT PANEL */}
                <Grid item xs={12}>
                    <Paper >
                        <Box >
                            <Toolbar variant="dense">
                                <Box sx={{ height: '28px', width: '28px' }} />
                                <Box 
                                    sx={{ 
                                        flexGrow: 1,
                                        display: 'flex',
                                        direction: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }} 
                                >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <IconButton size="small" onClick={() => handleNavVideo(index - 1)}>
                                            <NavigateBeforeRoundedIcon fontSize="inherit" />
                                        </IconButton> 
                                        <Typography variant="caption" component="div" >
                                            {`${index+1}/${contentVideos.length}`}
                                        </Typography>
                                        <IconButton size="small" onClick={() => handleNavVideo(index + 1)}> 
                                            <NavigateNextRoundedIcon fontSize="inherit" />
                                        </IconButton>
                                    </Stack>
                                </Box>
                                <IconButton
                                    component={Link}
                                    to="/admin/contentvideos/edit"
                                    size="small"
                                    disableRipple
                                    edge="end"
                                    color="inherit"
                                    aria-label="close"
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Toolbar>
                        </Box>
                        
                        <Box 
                            ref={ref} 
                            id="video-dialog"
                            sx={{ 
                                display: 'flex',                                 
                                direction: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                //width: '100%', 
                                height: '75vh',
                                px: 3,
                                pt: 18,
                                pb: 22,
                            }} 
                        >
                            <Box
                                sx={{ 
                                    width: frameSize.w, 
                                    height: frameSize.h,
                                }} 
                            >
                                <YoutubeEmbed embedId={params.videoId} />
                            </Box>
                        </Box>
                        <Box 
                            sx={{ 
                                display: 'flex',
                                direction: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%', 
                                height: '40px', 
                            }}
                        >
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {content && <Typography variant="caption" component="h1" sx={{ fontWeight: 600, textTransform: 'none' }}> 
                                    {content.title}
                                </Typography>}
                                {content && <Typography variant="caption" component="h1" sx={{ fontWeight: 600, textTransform: 'none' }}> 
                                    |
                                </Typography>}
                                {contentVideo.title && <Typography variant="caption" component="div" sx={{ fontWeight: 600, textTransform: 'none' }}>
                                    {contentVideo.title}
                                </Typography>}
                                {contentVideo.date && <Typography variant="caption" component="h1" sx={{ fontWeight: 400, textTransform: 'none' }}>
                                    {contentVideo.date.year}
                                </Typography>}
                                {contentVideo.description && <Typography variant="caption" component="h1" sx={{ fontWeight: 400, textTransform: 'none' }}>
                                    {contentVideo.description}
                                </Typography>}
                            </Stack>
                        </Box> 
                    </Paper>
                </Grid>

            </Grid>
        </Container>
    )
};

export default AdminVideoView;

AdminVideoView.defaultProps = {
    section: false,
}

AdminVideoView.propTypes = {
    all: PropTypes.bool,
};
