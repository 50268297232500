import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { updateDrawer } from '../features/sessionSlice'; 
import Box from '@mui/material/Box';
import LogoLinear from '../images/LogoLinear.svg';

const Logo = () => {
    
    // REDUX SELECTORS
    const dispatch = useDispatch();

    return (
        <Box 
            component={Link} 
            onClick={() => dispatch(updateDrawer(false))}
            to="/" 
            sx={{ 
                textDecoration: 'none', 
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',

            }} 
        >
            <img src={LogoLinear} alt="Maristela Ribeiro" />
        </Box>     
    );
};

export default Logo;
