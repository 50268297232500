import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';


import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FeedIcon from '@mui/icons-material/Feed';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MenuBookIcon from '@mui/icons-material/MenuBook';


import { publicTheme } from '../styles/publicStyles';
import { sortByDate } from '../utils';

const PublicContentRelatedList = (props) => {

    const { data, context } = props;

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();  

    const allContentVideos = useSelector(state => state.content.content_videos).filter(avc => true).sort(sortByDate);
    const contentVideos =  allContentVideos.filter(v => parseInt(v.content_id, 10) === parseInt(params.contentId, 10)); 
    const contextData = context === 'videos' ? contentVideos : data;
    
    const contextIcon = () => {
        if(context === 'articles') {
            return <MenuBookIcon color="inherit" fontSize="inherit" />
        } if(context === 'authorArticles') {
            return <LocalLibraryIcon color="inherit" fontSize="inherit" />
        } if(context === 'catalogues') {
            return <FeedIcon color="inherit" fontSize="inherit" />
        } if(context === 'clipping') {
            return <NewspaperIcon color="inherit" fontSize="inherit" />
        } else {
            return <VideoLibraryIcon color="inherit" fontSize="inherit" />
        }

    };

    return (
        <ThemeProvider theme={publicTheme} > 
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                sx={{ pt: 2, width: '100%', }}
            >
                {contextData && contextData.map(cv => (
                    <Box 
                        key={context === 'videos' ? cv.id : cv.fileId}
                        sx={{
                            width: '100%',
                            textDecoration: 'none',
                            p: 0.3, 
                            borderWidth: '1px', 
                            borderStyle: 'solid', 
                            borderColor: publicTheme.palette.lead.main,
                            color: publicTheme.palette.lead.main,
                            '&:hover': {
                                background: publicTheme.palette.lead.main,
                                color: publicTheme.palette.common.white,
                                '& .MuiSvgIcon-root': {
                                    color: publicTheme.palette.common.white,
                                },
                            },
                            transition: publicTheme.transitions.create(['color', 'background'], {
                                easing: publicTheme.transitions.easing.sharp,
                                duration: publicTheme.transitions.duration.leavingScreen,
                            }),   
                        }}
                        component={Link}
                        to={context === 'videos' ? `/content/${params.contentId}/video/${cv.video_id}` : cv.url}
                        target={context === 'videos' ? '_self' : '_blank'}
                    >

                        {/* <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} > */}
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>
                            <IconButton aria-label="abrir conteúdo relacionado" size="small" disableRipple sx={{ color: publicTheme.palette.lead.main, }}>
                                {/* <VideoLibraryIcon color="inherit" fontSize="inherit" />   */}
                                {contextIcon()}
                            </IconButton>
                            {/* <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}> */}
                            <Box style={{ width: '100%', }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0.5} >
                                    {cv.title && <Typography variant="contentRelatedCaption" component="div" >
                                        {context === 'videos' ? `${cv.title}. ${cv.description}.` : `${cv.title}`}
                                    </Typography>}
                                    {cv.date && <Typography variant="contentRelatedCaption" component="div" sx={{ pr: 1, }}>
                                        {context === 'videos' ? `[${cv.date.year}]` : `[${cv.date}]`}
                                    </Typography>}
                                </Stack>
                            </Box>     
                            {/* </Stack> */}
                        </Box>    
                        {/* </Stack> */}
 
                    </Box>
                ))}
            </Stack>
        </ThemeProvider>
    );
};

export default PublicContentRelatedList;

PublicContentRelatedList.defaultProps = {
    context: 'video',
};

PublicContentRelatedList.propTypes = {
    context: PropTypes.string,
};








