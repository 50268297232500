import * as React from 'react';
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateContentEditImageGallerySize } from '../features/contentSlice';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ImageArticlesGallery from './ImageArticlesGallery'; 
import Title from './Title'; 
import UppyArticlesDashboard from './UppyArticlesDashboard'; 
import ImagekitFilesIndex from './ImagekitFilesIndex';
import { useElementSize } from './useElementSize';
import { useHistory } from './history';

const ContentClippingEdit = () => {

     // REDUX SELECTORS
     const dispatch = useDispatch(); 

    // MY HISTORY HOOK
    const history = useHistory(); 

    // REF TO IMAGE GRID
    const ref = useRef(null);
    const size = useElementSize(ref);  

    // TRACK IMAGE GRID WIDTH
    useEffect(() => {
        const getMainSize = (size) => { 
            const { x, y, width, height, top, right, left, bottom, } = size;
            const mainsize = {
                x: x, 
                y: y, 
                width: width, 
                height: height, 
                top: top, 
                right: right, 
                left: left, 
                bottom: bottom
            };
            
            return mainsize;
        };
         
        dispatch(updateContentEditImageGallerySize(getMainSize(size)));
    }, [history.pathArray]);

    return (
        <Container maxWidth="xl" sx={{ my: 4 }}>
            <Grid container spacing={2} sx={{ mb: 2, }}>
                <Grid item xs={12} >
                    {/* IMAGE GALLERY */}
                    <Paper >
                        <Grid item xs={12} sx={{ px: 2, py: 2, display: 'flex', flexDirection: 'column', }}>
                            <Stack 
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={1}
                            >
                                <Title position={'articlesGallery'}/>
                                <Box sx={{ flexgrow: 1, }}/>
                                <UppyArticlesDashboard />
                            </Stack> 
                             
                        </Grid>
                        <Divider />
                        <Grid ref={ref} id="article-gallery" item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>  
                            <ImageArticlesGallery />
                        </Grid >
                    </Paper>
                </Grid>
                
            </Grid>

            <ImagekitFilesIndex />
            
        </Container>
    );
};

export default ContentClippingEdit;