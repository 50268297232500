import * as React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { publicTheme } from '../styles/publicStyles';
import { hasChildren } from '../utils';

const PublicMenu = (props) => {

    const { sectionId, close } = props;

    const groups = useSelector(state => state.sections.groups);   
    const categories = useSelector(state => state.sections.categories);
    const content = useSelector(state => state.content.content).filter(c => c.status === 1);
    const contentCategories = useSelector(state => state.content.content_categories);

    const publicMenu = (sectionid) => {
        const sectionGroups = groups?.filter(g => g.section_id === sectionid) || [];


        if(sectionGroups.length === 1) {
            const sectionMenu = sectionGroups.map(fg => {
                return {
                    title: fg.name,
                    sx: { pl: 0, textTransform: 'uppercase', textDecoration: 'none', color: publicTheme.palette.common.white, },
                    primaryTypographyProps: { fontSize: '0.65rem', fontWeight: 500, },
                    to: `/publicGroups/${fg.id}`,
                    items: categories
                        .filter(c => c.group_id === fg.id)
                        .map(fc => {
                            const contentCategoriesIds = contentCategories
                                .filter(cc => cc.category_id === fc.id )
                                .map(c => {return c.content_id} );
                            const categoriesContent = content
                                .filter(ccont => contentCategoriesIds.includes(ccont.id))
                            return {
                                title: fc.name,
                                sx: { pl: 0, textTransform: 'uppercase', textDecoration: 'none', color: publicTheme.palette.common.white, },
                                primaryTypographyProps: { fontSize: '0.65rem', fontWeight: 500, },
                                to: `/publicCategories/${fc.id}`,
                                items: categoriesContent
                                    .map(cont => {
                                        return {
                                            title: cont.title,
                                            primaryTypographyProps: { fontSize: '0.75rem', fontWeight: 200, textDecoration: 'none', color: publicTheme.palette.common.white, },
                                            sx: { pl: 1.5, },
                                            to: `/content/${cont.id}`,
                                        }
                                    }) 
                            }                      
                        })
                }
            });

            const { items } = sectionMenu[0]; 

            return items.map((item, key) => (
                <MenuItem key={key} item={item} />
            ));

        } if(sectionGroups.length > 1) {
            const sectionMenu = sectionGroups.map(fg => {
                return {
                    title: fg.name,
                    sx: { pl: 0, textTransform: 'uppercase', textDecoration: 'none', color: publicTheme.palette.common.white, },
                    primaryTypographyProps: { fontSize: '0.65rem', fontWeight: 500, },
                    to: `/publicGroups/${fg.id}`,
                    items: categories
                        .filter(c => c.group_id === fg.id)
                        .map(fc => {
                            const contentCategoriesIds = contentCategories
                                .filter(cc => cc.category_id === fc.id )
                                .map(c => {return c.content_id} );
                            const categoriesContent = content
                                .filter(ccont => contentCategoriesIds.includes(ccont.id))
                            return {
                                title: fc.name,
                                sx: { pl: 1.5, textTransform: 'uppercase', textDecoration: 'none', color: publicTheme.palette.common.white, },
                                primaryTypographyProps: { fontSize: '0.65rem', fontWeight: 400, },
                                to: `/publicCategories/${fc.id}`,
                                items: categoriesContent
                                    .map(cont => {
                                        return {
                                            title: cont.title,
                                            primaryTypographyProps: { fontSize: '0.75rem', fontWeight: 200, textDecoration: 'none', color: publicTheme.palette.common.white, },
                                            sx: { pl: 3, },
                                            to: `/content/${cont.id}`,
                                        }
                                    })
                            }                      
                        })
                }
            });

            return sectionMenu.map((item, key) => (
                <MenuItem key={key} item={item} />
            ));
        } else {
            return null;
        };
    };

    const MenuItem = ({ item }) => {
        const Component = hasChildren(item) ? MultiLevel : SingleLevel;
        return <Component item={item} />;
    };
      
    const SingleLevel = ({ item }) => {
        if(!item.items) {
            return (
                <ListItem disablePadding component={Link} to={item.to}>
                    <ListItemButton onClick={close}>
                        <ListItemText 
                            primary={item.title} 
                            sx={{...item.sx}} 
                            primaryTypographyProps={{...item.primaryTypographyProps}}    
                        />
                    </ListItemButton>
                </ListItem>
            );
        }

    };
      
    const MultiLevel = ({ item }) => {
        const { items: children } = item;
        const [open, setOpen] = useState(false);
      
        const handleClick = () => {
            setOpen((prev) => !prev);
        };
      
        return (
            <ThemeProvider theme={publicTheme} >
                <List dense disablePadding >
                    <ListItem 
                        disablePadding
                        onClick={handleClick}
                        secondaryAction={
                            <IconButton 
                                edge="end" 
                                aria-label={open ? "recolher" : "expandir"} 
                                sx={{ color: publicTheme.palette.common.white, }}
                            >
                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        }
                    >
                        <ListItemButton>
                            <ListItemText primary={item.title} sx={{...item.sx}} primaryTypographyProps={{...item.primaryTypographyProps}}/>
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List dense component="div" disablePadding>
                            {children.map((child, key) => (
                                <MenuItem key={key} item={child} />
                            ))}
                        </List>
                    </Collapse>
                </List>
            </ThemeProvider>
        );
    };


    return publicMenu(sectionId, close);
};

export default PublicMenu;


 

