import * as React from 'react';
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePublicFeaturedImageContainerSize } from '../features/contentSlice'; 
import { selectFeaturedContents } from '../features/imagesSlice'; 
import { ThemeProvider } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography'; 
import Logo from '../components/Logo';
import { useElementSize } from './useElementSize'
import { publicTheme } from '../styles/publicStyles';
import { featuredImageLayout } from '../utils'; 

const PublicHomeContent = () => {

    // IMAGEKIT
    const urlEndpoint = process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT;

    // REDUX SELECTORS
    const dispatch = useDispatch(); 

    const featuredContents = useSelector(selectFeaturedContents);
    const imageContainerSize = useSelector(state => state.content.publicFeaturedImageContainerSize);
    const mainDivSize = useSelector(state => state.content.mainDivSize); 

    // REF TO IMAGE GRID
    const ref = useRef(null);
    const size = useElementSize(ref);

    const [activeIndex, setActiveIndex] = useState(0);
    const [mainContent, setMainContent] = useState(null); 
    const [mainImageSize, setMainImageSize] = useState(null);
    const [fade, setFade] = useState(false);

    // TRACK MAIN WIDTH
    useEffect(() => {
        const getMainSize = (size) => { 
            const { x, y, width, height, top, right, left, bottom, } = size;
            const mainsize = {
                x: x, 
                y: y, 
                width: width, 
                height: height, 
                top: top, 
                right: right, 
                left: left, 
                bottom: bottom
            };
            
            return mainsize;
        };
         
        dispatch(updatePublicFeaturedImageContainerSize(getMainSize(size)));
    }, [mainDivSize, size, dispatch]);

    useEffect(() => {
        if(featuredContents) {
            setFade(true);
            const featuredContent = featuredContents[activeIndex]; 
            const mainImageSize = featuredImageLayout(mainDivSize, imageContainerSize, featuredContent.featuredImage); 
            setMainImageSize(mainImageSize);
            setMainContent(featuredContent);
        }
        
        return () => {
        }
    }, [featuredContents, activeIndex, mainDivSize]);

    useEffect(() => {
        const interval = 5000;

        const fadeOut = setInterval(() => {
            setFade(false);
        }, interval - 1000);

        const fadeInterval = setInterval(() => {
            const newIndex = (activeIndex) => {return (activeIndex + 1) % featuredContents.length};
            setActiveIndex(newIndex(activeIndex));
        }, interval);

        return () => {clearInterval(fadeInterval); clearInterval(fadeOut)};

      }, [mainContent]);


    return (
        <ThemeProvider theme={publicTheme} >
            <Container maxWidth="xl"  >
                <Grid container spacing={1} >
                    <Grid item xs={12} sx={{ mx: mainDivSize.width > 830 ? 10 : 2, }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                            sx={{ pt: 4, }}
                        >
                            <Logo />
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                            sx={{ pt: 3, }}
                        >
                            <Box sx={{ flexGrow: 1, }} />
                            <Fade in={fade} timeout={1000} >
                                <Typography component="h1" variant="h6" style={{ textTransform: 'none' }}>
                                    {mainContent ? mainContent.title : ' ' }
                                </Typography>
                            </Fade>
                        </Stack>
                    </Grid>

                    <Grid 
                        item 
                        xs={12} 
                        sx={{ mx: mainDivSize.width > 830 ? 10 : 2, pt: 0, mt: 0, }}
                    >
                        <Box 
                            ref={ref} 
                            id="publicFeaturedImageContainer"
                            sx={{ 
                                display: 'flex',
                                //background: publicTheme.palette.wheat.light,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                minHeight: '65vh',
                            }} 
                        >
                            <Grid container spacing={3} sx={{ mx: 0, my: 0, }}>
                                <Grid item xs={12} sx={{ pr: '24px', }}>
                                    <Box  
                                        sx={{ 
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            
                                        }}
                                    >
                                        {mainImageSize ? ( 
                                            <Fade in={fade} timeout={1000} >
                                                <ImageList 
                                                    sx={{ 
                                                        width: mainImageSize.w,  
                                                        overflow: 'hidden', 
                                                        my: 0,
                                                        
                                                        }} 
                                                    rowHeight={mainImageSize.h}
                                                    cols={1} 
                                                    gap={0}
                                                    >
                                                        <ImageListItem key={mainContent.featuredImage.fileId} component={Link} to={`/content/${mainContent.featuredImage.folder}`} >
                                                            <img 
                                                                src={`${urlEndpoint}/tr:h-${mainImageSize.h},w-${mainImageSize.w}${mainContent.featuredImage.filePath}?w=${mainImageSize.w}&h=${mainImageSize.h}&fit=crop&auto=format`}           
                                                                srcSet={`${urlEndpoint}/tr:h-${mainImageSize.h},w-${mainImageSize.w}${mainContent.featuredImage.filePath}?w=${mainImageSize.w}&h=${mainImageSize.h}&fit=crop&auto=format&dpr=2 2x`}
                                                                alt={mainContent.featuredImage.description}
                                                            />
                                                        </ImageListItem>
                                                </ImageList>
                                            </Fade>
                                            ) : null
                                        }
                                    </Box> 
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default PublicHomeContent;