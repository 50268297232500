import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTableTemplates } from './tableTemplates';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from './Title'; 

// REACT DATA TABLE COMPONENT
import FilteredDataTable from './FilteredDataTable';

// MY HISTORY HOOK
import { useHistory } from './history';

const SectionsIndex = () => {

    // TABLE TEMPLATES HOOK
    const tableTemplates = useTableTemplates(); 

    // MY HISTORY HOOK
    const history = useHistory();
    const context = history?.pathArray[3] || ''

    // REDUX SELECTORS
    const sections = useSelector(state => state.sections.sections);
    const getSectionsStatus = useSelector(state => state.sections.getSectionsStatus);
    const createSectionsTable = Boolean( getSectionsStatus === "succeeded" )  
    
    const index = () => {
        return (
            <Grid item xs={12}>
                <Paper square elevation={1}>
                    {createSectionsTable && sections.length > 0 ? (
                        <FilteredDataTable 
                            data={sections} 
                            columns={tableTemplates.sectionsColumns} 
                            title={<Title position={'index'}/> }
                        />
                    ) : (
                        <Typography component="div" variant="body1" color="inherit" sx={{ fontStyle: 'italic', textAlign: 'center', pt: 4, }}>
                            Sem dados para exibir
                        </Typography>
                    )}
                </Paper>
            </Grid>
        );
    }; 
    
    return (
        <Container maxWidth="xl" sx={{ my: 4, }}>
            <Grid container spacing={2}>
                {index()}  
            </Grid>
        </Container>
    );
};

export default SectionsIndex;