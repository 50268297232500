import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTableTemplates } from './tableTemplates';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from './Title'; 
import FilteredDataTable from './FilteredDataTable';

const PagesIndex = () => {

    // TABLE TEMPLATES HOOK
    const tableTemplates = useTableTemplates(); 

    // REDUX SELECTORS
    // PAGES
    const fullPages = useSelector(state => state.pages.pages);
    const getPagesStatus = useSelector(state => state.pages.getPagesStatus);

    const createPagesTable = Boolean( getPagesStatus === "succeeded" );  

    const index = () => {
        return (
            <Grid item xs={12}>
                <Paper square elevation={1}>
                    {createPagesTable && fullPages.length > 0 ? (
                        <FilteredDataTable 
                            data={fullPages} 
                            columns={tableTemplates.pagesColumns}
                            title={<Title position={'middle'}/>}
                        />
                    ) : (
                        <Typography component="div" variant="body1" color="inherit" sx={{ fontStyle: 'italic', textAlign: 'center', pt: 4, }}>
                            Sem dados para exibir
                        </Typography>
                    )}
                </Paper>
            </Grid>
        );
    };                                    

    return (
        <Container maxWidth="xl" sx={{ my: 4, }}>
            <Grid container spacing={2}>
                {index()}  
            </Grid>
        </Container>
    );

};

export default PagesIndex;