import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addGroup } from '../features/sectionsSlice';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'; 



const GroupsEdit = ( props ) => {

    const { sectionId, cleanTypes } = props;

    // REDUX STATES
    const dispatch = useDispatch();
    //const section = useSelector(state => state.sections.sections).find(s => s.id === sectionId);  
    const groups = useSelector(state => state.sections.groups); 
    const categories = useSelector(state => state.sections.categories);  

    const [newGroupName, setNewGroupName] = useState('');
    const [groupsArrayData, setGroupsArrayData] = useState([...groups]); //console.log('tagsArrayData',tagsArrayData);

        
    const handleAddGroup = () => {
        const newGroup = {
            section_id: sectionId,
            name: newGroupName
        };
        dispatch(addGroup(newGroup));
        setNewGroupName('');
    }

    // VALIDATE FIELDS
    const validateName = (str) => {
        if(str.length > 2)
        return true
        else
        return false
    };

    // RE-RENDER COMPONENT ON CLASSES ARRAY CHANGES
    useEffect(() => {
        setGroupsArrayData([...groups]);
    }, [groups])

    if(sectionId) {
        return (
            <React.Fragment>
                <Box
                    sx={{
                        minWidth: '400px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        p: 3,
                    }}
                >

                    <List sx={{ width: '100%', maxWidth: 360, }}> 
                        {groupsArrayData && groupsArrayData.filter(g => g.section_id === sectionId).map(gc => ( 
                                <ListItem
                                    disablePadding
                                    alignItems="flex-start"
                                    id={gc.id}
                                    // secondaryAction={
                                    //     <IconButton edge="end" aria-label="delete">
                                    //         <EditIcon />
                                    //     </IconButton>
                                    // }
                                >
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'secondary' }} >
                                        <BookmarkIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                    <ListItemText
                                        primary={gc.name}
                                        secondary={`Categorias [${categories.filter(c => c.group_id === gc.id).length}]`}
                                        //secondary={cc.description ? cc.description : null}
                                    />
                                </ListItem>
                            )
                        )}
                    </List>


                    <TextField
                        value={newGroupName}
                        //error={emailError(email)}
                        onChange={e => setNewGroupName(e.target.value)}
                        fullWidth
                        label="Nome do Grupo"
                        id="groupname"
                        size="small"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        //helperText={emailError(email) ? "Digite um endereço de e-mail válido" : null}
                        sx={{ mt: 3, mb: 1,}}
                    />
                    <Button 
                        variant="contained" 
                        disabled={!validateName(newGroupName) || newGroupName.length === 0}
                        fullWidth 
                        endIcon={<BookmarkAddIcon />}
                        onClick={e => {
                            e.preventDefault();
                            handleAddGroup();
                        }}
                    >
                        Incluir nova Classe
                    </Button>
                </Box>
            </React.Fragment>
        );
    } else {
        return null;
    };
}

export default GroupsEdit;

// GroupsEdit.defaultProps = {
//     sectionId: 1,
// };

GroupsEdit.propTypes = {
    sectionId: PropTypes.number.isRequired,
    cleanTypes: PropTypes.func.isRequired,
};