import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'; 
import PublicContentInfoMenu from './PublicContentInfoMenu';
import { publicTheme } from '../styles/publicStyles';

const PublicContentInfo = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();  

    // REDUX SELECTORS;
    const content = useSelector(state => state.content.content).find(c => c.id === parseInt(params.contentId, 10) );

    return (
        <ThemeProvider theme={publicTheme} >
            <Container >
                <Grid container spacing={1} >
                    {content && 
                        <Grid item xs={12}>
                            <Box sx={{ pt: 3, }}> 
                                <Typography component="h1" variant="h6"  >
                                    {content.title}
                                </Typography>
                            </Box>
                            <Box > 
                                <Typography component="h2" variant="h6" >
                                    {content.date.interval ? `${content.date.start.year}-${content.date.end.year}` : content.date.start.year}
                                </Typography>
                            </Box>
                            <Box sx={{ pt: 2, }}> 
                                {/* <Typography component="div" variant="pageBody" align="justify">
                                    {content.body}
                                </Typography> */}
                                <Typography component="div" variant="pageBody" align="justify">
                                    {content.body.split('\n').map((text, index) => (
                                        <div key={index}>
                                            {text}
                                            <br />
                                        </div>
                                    ))}
                                </Typography>
                            </Box>
                        </Grid>
                    }    
                    <Grid item xs={12}>
                           <PublicContentInfoMenu /> 
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default PublicContentInfo; 