import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useHistory } from './history';

const Title = (props) => {

  // MY HISTORY HOOK
  const history = useHistory();
  const section = history?.pathArray[2] || ''
  const context = history?.pathArray[3] || ''
  
  // TRACK ROUTES 
  useEffect(() => {
  }, [history.location]);

  function titleGenerator(section, context) {
    // MAIN 
    if(section === '') {
      // 
      if(context === '') {
        if(props.position === 'left')  
          return 'Resumo Geral'; 
        if(props.position === 'right')  
          return 'Minhas Colaborações?';
        else  
          return 'Atualizações Recentes';
      }
    };

    // CONTENT 
    if(section === 'content') {
      // CONTENT / MAIN
      if(context === '') 
        return 'Índice de Publicações';  
      // CONTENT / CREATE  
      if(context === 'create') {
        if(props.position === 'left')  
          return 'Nova Publicação'; 
        if(props.position === 'right')  
          return 'Publicar';
        else  
          return 'Itens Relacionados';
      }      
      // CONTENT / EDIT 
      if(context === 'edit') {
        if(props.position === 'left')  
          return 'Editar Apresentação'; 
        if(props.position === 'right')  
          return 'Publicar';
        if(props.position === 'contentGallery')  
          return 'Galeria'; 
        if(props.position === 'imagekitIndex')  
          return 'Descrição das Imagens'; 
        if(props.position === 'editImagekitIndex')  
          return 'Descrição das Imagens [MODO EDIÇÃO]';                         
        else  
          return 'Itens Relacionados';  
      } 
    };
      
    // CONTENT VIDEOS
    if(section === 'contentvideos') {
      // CONTENT / MAIN
      if(context === '') 
        return 'Índice de Conteúdo';  
      // CONTENT / CREATE  
      if(context === 'create') {
        if(props.position === 'left')  
          return 'Novo Conteúdo'; 
        if(props.position === 'right')  
          return 'Publicar';
        else  
          return 'Itens Relacionados';
      }      
      // CONTENT / EDIT 
      if(context === 'edit') {
        if(props.position === 'right')  
          return 'Publicar';
        if(props.position === 'left')  
          return 'Inserir Informações do Vídeo';   
        if(props.position === 'editVideosIndex')  
          return 'Descrição dos Vídeos [MODO EDIÇÃO]';                  
        else  
          return 'Índice de Vídeos Publicados';  
      } 
    };

  // CONTENT ARTICLES
  if(section === 'contentarticles') {    
    // CONTENT / EDIT 
    if(context === 'edit') {
      if(props.position === 'right')  
        return 'Publicar';
      if(props.position === 'articlesGallery')  
        return 'Galeria de Imagens em Bibliografias';
      if(props.position === 'imagekitIndex')  
        return 'Descrição das Bibliografias';
      if(props.position === 'editImagekitIndex')  
        return 'Descrição das Bibliografias [MODO EDIÇÃO]';                       
      else  
        return 'Índice de Bibliografias';  
    } 
  };

    // CONTENT CLIPPING
    if(section === 'contentclipping') {    
      // CONTENT / EDIT 
      if(context === 'edit') {
        if(props.position === 'right')  
          return 'Publicar';
        if(props.position === 'articlesGallery')  
          return 'Galeria de Imagens em Clipping';
        if(props.position === 'imagekitIndex')  
          return 'Descrição de Clippings';
        if(props.position === 'editImagekitIndex')  
          return 'Descrição de Clippings [MODO EDIÇÃO]';                       
        else  
          return 'Índice de Clippings';  
      } 
    };


    // SECTIONS 
    if(section === 'sections') {
      // SECTIONS / MAIN
      if(context === '') {  
        return 'Índice de Seções';
      }    
      // SECTIONS / EDIT 
      if(context === 'edit') {
        if(props.position === 'left')  
          return 'Editar Seção'; 
        if(props.position === 'right')  
          return 'Publicar';
        if(props.position === 'middle')  
          return 'Editar Categorias';  
        else  
          return 'Índice de Seções';  
      } 
    };

    // PAGES
    if(section === 'pages') {

      // PAGES / MAIN
      if(context === '') 
        return 'Índice de Páginas';    

      // PAGES / CREATE  
      if(context === 'create') {
        if(props.position === 'left')  
          return 'Nova Página'; 
        if(props.position === 'right')  
          return 'Status';
      }               

      // PAGES / EDIT 
      if(context === 'edit') {
        if(props.position === 'left')  
          return 'Editar Página'; 
        if(props.position === 'right')  
          return 'Status';
        else  
          return 'Definir';  
      } 
    };     
    
    // // MEMBERS 
    // if(section === 'members') {
    //   // MEMBERS / MAIN
    //   if(context === '') {
    //     if(props.position === 'left')  
    //       return 'Resumo de Colaboradores'; 
    //     if(props.position === 'right')  
    //       return 'Minha Conta???';
    //     else  
    //       return 'Índice de Colaboradores';
    //   }
    //   // MEMBERS / ALL
    //   if(context === 'all') 
    //     return 'Índice de Colaboradores';     
    //   // MEMBERS / EDIT 
    //   if(context === 'edit') {
    //     if(props.position === 'left')  
    //       return 'Editar Colaborador'; 
    //     if(props.position === 'right')  
    //       return 'Atualizar';
    //     else  
    //       return 'Produção Relacionada';  
    //   } 
    // }

    // // CATEGORIES 
    // if(section === 'categories' && context === '')
    //   return 'Minhas Categorias';

    // if(section === 'members' && context === '')
    //   return 'Minha Conta';

    // if(section === 'pages' && context === '')
    //   return 'Minhas Páginas';
  };


  return (
      <Typography component="div" variant="body1" color="inherit" gutterBottom sx={{ fontWeight: 600, }}>
        {titleGenerator(section, context)}
      </Typography>
  );
};

Title.propTypes = {
  position: PropTypes.string,
};

export default Title;
