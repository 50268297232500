import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSession, trackSession, updateProfile } from './features/sessionSlice';
import { getSections, getGroups, getCategories } from './features/sectionsSlice';
import { getContent, getStatuses, getContentCategories, getContentVideos, getContentArticles } from './features/contentSlice';
import { getPages } from './features/pagesSlice';
import { getImages } from './features/imagesSlice';
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from './components/ProtectedRoute';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// COMPONENTS
//// PUBLIC
import PublicHomeContent from './components/PublicHomeContent';
import PublicContent from './components/PublicContent';
import PublicRelatedContent from './components/PublicRelatedContent';
import PublicChronology from './components/PublicChronology';
import PublicPage from './components/PublicPage';
//// PRIVATE
import SectionsIndex from './components/SectionsIndex';
import SectionsEdit from './components/SectionsEdit';
import ContentIndex from './components/ContentIndex';
import ContentCreate from './components/ContentCreate';
import ContentEdit from './components/ContentEdit';
import ContentVideoEdit from './components/ContentVideoEdit'; 
import AdminVideoView from './components/AdminVideoView';
import ContentArticlesEdit from './components/ContentArticlesEdit';
import ContentClippingEdit from './components/ContentClippingEdit';
import PagesIndex from './components/PagesIndex';
import PagesCreate from './components/PagesCreate';
import PagesEdit from './components/PagesEdit';

// PAGES
import Home from './pages/Home';
import ImageView from './pages/ImageView';
import VideoView from './pages/VideoView';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Admin from './pages/Admin';

function App() {

    // REDUX SELECTORS
    const dispatch = useDispatch();
    const session = useSelector(state => state.session);

    // GET AND TRACK SESSION 
    useEffect(() => {
        dispatch(getSession());
        dispatch(getPages());
        dispatch(trackSession());
        dispatch(getSections());
        dispatch(getGroups());
        dispatch(getCategories());
        dispatch(getImages());
        dispatch(getContent());
        dispatch(getStatuses());
        dispatch(getContentCategories());
        dispatch(getContentVideos());
        dispatch(getContentArticles()); 

        return () => { 
            if(session?.event === 'SIGNED_IN')
                dispatch(updateProfile(session.profile))
        }
    }, [])

    if(session.sessionStatus === 'succeeded') {
        return (
            <Routes>
                <Route path="/" element={<Home />} >
                    <Route path="/" element={<PublicHomeContent />} />
                    <Route path="content/:contentId" element={<PublicContent />} />
                    <Route path="relatedcontent/:relatedContentId" element={<PublicRelatedContent />} />
                    <Route path="chronology/:chronologyRelatedContentId" element={<PublicChronology />} />
                    <Route path="page/:pageId" element={<PublicPage />} />
                </Route>
                <Route path="content/:contentId/:imageId" element={<ImageView />} />
                <Route path="content/:contentId/video/:videoId" element={<VideoView />} />
                <Route path="content/all/video/:videoId" element={<VideoView all={true} />} />

                {/* <Route path="home" element={<Home />} /> */}
                
                <Route path="signin" element={<Signin />} />
                <Route path="signup" element={<Signup />} />

                {/* PRIVATE ROUTE */}
                <Route 
                    path="admin/*" 
                    element={
                        <ProtectedRoute>
                            <Admin />
                        </ProtectedRoute>
                    } 
                >
                    {/* MAIN DASHBOARD */}
                    <Route index element={<ContentIndex />} />

                    {/* SECTIONS ROUTE */}
                    <Route path="sections" element={<SectionsIndex />} />
                    <Route path="sections/edit/:sectionId" element={<SectionsEdit />} />

                    {/* CONTENT ROUTE */}
                    <Route path="content" element={<ContentIndex />} />
                    <Route path="content/create" element={<ContentCreate />} />
                    <Route path="content/edit/:contentId" element={<ContentEdit />} />

                    {/* CONTENT RELATED ROUTES */}
                    <Route path="contentvideos/edit" element={<ContentVideoEdit />} />

                    {/* VIEW VIDEOS ROUTE */}
                    <Route path="contentvideos/:videoId" element={<AdminVideoView />} />


                    {/* CONTENT RELATED ARTICLES */}
                    <Route path="contentarticles/edit" element={<ContentArticlesEdit />} />

                    {/* CONTENT RELATED AUTHOR ARTICLES */}
                    <Route path="contentauthorarticles/edit" element={<ContentArticlesEdit />} />

                    {/* CONTENT RELATED CATALOGUES */}
                    <Route path="contentcatalogues/edit" element={<ContentArticlesEdit />} />
                   
                    {/* CONTENT RELATED CLIPPING */}
                    <Route path="contentclipping/edit" element={<ContentClippingEdit />} />
                    
                    {/* PAGES ROUTE */}
                    <Route path="pages" element={<PagesIndex />} />
                    <Route path="pages/create" element={<PagesCreate />} />
                    <Route path="pages/edit/:pageId" element={<PagesEdit />} />


                </Route> 

                <Route path="*" element={<Navigate to="/" />} /> 
            </Routes>

        );

    } else { 
        return (
            <Backdrop
                open={true}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>   
        ); 
    };
};

export default App;
