import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { updateContent, refreshContentCategories, updateContentEditImageGallerySize } from '../features/contentSlice';
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ImageGallery from './ImageGallery'; 
import Title from './Title'; 
import CategoryCheckbox from './CategoryCheckbox'; 
import UppyDashboard from './UppyDashboard';
import DateSetter from './DateSetter'; 
import ImagekitFilesIndex from './ImagekitFilesIndex';
import { useHistory } from './history';
import { useElementSize } from './useElementSize'

const ContentEdit = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();       

    // MY HISTORY HOOK
    const history = useHistory(); 

    // REDUX SELECTORS
    const dispatch = useDispatch();
    const content = useSelector(state => state.content.content).find(c => c.id === parseInt(params.contentId, 10) );
    const contentCategoriesIds = useSelector(state => state.content.content_categories)
        .filter(cc => cc.content_id === parseInt(params.contentId, 10) )
        .map(c => {return c.category_id} ); 
    const statuses = useSelector(state => state.content.statuses);
    const sections = useSelector(state => state.sections.sections);
    const categories = useSelector(state => state.sections.categories);

    // FILTER CATEGORIES RELATED
    const contentCategories = categories.filter(cc => contentCategoriesIds.includes(cc.id)); 

    // EDIT RESEARCH STATES
    const dateTime = { ...content.date, start: DateTime.fromObject(content.date.start), end: DateTime.fromObject(content.date.end) }
    const contentWithDate = { ...content, date: dateTime }; 
    const [contentData, setContentData] = useState(contentWithDate); //console.log('contentData',contentData)
    const [checked, setChecked] = useState([...contentCategories]); 

    // CHANGE Content STATES
    const handleChangeContentData = (event) => {
        setContentData({...contentData, [event.target.name]: event.target.value});
    };

    const handleChangeFeatured = (event) => {
        setContentData({...contentData, [event.target.name]: event.target.checked});
    };

    // UPDATE CONTENT
    const handleUpdateContent = () => {
        const newDate = { ...contentData.date, start: contentData.date.start.c, end: contentData.date.end.c }
        const updatedContent = { ...contentData, date: newDate };
        dispatch(updateContent(updatedContent));
        dispatch(refreshContentCategories({ 
            contentId: parseInt(params.contentId, 10),
            contentCategoriesData: checked,
        }));
    };

    // REF TO IMAGE GRID
    const ref = useRef(null);
    const size = useElementSize(ref);  

    // TRACK IMAGE GRID WIDTH
    useEffect(() => {
        const getMainSize = (size) => { 
            const { x, y, width, height, top, right, left, bottom, } = size;
            const mainsize = {
                x: x, 
                y: y, 
                width: width, 
                height: height, 
                top: top, 
                right: right, 
                left: left, 
                bottom: bottom
            };
            
            return mainsize;
        };
         
        dispatch(updateContentEditImageGallerySize(getMainSize(size)));
    }, [history.pathArray]);

    return (
        <Container maxWidth="xl" sx={{ my: 4 }}>
            <Grid container spacing={2}>
                {/* LEFT PANEL */}
                <Grid item xs={12} md={8}>
                    <Paper sx={{ minHeight: 400, }}>
                        <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', }}>
                            <Title position={'left'}/> 
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <TextField
                                value={contentData.title}
                                //error={emailError(email)}
                                onChange={(event) => handleChangeContentData(event)}
                                fullWidth
                                label="Título"
                                name="title"
                                size="small"
                                multiline={true}
                                minRows={1}
                                maxRows={2}
                                type="text"
                                //error={true}
                                helperText="Título principal da página"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                value={contentData.slug}
                                //error={emailError(email)}
                                onChange={(event) => handleChangeContentData(event)}
                                fullWidth
                                label="Título de Menu"
                                name="slug"
                                size="small"
                                type="text"
                                //error={true}
                                helperText="Termo do menu de navegação principal, que dá acesso à página da publicação"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            />

                            <TextField
                                value={contentData.body}
                                //error={emailError(email)}
                                onChange={(event) => handleChangeContentData(event)}
                                fullWidth
                                label="Apresentação"
                                name="body"
                                size="small"
                                type="text"
                                multiline
                                minRows={3}
                                maxRows={10}
                                //error={true}
                                helperText="Texto principal de descrição da publicação"
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            />      

                        </Grid>
                    </Paper>

                </Grid>

                {/* RIGHT PANEL */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ minHeight: 400, }} >
                        <Grid item xs={12} sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', }}>
                            <Title position={'right'}/>
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <TextField
                                name="status"
                                select
                                label="Status"
                                size="small"
                                value={contentData.status}
                                onChange={(event) => handleChangeContentData(event)}
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            >
                                {statuses.map((c) => (
                                    <MenuItem key={`${c.id}`} value={c.id}>
                                        {c.status}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                name="section_id"
                                select
                                label="Seção"
                                size="small"
                                value={contentData.section_id}
                                onChange={(event) => handleChangeContentData(event)}
                                sx={{ my: 1,}}
                                InputLabelProps={{ shrink: true }}
                            >
                                {sections.map(s => (
                                    <MenuItem key={`${s.id}`} value={s.id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <CategoryCheckbox checked={checked} setChecked={(obj) => setChecked(obj)} />
          
                        </Grid>

                        <Divider />

                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <FormControlLabel
                                label="Destacado na Página Inicial"
                                name="featured"
                                control={<Checkbox checked={contentData.featured} onChange={handleChangeFeatured} />}
                            />
                        </Grid>

                        <Divider />

                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <DateSetter 
                                setDate={(value) => setContentData({ ...contentData, date:value })}
                                date={{ ...contentData.date }}
                            />
                        </Grid>

                        <Divider />

                        <Grid item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                            <Button 
                                variant="contained" 
                                color="success"
                                fullWidth 
                                sx={{ my: 2,}} 
                                onClick={handleUpdateContent}  
                            >
                                Salvar Publicação
                            </Button>
                        </Grid>





                    </Paper>

                </Grid> 

                <ImagekitFilesIndex />

                <Grid item xs={12} >
                {/* IMAGE GALLERY */}
                    <Paper >
                        <Grid item xs={12} sx={{ px: 2, py: 2, display: 'flex', flexDirection: 'column', }}>
                            <Stack 
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Title position={'contentGallery'}/>
                                <Box sx={{ flexgrow: 1, }}/>
                                <UppyDashboard />
                            </Stack> 
                             
                        </Grid>
                        <Divider />
                        <Grid ref={ref} id="image-gallery" item xs={12} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}> 

                            <ImageGallery />
                        
                        </Grid >
                    </Paper>
                </Grid>
            </Grid>

        </Container>
    )
};

export default ContentEdit;