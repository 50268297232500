import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectArticles, selectAuthorArticles, selectCatalogues, selectClipping } from '../features/imagesSlice';
import { useParams } from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FeedIcon from '@mui/icons-material/Feed';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import Collapse from '@mui/material/Collapse';
// import PublicDialog from './PublicDialog';
// import PublicContentVideoList from './PublicContentVideoList';
import PublicContentRelatedList from './PublicContentRelatedList';
import { publicTheme, ContentMenuButton } from '../styles/publicStyles';
import { sortByDate, sortImagekitFileByDate } from '../utils';

const PublicContentInfoMenu = (props) => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();  

    // const publicContent = useSelector(state => state.content.content);
    const articlesSelector = useSelector(selectArticles) || [];
    const authorArticlesSelector = useSelector(selectAuthorArticles) || [];
    const cataloguesSelector = useSelector(selectCatalogues) || [];
    const clippingSelector = useSelector(selectClipping) || [];

    //const content = publicContent.find(c => c.id === parseInt(params.contentId, 10));

    const contentArticlesFileIds = useSelector(state => state.content.content_articles)
        .filter(aa => aa.content_id === parseInt(params.contentId, 10))
        .map(faa => { return faa.file_id }); 
    
    const articles = articlesSelector 
        .filter(ar => contentArticlesFileIds.includes(ar.fileId))
        .sort(sortImagekitFileByDate); 
    const authorArticles = authorArticlesSelector
        .filter(aa => contentArticlesFileIds.includes(aa.fileId))
        .sort(sortImagekitFileByDate);
    const catalogues = cataloguesSelector 
        .filter(ca => contentArticlesFileIds.includes(ca.fileId))
        .sort(sortImagekitFileByDate); 
    const clipping = clippingSelector
        .filter(cl => contentArticlesFileIds.includes(cl.fileId))
        .sort(sortImagekitFileByDate); 
    
    const contentVideos = useSelector(state => state.content.content_videos)
        .filter((v, index) => parseInt(v.content_id, 10) === parseInt(params.contentId, 10))
        .sort(sortByDate); 
    

    // DIALOG STATES
    const [videosDialogOpen, setVideosDialogOpen] = useState(false);
    const [articlesDialogOpen, setArticlesDialogOpen] = useState(false);
    const [authorArticlesDialogOpen, setAuthorArticlesDialogOpen] = useState(false);
    const [cataloguesDialogOpen, setCataloguesDialogOpen] = useState(false);
    const [clippingDialogOpen, setClippingDialogOpen] = useState(false);

    // HANDLE TOGGLE DIALOG
    const handleVideosDialogOpen = () => {
        setVideosDialogOpen(!videosDialogOpen);
    };
    const handleArticlesDialogOpen = () => {
        setArticlesDialogOpen(!articlesDialogOpen);
    };
    const handleAuthorArticlesDialogOpen = () => {
        setAuthorArticlesDialogOpen(!authorArticlesDialogOpen);
    };
    const handleCataloguesDialogOpen = () => {
        setCataloguesDialogOpen(!cataloguesDialogOpen);
    };
    const handleClippingDialogOpen = () => {
        setClippingDialogOpen(!clippingDialogOpen);
    };
    // const handleDialogClose = () => {
    //     setVideosDialogOpen(false);
    //     setArticlesDialogOpen(false);
    //     setAuthorArticlesDialogOpen(false);
    //     setCataloguesDialogOpen(false);
    //     setClippingDialogOpen(false);
    // };    

    return (
        <ThemeProvider theme={publicTheme} >  
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        sx={{ pt: 3, }}
                    >
                        <ButtonGroup 
                            fullWidth
                            //size="small"
                            color="lead" 
                            orientation="vertical"
                            disableElevation
                            aria-label="menu de conteúdo relacionado"
                        >
                            {contentVideos.length > 0 && <ContentMenuButton 
                                id="video-library-menu"
                                
                                aria-label="videos relacionados"
                                onClick={handleVideosDialogOpen}
                                endIcon={<VideoLibraryIcon />}
                            >
                                Vídeos
                            </ContentMenuButton>}

                            {articles.length > 0 && <ContentMenuButton 
                                id="articles-menu"
                                aria-label="bibliografia relacionada"
                                onClick={handleArticlesDialogOpen}
                                endIcon={<MenuBookIcon />}
                            >
                                Bibliografia
                            </ContentMenuButton> }

                            {authorArticles.length > 0 && <ContentMenuButton 
                                id="authorarticles-menu"
                                aria-label="textos da artista relacionados"
                                onClick={handleAuthorArticlesDialogOpen}
                                endIcon={<LocalLibraryIcon />}
                            >
                                Textos da Artista
                            </ContentMenuButton> }

                            {catalogues.length > 0 && <ContentMenuButton 
                                id="catalogues-menu"
                                aria-label="publicações relacionadas"
                                onClick={handleCataloguesDialogOpen}
                                endIcon={<FeedIcon />} 
                            >
                                Publicações
                            </ContentMenuButton> }

                            {clipping.length > 0 && <ContentMenuButton 
                                id="clipping-menu"
                                aria-label="clipping relacionado"
                                onClick={handleClippingDialogOpen}
                                endIcon={<NewspaperIcon />} 
                            >
                                Clipping
                            </ContentMenuButton> }                                       
                        </ButtonGroup>
                        
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{ pb: 3, }}>
                    <Collapse in={videosDialogOpen}>
                        {/* <ClickAwayListener onClickAway={handleDialogClose}> */}
                        <PublicContentRelatedList context="videos"/>
                        {/* </ClickAwayListener> */}
                    </Collapse>
                    <Collapse in={articlesDialogOpen}>
                        <PublicContentRelatedList data={articles} context="articles"/>
                    </Collapse>
                    <Collapse in={authorArticlesDialogOpen}>
                        <PublicContentRelatedList data={authorArticles} context="authorArticles"/>
                    </Collapse>
                    <Collapse in={cataloguesDialogOpen}>
                        <PublicContentRelatedList data={catalogues} context="catalogues"/>
                    </Collapse>
                    <Collapse in={clippingDialogOpen}>
                        <PublicContentRelatedList data={clipping} context="clipping"/>
                    </Collapse>
                </Grid>
            </Grid>
            
        </ThemeProvider>
    );
};

export default PublicContentInfoMenu; 