import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTableTemplates } from './tableTemplates';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Title from './Title'; 

// REACT DATA TABLE COMPONENT
import FilteredDataTable from './FilteredDataTable';

// MY HISTORY HOOK
import { useHistory } from './history';

const ContentIndex = () => {

    // TABLE TEMPLATES HOOK
    const tableTemplates = useTableTemplates(); 

    // MY HISTORY HOOK
    const history = useHistory();
    const context = history?.pathArray[3] || ''

    // REDUX SELECTORS
    const content = useSelector(state => state.content.content);
    const getContentStatus = useSelector(state => state.content.getContentStatus);
    const createContentTable = Boolean( getContentStatus === "succeeded" )  

    const index = () => {
        return (
            <Grid item xs={12}>
                <Paper square elevation={1}>
                    {createContentTable && content.length > 0 ? (
                        <FilteredDataTable 
                            data={content} 
                            columns={tableTemplates.contentColumns} 
                            title={<Title position={'index'}/> }
                        />
                    ) : (
                        <Typography component="div" variant="body1" color="inherit" sx={{ fontStyle: 'italic', textAlign: 'center', pt: 4, }}>
                            Sem dados para exibir
                        </Typography>
                    )}
                </Paper>
            </Grid>
        );
    }; 
    
    if(context === 'edit') {
        return index();
    } else {
        return (
            <Container maxWidth="xl" sx={{ my: 4, }}>
                <Grid container spacing={3}>
                    {index()}  
                </Grid>
            </Container>
        );
    }; 

};

export default ContentIndex;