import * as React from 'react';
import { ThemeProvider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { publicTheme, PaperControls } from '../styles/publicStyles';

const PublicMenuBar = (props) => {

    const { open, setOpen, show } = props;

    return ( 
        <ThemeProvider theme={publicTheme} > 
            <PaperControls 
                open={open}
                show={show}
                elevation={1} 
                square
            >
                <IconButton disableRipple onClick={setOpen} sx={{  color: publicTheme.palette.common.white, }} >
                    {open ? <ChevronRightIcon /> : <AddIcon />}
                </IconButton>
            </PaperControls>
        </ThemeProvider>
    );
};

export default PublicMenuBar;