import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteGroup, addCategory, updateGroupName, updateCategoriesNames, updateGroup, updateGroupCategories, deleteCategory } from '../features/sectionsSlice';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ClearIcon from '@mui/icons-material/Clear';  
import LabelIcon from '@mui/icons-material/Label';


const CategoriesEdit = ( props ) => {

    const { groupId, cleanTypes } = props;

    // REDUX STATES
    const dispatch = useDispatch();
    const groupObj = useSelector(state => state.sections.groups).find(g => g.id === groupId);  // console.log('groupObj',groupObj);
    const categories = useSelector(state => state.sections.categories); 

    const groupCategoriesLength = categories.filter(c => c.group_id === groupId).length; //console.log(categories.filter(c => c.group_id === groupId)); 
    const dummyCategoryObj = { group_id: groupId, name: '', };

    const [newCategoryData, setNewCategoryData] = useState({...dummyCategoryObj});
    const [groupObjData, setGroupObjData] = useState({...groupObj});  //console.log('groupObjData',groupObjData);
    const [categoriesArrayData, setCategoriesArrayData] = useState([...categories]); //console.log('categoriesArrayData',categoriesArrayData);

    // HANDLE EDIT GROUPS AND CATEGORIES
    const handleSaveGroup = () => {
        dispatch(updateGroup(groupId));
        dispatch(updateGroupCategories(groupId));
    };

    // HANDLE ADD CATEGORY
    const handleAddCategory = () => {
        const newCategory = {
            group_id: groupId,
            name: newCategoryData.name
        }
        dispatch(addCategory(newCategory));
        setNewCategoryData(dummyCategoryObj);
    };

    // CHANGE CLASS OBJECT STATE
    const handleChangeGroupObj = (event) => {
        let newGroup = {...groupObjData, [event.target.name]: event.target.value}
        setGroupObjData(newGroup);
        dispatch(updateGroupName(newGroup));
    };

    // CHANGE TAGS OBJECTS STATE
    const handleChangeCategoriesArray = (event) => { //console.log('handleChangeTagsArray-event.target',event.target); console.log('handleChangeTagsArray-event.target.id',event.target.id); console.log('handleChangeTagsArray-event.target.name',event.target.name);
        const newCategories = categoriesArrayData.map(cam => {
            if( cam.id === parseInt(event.target.id) ) {
                let newCategory = {...cam, [event.target.name]: event.target.value}; 
                dispatch(updateCategoriesNames(newCategory));

                return newCategory;
            }
            else
                return cam;
        });
        
        setCategoriesArrayData([...newCategories]); //console.log('handleChangeTagsArray-newTags',newTags);
    };    

    // HANDLE DELETE CLASS
    const handleDeleteGroup = (groupobj) => {
        dispatch(deleteGroup(groupobj));
        setGroupObjData({});
        cleanTypes();
    };

    // HANDLE DELETE TAG
    const handleDeleteCategory = (categoryobj) => {
        dispatch(deleteCategory(categoryobj));
        //const updatedTags = .filter(t => t.id !== action.payload.id);
        //setTagsArrayData({...dummyGroupObj});
    };

    // VALIDATE FIELDS
    const validateName = (str) => {
        if(str.length > 2)
        return true
        else
        return false
    };

    // RE-RENDER COMPONENT ON TAG ARRAY CHANGES
    useEffect(() => {
        setCategoriesArrayData([...categories]);
    }, [categories])


    if(groupId) {
        return (
            <React.Fragment>
                <Box
                    sx={{
                        minWidth: '400px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        p: 3,
                    }}
                >

                    <Stack spacing={5} sx={{ width: '100%', maxWidth: 560, }}>
                        <TextField
                            value={groupObjData && groupObjData.name}
                            //error={emailError(email)}
                            onChange={(event) => handleChangeGroupObj(event)}
                            variant="standard"
                            fullWidth
                            id="group"
                            name="name"
                            type="text"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><BookmarkIcon /></InputAdornment>,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton disabled={(groupCategoriesLength > 0)} onClick={() => handleDeleteGroup(groupObj)} >
                                        <ClearIcon color={(groupCategoriesLength > 0) ? 'inherit' : 'error'}/>
                                    </IconButton>
                                </InputAdornment>,
                            }} 
                            InputLabelProps={{
                                shrink: true,
                            }}
                            //helperText={emailError(email) ? "Digite um endereço de e-mail válido" : null}
                        />
                        <Stack spacing={1} >
                            {categoriesArrayData && categoriesArrayData.filter(c => c.group_id === groupId).map(gc => (
                                <TextField
                                    value={gc.name}
                                    //error={emailError(email)}
                                    onChange={(event) => handleChangeCategoriesArray(event)}
                                    variant="standard"
                                    fullWidth
                                    id={gc.id}
                                    name="name"
                                    type="text"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><LabelIcon /></InputAdornment>,
                                        endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleDeleteCategory(gc)} ><ClearIcon color="error"/></IconButton></InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //helperText={emailError(email) ? "Digite um endereço de e-mail válido" : null}
                                /> 
                                )
                            )}
                        </Stack>  
                        <Box >
                            <TextField
                                value={newCategoryData.name}
                                //error={emailError(email)}
                                onChange={e => setNewCategoryData({...newCategoryData, [e.target.name]: e.target.value})}
                                fullWidth
                                label="Nome da Categoria"
                                id="newcategoryname"
                                name="name"
                                size="small"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //helperText={emailError(email) ? "Digite um endereço de e-mail válido" : null}
                            />
                            <Button 
                                variant="outlined" 
                                disabled={!validateName(newCategoryData.name) || newCategoryData.name.length === 0}
                                fullWidth 
                                endIcon={<LabelIcon />}
                                onClick={e => {
                                    e.preventDefault();
                                    handleAddCategory();
                                }}
                                sx={{ mt: 1, }}
                            >
                                Incluir nova Categoria
                            </Button>
                        </Box>  

                        <Button 
                            variant="contained" 
                            //disabled={!validateName(groupObjState.name) || groupObjState.name.length === 0}
                            fullWidth 
                            endIcon={<BookmarkAddedIcon />}
                            onClick={e => {
                                e.preventDefault();
                                handleSaveGroup();
                            }}
                            sx={{ mt: 1, }}
                        >
                            Salvar
                        </Button>
                    </Stack>
                </Box>
            </React.Fragment>
        );
    } else {
        return null; 
    };    
}

export default CategoriesEdit;

// CategoriesEdit.defaultProps = {
//     groupId: 1,
// };

CategoriesEdit.propTypes = {
    groupId: PropTypes.number.isRequired,
    cleanTypes: PropTypes.func.isRequired,
};
