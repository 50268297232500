import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../services/supabaseClient';

export const getSections = createAsyncThunk('sections/getSections', async (obj , { dispatch, getState }) => {
    try { 
        const { data, error } = await supabase
            .from('sections')
            .select('*')    
            .order('name', { ascending: false });

        if (error) 
            throw error;

        return data;

    } catch (error) {
        alert('getSections()-error')
        console.log(error)
        alert(error.message)
    };
});

export const createSection = createAsyncThunk('sections/createSection', async (obj , { dispatch, getState }) => {
    try { 
        const { data, error } = await supabase
            .from('sections')
            .insert([obj])
            .single()

        alert('Seção criada com sucesso.');

        if(error) {
            throw error
        }  

        return data;

    } catch (error) {
        alert('createSection()-error')
        console.log(error)
        alert(error.message)
    };
});

export const updateSection = createAsyncThunk('sections/updateSection', async (obj , { dispatch, getState }) => {
    try { 
        const { sections } = getState()
        const { data, error } = await supabase
            .from('sections')
            .update(obj)
            .match({ id: obj.id })
            .single()
        
        const payload = sections.sections.map(s => {
            if(s.id === obj.id)
                return data;
            else 
                return s;
        });  

        alert('Seção atualizada com sucesso.');
        
        if(error) {
            throw error
        }  

        return payload;

    } catch (error) {
        alert('updateSection()-error')
        console.log(error)
        alert(error.message)
    };
});

export const getGroups = createAsyncThunk('sections/getGroups', async (obj , { dispatch, getState }) => {
    try { 
        const { data, error } = await supabase
            .from('groups')
            .select(`*`)    
            .order('name', { ascending: true });

        if (error) 
            throw error;

        return data;

    } catch (error) {
        alert('getGroups()-error')
        console.log(error)
        alert(error.message)
    };
});

export const addGroup = createAsyncThunk('section/addGroup', async (obj , { dispatch, getState }) => {
    try {     
        const { data, error } = await supabase
            .from('groups')
            .upsert({ section_id: obj.section_id, name: obj.name }, { ignoreDuplicates: true })
            .single();
  
        //alert('addGroup()-success - Grupo adicionado')
        
        if (error) 
            throw error;
        
        return data;

    } catch (error) {
        alert('addGroup()-error')
        console.log(error)
        alert(error.message)
    };
});

export const updateGroup = createAsyncThunk('sections/updateGroup', async (obj , { dispatch, getState }) => {
    try {  
        const { sections } = getState();
        const updatedGroup = sections.groups.find(sg => sg.id === obj);

        const { error } = await supabase
            .from('groups')
            .update(updatedGroup, {
                returning: 'minimal'
            })
            .match({ id: obj });
        
        alert('updateGroup()-Success - Grupo atualizado');

        if (error) 
            throw error; 

    } catch (error) {
        alert('updateGroup()-Error');
        console.log(error);
        alert(error.message);
    };
});

export const deleteGroup = createAsyncThunk('sections/deleteGroup', async (obj , { dispatch, getState }) => {
    try {     
        const { error } = await supabase
            .from('groups')
            .delete()
            .match({ id: obj.id })

        if (error) 
            throw error;
          
        dispatch(removeGroup(obj));
        alert('deleteGroup()-Success - Grupo excluído'); 

    } catch (error) {
        alert('deleteGroup()-error')
        console.log(error)
        alert(error.message)
    };
});

export const getCategories = createAsyncThunk('sections/getCategories', async (obj , { dispatch, getState }) => {
    try { 
        const { data, error } = await supabase
            .from('categories')
            .select('*')    
            .order('name', { ascending: true });

        if (error) 
            throw error;

        return data;

    } catch (error) {
        alert('getCategories()-error')
        console.log(error)
        alert(error.message)
    };
});

export const updateGroupCategories = createAsyncThunk('sections/updateGroupCategories', async (obj , { dispatch, getState }) => {
    try {  
        const { sections } = getState();
        const updatedGroupCategories = sections.categories.filter(sc => sc.group_id === obj);

        const { error } = await supabase
            .from('categories')
            .upsert(updatedGroupCategories, {
                returning: 'minimal'
            });
        //alert('updateGroupCategories()-Success - Categorias atualizadas');

        if (error) 
            throw error; 

    } catch (error) {
        alert('updateGroupCategories()-Error');
        console.log(error);
        alert(error.message);
    };
});

export const addCategory = createAsyncThunk('sections/addCategory', async (obj , { dispatch, getState }) => {
    try {     
        const { data, error } = await supabase
            .from('categories')
            .upsert({ group_id: obj.group_id, name: obj.name }, { ignoreDuplicates: true })
            .single();
  
        
        //alert('addCategory()-success - Categoria inserida');
        
        if (error) 
            throw error;
        
        return data;

    } catch (error) {
        alert('addCategory()-error')
        console.log(error)
        alert(error.message)
    };
});

export const deleteCategory = createAsyncThunk('sections/deleteCategory', async (obj , { dispatch, getState }) => {
    try {     
        const { error } = await supabase
            .from('categories')
            .delete()
            .match({ id: obj.id })

        if (error) 
            throw error;
          
        dispatch(removeCategory(obj)); 
        alert('deleteCategory()-success - Categoria removida');

    } catch (error) {
        alert('deleteCategory()-error')
        console.log(error)
        alert(error.message)
    };
});

export const sectionsSlice = createSlice({
    name: 'sections',
    initialState: {
        sections: [],
        groups: [],
        categories: [],

        getSectionsStatus: 'idle',
        getSectionsError: null,

        createSectionStatus: 'idle',
        createSectionError: null,

        updateSectionStatus: 'idle',
        updateSectionError: null,

        getGroupsStatus: 'idle',
        getGroupsError: null,

        addGroupStatus: 'idle',
        addGroupError: null,

        updateGroupStatus: 'idle',
        updateGroupError: null,

        deleteGroupStatus: 'idle',
        deleteGroupError: null, 

        getCategoriesStatus: 'idle',
        getCategoriesError: null,

        updateGroupCategoriesStatus: 'idle',
        updateGroupCategoriesError: null,

        addCategoryStatus: 'idle',
        addCategoryError: null,

        deleteCategoryStatus: 'idle',
        deleteCategoryError: null,
    },
    reducers: {
        updateGroupName: {
            reducer(state, action) {
                const newstate = state.groups.map(g => {
                    if(g.id !== action.payload.id) 
                        return g;
                    else {
                        const newgroup = {
                            ...g, 
                            name: action.payload.name
                        }
                        return newgroup;   
                    } 
                }) 
                return {
                    ...state,
                    groups: newstate,
                }  
            },
            prepare({id, name}) {
                return {
                    payload: {
                        id,
                        name
                    }
                }
            }
        },
        updateCategoriesNames: {
            reducer(state, action) {
                const newstate = state.categories.map(c => {
                    if(c.id !== action.payload.id) 
                        return c;
                    else {
                        const newcategory = {
                            ...c, 
                            name: action.payload.name
                        }
                        return newcategory;   
                    } 
                }) 
                return {
                    ...state,
                    categories: newstate,
                }  
            },
            prepare({id, name}) {
                return {
                    payload: {
                        id,
                        name
                    }
                }
            }
        },
        removeGroup(state, action) { 
            const newGroups = state.groups.filter(g => g.id !== action.payload.id);
            state.groups = newGroups;
        },
        removeCategory(state, action) { 
            const newCategories = state.categories.filter(c => c.id !== action.payload.id);
            state.categories = newCategories;
        }
     
    },
    extraReducers: {
        [getSections.pending]: (state) => {
            state.getSectionsStatus = 'loading'
        },
        [getSections.fulfilled]: (state, action) => {
            state.sections = action.payload
            state.getSectionsStatus = 'succeeded'
        },
        [getSections.rejected]: (state, action) => {
          state.getSectionsStatus = 'failed'
          state.getSectionsError = action.error
        },

        [createSection.pending]: (state) => {
            state.createSectionStatus = 'loading'
        },
        [createSection.fulfilled]: (state, action) => {
            state.sections.unshift(action.payload)
            state.createSectionStatus = 'succeeded'
        },
        [createSection.rejected]: (state, action) => {
          state.createSectionStatus = 'failed'
          state.createSectionError = action.error
        },

        [updateSection.pending]: (state) => {
            state.updateSectionStatus = 'loading'
        },
        [updateSection.fulfilled]: (state, action) => {
            state.sections = action.payload
            state.updateSectionStatus = 'succeeded'
        },
        [updateSection.rejected]: (state, action) => {
          state.updateSectionStatus = 'failed'
          state.updateSectionError = action.error
        },

        [getGroups.pending]: (state) => {
            state.getGroupsStatus = 'loading'
        },
        [getGroups.fulfilled]: (state, action) => {
            state.groups = action.payload
            state.getGroupsStatus = 'succeeded'
        },
        [getGroups.rejected]: (state, action) => {
          state.getGroupsStatus = 'failed'
          state.getGroupsError = action.error
        },

        [addGroup.pending]: (state) => {
            state.addGroupStatus = 'loading'
        },
        [addGroup.fulfilled]: (state, action) => {
            state.groups.unshift(action.payload)
            state.addGroupStatus = 'succeeded'
        },
        [addGroup.rejected]: (state, action) => {
          state.addGroupStatus = 'failed'
          state.addGroupError = action.error
        },

        [updateGroup.pending]: (state) => {
            state.updateGroupStatus = 'loading'
        },
        [updateGroup.fulfilled]: (state, action) => {
            state.updateGroupStatus = 'succeeded'
        },
        [updateGroup.rejected]: (state, action) => {
          state.updateGroupStatus = 'failed'
          state.updateGroupError = action.error
        },

        [deleteGroup.pending]: (state) => {
            state.deleteGroupStatus = 'loading'
        },
        [deleteGroup.fulfilled]: (state, action) => {
            state.deleteGroupStatus = 'succeeded'
        },
        [deleteGroup.rejected]: (state, action) => {
          state.deleteGroupStatus = 'failed'
          state.deleteGroupError = action.error
        },

        [getCategories.pending]: (state) => {
            state.getCategoriesStatus = 'loading'
        },
        [getCategories.fulfilled]: (state, action) => {
            state.categories = action.payload
            state.getCategoriesStatus = 'succeeded'
        },
        [getCategories.rejected]: (state, action) => {
          state.getCategoriesStatus = 'failed'
          state.getCategoriesError = action.error
        },

        [addCategory.pending]: (state) => {
            state.addCategoryStatus = 'loading'
        },
        [addCategory.fulfilled]: (state, action) => {
            state.categories.unshift(action.payload)
            state.addCategoryStatus = 'succeeded'
        },
        [addCategory.rejected]: (state, action) => {
          state.addCategoryStatus = 'failed'
          state.addCategoryError = action.error
        },

        [updateGroupCategories.pending]: (state) => {
            state.updateGroupCategoriesStatus = 'loading'
        },
        [updateGroupCategories.fulfilled]: (state, action) => {
            state.updateGroupCategoriesStatus = 'succeeded'
        },
        [updateGroupCategories.rejected]: (state, action) => {
          state.updateGroupCategoriesStatus = 'failed'
          state.updateGroupCategoriesError = action.error
        },

        [deleteCategory.pending]: (state) => {
            state.deleteCategoryStatus = 'loading'
        },
        [deleteCategory.fulfilled]: (state, action) => {
            state.deleteCategoryStatus = 'succeeded'
        },
        [deleteCategory.rejected]: (state, action) => {
          state.deleteCategoryStatus = 'failed'
          state.deleteCategoryError = action.error
        },
      }
});

// SELECTOR OF COMPLETE SECTIONS TREE 
export const selectSectionsTree  = state => {
    if (    state.sections.getSectionsStatus === 'succeeded' && 
            state.sections.getGroupsStatus === 'succeeded' &&
            state.sections.getCategoriesStatus === 'succeeded' ) {
                
        const groupCategories = state.sections.groups.map(g => { 
            return({
                ...g,
                groupCategories: state.sections.categories.filter(c => c.group_id === g.id),
            }) 
        });

        const sectionTree = state.sections.sections.map(s => {
            return ({
                ...s, 
                sectionGroups: groupCategories.filter(g => g.section_id === s.id),
            });
        });

        return sectionTree;
    }

    return null;
};

export const { 
    updateGroupName,
    updateCategoriesNames,
    removeGroup,
    removeCategory,
} = sectionsSlice.actions;

export default sectionsSlice.reducer;