import * as React from 'react';
import { useEffect, useRef } from 'react';
import { 
    selectArticles, 
    selectArticlesCategories, 
    selectAuthorArticles, 
    selectAuthorArticlesCategories, 
    selectCatalogues, 
    selectCataloguesCategories, 
    selectClipping, 
    selectClippingCategories } from '../features/imagesSlice'; 
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updateDrawer } from '../features/sessionSlice'; 
import { updatePublicImageGalleryContainerSize, selectVideosCategories } from '../features/contentSlice'; 
import { ThemeProvider } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Logo from './Logo';
import { publicTheme } from '../styles/publicStyles'; 
import { sxProps, headerProps } from '../styles/publicRelatedContentStyles';
import { useElementSize } from './useElementSize';
import { articleContent, articleAuthors, videoContent, sortImagekitFileByDate, sortByDate } from '../utils';

const PublicContent = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();

    // REDUX SELECTORS
    const dispatch = useDispatch(); 

    // REF TO IMAGE GRID
    const ref = useRef(null);
    const size = useElementSize(ref);

    // RELATED CONTENT MENU ELEMENTS
    const articles = useSelector(selectArticles); 
    const articlesCategories = useSelector(selectArticlesCategories);
    const authorArticles = useSelector(selectAuthorArticles); 
    const authorArticlesCategories = useSelector(selectAuthorArticlesCategories);
    const catalogues = useSelector(selectCatalogues); 
    const cataloguesCategories = useSelector(selectCataloguesCategories);
    const clipping = useSelector(selectClipping); 
    const clippingCategories = useSelector(selectClippingCategories);
    const contentVideos = useSelector(state => state.content.content_videos);
    const videosCategories = useSelector(selectVideosCategories);
     
    const mainDivSize = useSelector(state => state.content.mainDivSize);    
    const relatedContent = useSelector(state => state.content.related_content)
        .find(rc => rc.id === parseInt(params.relatedContentId, 10) );

    // CLOSE DRAWER ON LOAD
    useEffect(() => {
        dispatch(updateDrawer(false));
    }, []);
    
    // TRACK MAIN WIDTH
    useEffect(() => {
        const getMainSize = (size) => { 
            const { x, y, width, height, top, right, left, bottom, } = size;
            const mainsize = {
                x: x, 
                y: y, 
                width: width, 
                height: height, 
                top: top, 
                right: right, 
                left: left, 
                bottom: bottom
            };
            
            return mainsize;
        };
         
        dispatch(updatePublicImageGalleryContainerSize(getMainSize(size)));
    }, [mainDivSize, size, dispatch]);  

    // RELATED CONTENT LISTS
    const showRelateContent = () => {
 
        if(parseInt(params.relatedContentId, 10) === 1 && videosCategories?.length > 0 && contentVideos?.length > 0) { 
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {videosCategories.map(vc => ( 
                        <Stack
                            key={vc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {vc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {contentVideos
                                    .slice()
                                    .sort(sortByDate)
                                    .filter(acv => acv.category === vc)
                                    .map(cv => (
                                    <Box 
                                        key={cv.id}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={`/content/all/video/${cv.video_id}`}
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3} >
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, pt: 0.5, }} >
                                                {videoContent(cv.title, cv.date.year, cv.description)}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );
        } if(parseInt(params.relatedContentId, 10) === 2 
                && articlesCategories?.length > 0 
                && articles?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {articlesCategories.map(cc => ( 
                        <Stack
                            key={cc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {cc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {articles
                                    .slice()
                                    .sort(sortImagekitFileByDate)
                                    .filter(fc => fc.dimensions === cc)
                                    .map(c => (
                                    <Box 
                                        key={c.fileId}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={c.url}
                                        target="_blank"
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                {articleContent(c.title, c.date, c.description)}
                                            </Typography>
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, }}>
                                                {articleAuthors(c.technique)} 
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );
        } if(parseInt(params.relatedContentId, 10) === 3  
                && authorArticlesCategories?.length > 0 
                && authorArticles?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {authorArticlesCategories.map(cc => ( 
                        <Stack
                            key={cc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {cc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {authorArticles
                                    .slice()
                                    .sort(sortImagekitFileByDate)
                                    .filter(fc => fc.dimensions === cc)
                                    .map(c => (
                                    <Box 
                                        key={c.fileId}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={c.url}
                                        target="_blank"
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                {articleContent(c.title, c.date, c.description)}
                                            </Typography>
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, }}>
                                                {articleAuthors(c.technique)} 
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );

        } if(parseInt(params.relatedContentId, 10) === 5  
                && clippingCategories?.length > 0 
                && clipping?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {clippingCategories.map(cc => ( 
                        <Stack
                            key={cc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {cc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {clipping
                                    .slice()
                                    .sort(sortImagekitFileByDate)
                                    .filter(fc => fc.dimensions === cc)
                                    .map(c => (
                                    <Box 
                                        key={c.fileId}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={c.url}
                                        target="_blank"
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                {articleContent(c.title, c.date, c.description)}
                                            </Typography>
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, }}>
                                                {articleAuthors(c.technique)} 
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );

        } if(parseInt(params.relatedContentId, 10) === 4  
                && cataloguesCategories?.length > 0 
                && catalogues?.length > 0) {
            return (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {cataloguesCategories.map(cc => ( 
                        <Stack
                            key={cc}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ width: '100%', }}
                        >
                            <Typography variant="boldContentCaption" component="div" sx={headerProps}>
                                {cc}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0.6}
                                sx={{ pr: 3, width: '100%', }}
                            >
                                {catalogues
                                    .slice()
                                    .sort(sortImagekitFileByDate)
                                    .filter(fc => fc.dimensions === cc)
                                    .map(c => (
                                    <Box 
                                        key={c.fileId}
                                        sx={{...sxProps}}
                                        component={Link}
                                        to={c.url}
                                        target="_blank"
                                    >
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.3}>
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, pt: 0.5, }}>
                                                {articleContent(c.title, c.date, c.description)}
                                            </Typography>
                                            <Typography variant="relatedContentCaption" component="div" sx={{ pr: 1, }}>
                                                {articleAuthors(c.technique)} 
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            );
        } else 
            return null;
    };

    return (
        <ThemeProvider theme={publicTheme} >
            <Container maxWidth="xl"  >
                <Grid container spacing={1} >
                    <Grid item xs={12} sx={{ mx: mainDivSize.width > 830 ? 10 : 2, }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                            sx={{ pt: 4, }}
                        >
                            <Logo />
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                            sx={{ pt: 3, }}
                        >

                            <Box sx={{ flexGrow: 1, }} />

                            <Typography component="h1" variant="h6" >
                                {relatedContent ? relatedContent.name : ' ' }
                            </Typography>

                        </Stack>
                    </Grid>

                    <Grid 
                        item 
                        xs={12} 
                        sx={{ mx: mainDivSize.width > 830 ? 10 : 2, pt: 0, mt: 0, }}
                    >
                        <Box 
                            ref={ref} 
                            id="publicImageGalleryContainer"
                            sx={{ 
                                display: 'flex',
                                background: publicTheme.palette.wheat.light,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                minHeight: '70vh',
                                pb: 4,
                                mb: 5
                            }} 
                        >
                            <Grid container spacing={3} sx={{ mx: 0, my: 0, }}>
                                <Grid item xs={12}>
                                    <Box  
                                        sx={{ 
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        {showRelateContent()}
                                    </Box> 
                                </Grid>
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default PublicContent;