import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import { publicTheme } from '../styles/publicStyles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Logo from '../components/Logo';
import Typography from '@mui/material/Typography';


const PublicPage = () => {

    // REACT ROUTER DYNAMIC PARAMETER
    let params = useParams();    

    const mainDivSize = useSelector(state => state.content.mainDivSize); 
    
    const page = useSelector(state => state.pages.pages).find(p => p.id === parseInt(params.pageId, 10) )

    return (
        <ThemeProvider theme={publicTheme} >

            <Container maxWidth="xl"  >
                <Grid container spacing={1} >
                    <Grid item xs={12} sx={{ mx: mainDivSize.width > 830 ? 10 : 2, }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                            sx={{ pt: 4, }}
                        >
                            <Logo />
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                            sx={{ pt: 3, }}
                        >
                            <Box sx={{ flexGrow: 1, }} />

                            <Typography component="h1" variant="h6" >
                                {page ? page.title : ' ' }
                            </Typography>

                        </Stack>
                    </Grid>

                    <Grid 
                        item 
                        xs={12} 
                        sx={{ mx: mainDivSize.width > 830 ? 10 : 2, pt: 0, mt: 0, }}
                    >
                        <Box 
                            sx={{ 
                                display: 'flex',
                                background: publicTheme.palette.wheat.light,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                minHeight: '65vh',
                                mb: mainDivSize.width > 830 ? 10 : 2,
                            }} 
                        >
                            <Grid container spacing={3} sx={{ mx: 0, my: 0, }}>
                                <Grid item xs={12} sx={{ pr: '24px', }}>
                                    <Box  
                                        sx={{ 
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            
                                        }}
                                    >
                                        <Typography variant="pageBody" component="div" >
                                            <div dangerouslySetInnerHTML={{ __html: page.body }} />
                                        </Typography>
                                    </Box> 
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default PublicPage;
